import {APPLY_FILTER_NOTIFICATION, GET_NEW_NOTIFICATION, NOTIFICATION_COUNT, SCHEDULING_NOTIFICATION_STATUS} from "../actionTypes";
  
  const initialState = {
    notificationCount:0,
    notification:{
      data:[],
      page:1,
      limit:5,
      totalPages: 0,
      totalNotification: 0
    },
    notificationStatus:false
  };
  
export default function notificationReducer(state = initialState, action) {
    switch (action.type) {
    
        case NOTIFICATION_COUNT:
            return {
                ...state,
                notificationCount: action.payload,
            }
        
        case SCHEDULING_NOTIFICATION_STATUS:
          return {
              ...state,
              notificationStatus: action.payload,
          }
        
        case GET_NEW_NOTIFICATION:
          console.log("action",action.payload);
          return {
              ...state,
              notification: {
                data:action.payload.results,
                page:action.payload.page,
                limit:action.payload.limit,
                totalPages:action.payload.totalPages,
                totalNotification:action.payload.totalResults
              }
          }
          
        case APPLY_FILTER_NOTIFICATION:
          return {
              ...state,
              notification: {
                data:action.payload.results,
                page:action.payload.page,
                limit:action.payload.limit,
                totalPages:action.payload.totalPages,
                totalNotification:action.payload.totalResults
              }
            }
  

        default:
            return state
        
    }
}