import { GET_INVOICE_RES, ADD_INVOICE_RES, RECURING_INVOICE_RES } from "../actionTypes";

const initialState = {
    getInvoice: null,
    addInvoice: null,
    recuringInvoice: null,
};

export default function invoice(state = initialState, action) {
    switch (action.type) {
        case GET_INVOICE_RES:
            return {
                ...state,
                getInvoice: action.payload,
            };
        case ADD_INVOICE_RES:
            return {
                ...state,
                addInvoice: action.payload,
            };
        case RECURING_INVOICE_RES:
            return {
                ...state,
                recuringInvoice: action.payload,
            };
        default:
            return state;
    }
}