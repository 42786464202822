import { GET_GENERATE_PAYROLL } from '../actionTypes';


const initialState = {
   generatePayrollData: []  
}

export default function generatePayrollReducer(state = initialState, action) {
    switch (action.type) {
        case GET_GENERATE_PAYROLL:
            return {
                ...state,
                generatePayrollData: action.payload
            }
            default: return state
    }
}