import {
    GET_BUILD_MY_BIZ_DOCUMENT, GET_ALL_CATEGORY, EDIT_DOCUMENT_ITEM, DELETE_DOCUMENT_ID, GET_DOCUMENT_UPLOADED_FILES, ADD_DOCUMENT_UPLOADED_FILES, DOCUMENT_UPLOAD_STATUS,
    VIEW_DETIAL_OF_DOCUMENTS, SHOW_MODAL, HIDE_MODAL, REMOVE_DOCUMENT_UPLOADED_FILES, GET_CATEGORY_ID, UPDATE_DRAG_DATA
} from '../actionTypes';

const initialState = {
    category_id: null,
    buildMyBiz: [],
    buildMyBizHeading: '',
    documentCategory: [],
    document_Id: null,
    edit_document_item: null,
    isEdit: false,
    documentUploadedFiles: [],
    updateDragData: {},
    uploadStatus: false,
    document_details: null,
    documentmodalProps: {
        open: false
    },
    currentPage: 1,
    limit: 100,
    totalPages: null,
    totalResults: null
}


export default function buildMyBizzReducer(state = initialState, action) {
    switch (action.type) {

        case GET_CATEGORY_ID:
            return {
                ...state,
                category_id: action.category_id,
                currentPage: action.currentPage,
            }


        case GET_BUILD_MY_BIZ_DOCUMENT:
            return {
                ...state,
                buildMyBizHeading: action.payload.categoryName,
                buildMyBiz: action.payload.results,
                currentPage: action.payload.page,
                totalPages: action.payload.totalPages,
                totalResults: action.payload.totalResults
            }

        case GET_ALL_CATEGORY:
            return {
                ...state,
                documentCategory: action.payload,
            }

        case EDIT_DOCUMENT_ITEM:
            return {
                ...state,
                edit_document_item: action.payload.item,
                isEdit: action.payload.isEdit,
            }

        case ADD_DOCUMENT_UPLOADED_FILES:
            return {
                ...state,
                documentUploadedFiles: action.files.length > 0 ? [...state.documentUploadedFiles, ...action.files] : []
            }


        case GET_DOCUMENT_UPLOADED_FILES:

            return {
                ...state,
                documentUploadedFiles: action.files
            }

        case REMOVE_DOCUMENT_UPLOADED_FILES:
            return {
                ...state,
                documentUploadedFiles: action.files
            }


        case VIEW_DETIAL_OF_DOCUMENTS:
            return {
                ...state,
                document_details: action.document_details
            }

        case DOCUMENT_UPLOAD_STATUS:
            return {
                ...state,
                uploadStatus: action.status
            }

        case DELETE_DOCUMENT_ID:
            return {
                ...state,
                document_Id: action.payload,
            }

        case SHOW_MODAL:
            return {
                ...state,
                documentmodalProps: action.documentmodalProps,
            }

        case HIDE_MODAL:
            return { ...state, documentmodalProps: { open: false } }

        case "UPDATE_BUILDMYBIZZ":
            return { ...state, buildMyBiz: action.payload }

        case UPDATE_DRAG_DATA:
            return {
                ...state,
                updateDragData: action.payload
            }

        default: return state
    }
}