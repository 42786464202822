import {
  ADD_EAR_TRAINER,
  GET_EAR_TRAINER,
  UPDATE_QUIZ_QUESTION,
  DELETE_EAR_TRAINER,
  GET_EAR_TRAINER_CATEGORY,
  ADD_EAR_TRAINER_CATEGORY,
  CLEAR_EAR_TRAINER,
} from "../../actionTypes";

const initialState = {
  earTrainerQuestion: [],
  getEarTrainers: [],
  page: 1,
  limit: 20,
  totalEarTrainers: null,
  totalPages: null,
  updateEarTrainers: [],
  deletedEarTrainers: [],
  getEarTrainerCategory: [],
  addEarTrainerCategory: [],
};

export default function musicalInteractivesReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case ADD_EAR_TRAINER:
      return {
        ...state,
        earTrainerQuestion: action.payload,
      };

    case GET_EAR_TRAINER:
      return {
        ...state,
        getEarTrainers: action.payload.results,
        page: action.payload.page,
        limit: action.payload.limit,
        totalQuiz: action.payload.totalResults,
        totalPages: action.payload.totalPages,
      };

    case DELETE_EAR_TRAINER:
      return {
        ...state,
        deletedEarTrainers: action.payload,
      };

    case UPDATE_QUIZ_QUESTION:
      return {
        ...state,
        updateEarTrainers: action.payload,
      };

    case GET_EAR_TRAINER_CATEGORY:
      return {
        ...state,
        getEarTrainerCategory: action.payload,
      };

    case ADD_EAR_TRAINER_CATEGORY:
      return {
        ...state,
        addEarTrainerCategory: action.payload,
      };

    case CLEAR_EAR_TRAINER:
      console.log("action======", action.payload);
      return {
        ...state,
        getEarTrainers: action.payload,
      };

    default:
      return state;
  }
}
