import {
  FETCH_SINGLE_PLAN,
  GET_ALL_CARD,
  GET_ALL_INSTRUMENT,
  GET_ALL_PLAN,
  GET_INSTRUMENT_TEACHER,
  GET_PLAN_BUILDER,
  GET_ENABLE_PLAN_LIST,
  BOOK_PLAN,
  GET_PLAN_SCHOOL_PRIVATE_TEACHER,
  GET_WAITING_PLAN_BUILDER,
  GET_SUBSCRIBE_PLAN,
  SELECT_PLAN,
  GET_PAYMENT_METHODS,
  GET_PAYMENT_DATA_DETAILS,
  REMOVE_PAYMENT_DATA_DETAILS,
  CLEAR_PAYMENT_DETAILS_DATA,
  GET_WIDGET_DATA,
  GET_ALL_GROUP_STUDENT,
  SELECT_INVOICE_PAY
} from "../actionTypes";

const initialState = {
  planBuilder: [],
  enablePlanList: [],
  bookPlan: {},
  getPlanOfSchool: [],
  waitingPlanBuilder: [],
  editPlanData: null,
  paymentCards: [],
  planList: [],
  instrumentList: [],
  teacherList: [],
  selectedPlan: null,
  invoicePayData: {},
  subscribePlan: [],
  paymentMethods: [],
  paymentListData: {},
  widgetData: [],

  pageApprove: 1,
  limitApprove: 6,

  pageWaiting: 1,
  limitWaiting: 6,

  totalPlans: 0,
  totalWaitingPlans: 0,

  totalApprovePlanPage: 0,
  totalWaitingPlanPage: 0,

  groupStudentList: []
};

export default function studentBillingReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PLAN_BUILDER:
      return {
        ...state,
        planBuilder: action.payload.results,
        totalPlans: action.payload.totalResults,
        totalApprovePlanPage: action.payload.totalPages,
        pageApprove: action.payload.page ? action.payload.page : 1,
        limitApprove: action.payload.limit ? action.payload.limit : 6,
      };
    case GET_ENABLE_PLAN_LIST:
      return {
        ...state,
        enablePlanList: action.payload.results,
      };

    case BOOK_PLAN:
      return {
        ...state,
        bookPlan: action.payload
      }

    case GET_PLAN_SCHOOL_PRIVATE_TEACHER:
      return {
        ...state,
        getPlanOfSchool: action.payload
      }

    case GET_WAITING_PLAN_BUILDER:
      return {
        ...state,
        waitingPlanBuilder: action.payload.results,
        totalWaitingPlans: action.payload.totalResults,
        totalWaitingPlanPage: action.payload.totalPages,
        pageWaiting: action.payload.page ? action.payload.page : 1,
        limitWaiting: action.payload.limit ? action.payload.limit : 6,
      };

    case GET_ALL_CARD:
      return {
        ...state,
        paymentCards: action.payload,
      };

    case GET_ALL_PLAN:
      return {
        ...state,
        planList: action.payload,
      };

    case GET_ALL_GROUP_STUDENT:
      return{
        ...state,
        groupStudentList: action.payload
      }

    case GET_ALL_INSTRUMENT:
      return {
        ...state,
        instrumentList: action.payload,
      };

    case GET_INSTRUMENT_TEACHER:
      return {
        ...state,
        teacherList: action.payload,
      };

    case SELECT_PLAN:
      return {
        ...state,
        selectedPlan: action.payload,
      };

      case SELECT_INVOICE_PAY:
        return {
          ...state,
          invoicePayData: action.payload,
        };

    case GET_SUBSCRIBE_PLAN:
      return {
        ...state,
        subscribePlan: action.payload,
      };

    case FETCH_SINGLE_PLAN:
      return {
        ...state,
        editPlanData: action.payload,
      };
    case GET_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: action.payload,
      };
    case GET_PAYMENT_DATA_DETAILS:
      return {
        ...state,
        paymentListData: action.payload,
      };
    case REMOVE_PAYMENT_DATA_DETAILS:
      return {
        ...state,
        paymentListData: action.payload
      }
    case GET_WIDGET_DATA:
      return {
        ...state,
        widgetData: action.payload
      }
    default:
      return state;
  }
}
