import {GET_NOTIFICATION_SETTING_DETAILS, UPDATE_NOTIFICATION_SETTING_DETAILS} from "../actionTypes";

const initialState = {
    updateNotificationSetting: {},
    // userId: null,
}

export default function notificationSettingReducer(state = initialState, action){
    switch (action.type) {
        case GET_NOTIFICATION_SETTING_DETAILS:
      console.log("action.payload----", action.payload);
      return {
        ...state,
         userId: action.payload,
      };

    case UPDATE_NOTIFICATION_SETTING_DETAILS:
      return {
        ...state,
         updateNotificationSetting: action.payload,
      };

    default:
      return state;
    }
}