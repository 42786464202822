import { GET_SIDEBAR_MENU, UPDATE_SIDEBAR_MENU } from '../actionTypes';


const initialState = {
    mainmenu: []
}

export default function sidebarReducer(state = initialState, action) {
    switch (action.type) {

        case GET_SIDEBAR_MENU:
            return {
                ...state,
                mainmenu: action.payload
            }

        case UPDATE_SIDEBAR_MENU:
            return {
                ...state,
                mainmenu: action.payload
            }

        default: return state
    }
}