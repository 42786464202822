import React from 'react'

const Validation = ({errors,message,genre}) => {
    return (
            errors ? 
                <div className={"invalid-feedback d-block" + (genre ? '' : ' pl-4')}>{message}</div>
            : '' 
    )
}
export default Validation
