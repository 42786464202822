import socketIOClient from "socket.io-client";
import {
  SOCKET_AUTHENTICATION,
  CHAT_USER_LIST,
  MESSAGE_COUNT,
  MESSAGE_RECEIVED,
  SINGLE_MESSAGE_RECEIVED,
  SET_USER_STATUS,
  TYPING_MESSAGE,
  MESSAGE_LOADER,
  READ_MESSAGE,
} from "./redux/actionTypes";

let socket;
let prodEnv;
let domain = window.location.hostname;
const liveApi = process.env.REACT_APP_DEV_URL;
const devApi = process.env.REACT_APP_DEV_URL;
if (domain === 'www.ampliteach.com') prodEnv = "prod";
const ENDPOINT = prodEnv === "prod" ? liveApi : devApi;

export const socketConnect = (dispatch, cb) => {
  if (!socket) socket = socketIOClient.connect(ENDPOINT);

  socket.on("connect", () => {
    cb(socket.connected);
  });

  socket.on("response", (res) => {

    switch (res.event) {
      case "auth": {
        dispatch({ type: SOCKET_AUTHENTICATION, payload: res.data });
        if (res.data.status === 200) {
          socketSendRequest("getUserList", {});
        }
        break;
      }
      case "userConnected":
      case "onDisconnected": {
        dispatch({ type: SET_USER_STATUS, payload: res.data });
        break;
      }

      // case "sendMessage":
      //   dispatch({
      //     type: SINGLE_MESSAGE_RECEIVED,
      //     payload: { messages: res.data },
      //   });
      //   break;

      case "typing":
        dispatch({ type: TYPING_MESSAGE, payload: res.data });
        setTimeout(() => dispatch({ type: TYPING_MESSAGE, payload: "" }), 3000);
        break;

      case "getUserList":
        dispatch({ type: CHAT_USER_LIST, payload: res.data });
        break;

      // case "getAllMessage":
      //   dispatch({ type: MESSAGE_LOADER, payload: false });
      //   dispatch({
      //     type: MESSAGE_RECEIVED,
      //     payload: {
      //       messages: res.data.results,
      //       page: res.data.page,
      //       limit: res.data.limit,
      //     },
      //   });
      //   break;

      case "readMessage":
        dispatch({ type: READ_MESSAGE, payload: res.data });
        break;

      case "getUserWiseMsgCount":
        dispatch({ type: MESSAGE_COUNT, payload: res.data });
        break;

      default:
        break;
    }
  });

  socket.on("disconnect", () => {
  });
};

export const socketSendRequest = (e, payload) => {
  socket?.emit("request", { event: e, data: payload });
};

export { socket };
