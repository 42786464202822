import { authHeader } from '../../services/authHeader';
import { APPLY_FILTER_NOTIFICATION, GET_NEW_NOTIFICATION, NOTIFICATION_COUNT, SCHEDULING_NOTIFICATION_STATUS } from '../actionTypes';

export const getUnReadNotificationCount = (userId)  => async (dispatch, getState, api) => {
    const results = await api.post(`/notification/getUnReadNotificationCount`, {userId}, { headers: authHeader() }).then(res => {
        if (res.status === 200) {
            dispatch({
                type:NOTIFICATION_COUNT,
                payload:res.data.data.notificationCount
            })
        }
        return res
    }).catch(err => {
        return err.response
    })
    return results
}


export const getNotification = (data)  => async (dispatch, getState, api) => {
    const results = await api.post(`/notification/getNotification`, data, { headers: authHeader() }).then(res => {
        if (res.status === 200) {
            console.log("noti -------------",res.data.data);
            dispatch({
                type:GET_NEW_NOTIFICATION,
                payload:res.data.data
            })
        }
        return res
    }).catch(err => {
        return err.response
    })
    return results
}

export const getFilterNotification = (data)  => async (dispatch, getState, api) => {
    const results = await api.post(`/notification/getNotification`, data, { headers: authHeader() }).then(res => {
        if (res.status === 200) {
            dispatch({
                type:APPLY_FILTER_NOTIFICATION,
                payload:res.data.data
            })
        }
        return res
    }).catch(err => {
        return err.response
    })
    return results
}

export const notificationStatusAction = (data)  => async (dispatch, getState, api) => {
    const results = await api.post(`/scheduling/notificationOnOff`, data, { headers: authHeader() }).then(res => {
        // if (res.status === 200) {
        //     dispatch({
        //         type:GET_NEW_NOTIFICATION,
        //         payload:res.data.data
        //     })
        // }
        return res
    }).catch(err => {
        return err.response
    })
    return results
}

export const getSchedulingNotificationStatus = (userId)  => async (dispatch, getState, api) => {
    const results = await api.post(`/scheduling/getNotificationOnOff`, {userId}, { headers: authHeader() }).then(res => {
        if (res.status === 200) {
            dispatch({
                type:SCHEDULING_NOTIFICATION_STATUS,
                payload:res.data.data
            })
        }
        return res
    }).catch(err => {
        return err.response
    })
    return results
}

export const sendFCMToken = (data)  => async (dispatch, getState, api) => {
    const results = await api.post(`/notification/addFcmToken`, data, { headers: authHeader() }).then(res => {
        return res
    }).catch(err => {
        return err.response
    })
    return results
}

