import React, { useEffect } from "react";
// import { getWithExpiry, setWithExpiry } from "../utils";
import Loader from './layout/loader';

const ErrorFallback = ({ error }) => {
  // Handles failed lazy loading of a JS/CSS chunk.
  useEffect(() => {
    console.log("error", error);
    if (localStorage.getItem('counter') === "1") {
      localStorage.removeItem('counter');
      return false
    }
    localStorage.setItem('counter', "1");
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (error?.message && chunkFailedMessage.test(error.message)) {
      window.location.reload()
    }
  }, [error]);

  return (<Loader />)

}

export default ErrorFallback