import { GET_SINGLE_SCHOOL_ID, GET_SINGLE_SCHOOL_ITEM, GET_ALL_SCHOOL, FAIL_TO_ADD_SCHOOL, SHOW_MODAL, HIDE_MODAL, APPLY_SORTBY_SCHOOL, SAVE_MERCHANT_INFO, GET_MERCHANT_INFO } from '../actionTypes';

const initialState = {
    school_Id: null,
    single_school_item: null,
    isEdit: false,
    allschools: [],
    page: 1,
    limit: 6,
    sortBy: 'createdAt',
    orderBy: -1,
    totalPages: 0,
    totalSchool: 0,
    activeSchoolCount: 0,
    inActiveSchoolCount: 0,
    modalProps: {
        open: false
    },
    errMsg: '',
    merchantInfoData: {},
    getMerchantInformation: null
}


export default function schoolReducer(state = initialState, action) {
    switch (action.type) {

        case GET_SINGLE_SCHOOL_ID:
            return {
                ...state,
                school_Id: action.payload,
            }

        case GET_SINGLE_SCHOOL_ITEM:
            return {
                ...state,
                single_school_item: action.payload.item,
                isEdit: action.payload.isEdit,
            }

        case GET_ALL_SCHOOL:
            return {
                ...state,
                allschools: action.payload.results,
                page: action.payload.page ? action.payload.page : 1,
                limit: action.payload.limit ? action.payload.limit : 6,
                totalSchool: action.payload.totalResults,
                totalPages: action.payload.totalPages,
                activeSchoolCount: action.payload.activeSchoolCount,
                inActiveSchoolCount: action.payload.inActiveSchoolCount
            }

        case APPLY_SORTBY_SCHOOL:
            return {
                ...state,
                allschools: action.payload.results,
                page: action.payload.page,
                limit: action.payload.limit,
                totalSchool: action.payload.totalResults,
                totalPages: action.payload.totalPages,
                sortBy: action.payload.sortBy,
                orderBy: action.payload.orderBy,
                activeSchoolCount: action.payload.activeSchoolCount,
                inActiveSchoolCount: action.payload.inActiveSchoolCount
            }

        case SHOW_MODAL:
            return {
                ...state,
                modalProps: action.modalProps,
            }

        case HIDE_MODAL:
            return { ...state, modalProps: { open: false } }

        case FAIL_TO_ADD_SCHOOL:
            return { ...state, errMsg: action.errMsg }

        case SAVE_MERCHANT_INFO:
            return { ...state, merchantInfoData: action.payload }

        case GET_MERCHANT_INFO:
            return { ...state, getMerchantInformation: action.payload }

        default: return state
    }
}