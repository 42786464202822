import thumbnail from "../../assets/images/thumbnail.jpg";
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { printToPDFMultiple } from '../../services/invoiceService';
import { downloadVendorInvoice } from '../../redux/store/action';
import moment from 'moment';

function VendorInvoice({ match, vendorInvoiceData, downloadVendorInvoice }) {

    let { id } = match.params

    useEffect(() => {
        downloadVendorInvoice(id)
    }, [downloadVendorInvoice, id])


    const handlePrint = async () => {
        await printToPDFMultiple('vendorInvoicePdf', [
            'download_vendor_invoice'
        ]);
    }


    return (
        <div className="container-fluid px-0 fullWidth py-5">
            <div className="store invoice_modal mx-auto">
                <div className="fullWidth mb-4" onClick={handlePrint}><a href="#javascript" className="float-right btn_black">Download Invoice</a></div>
                <div className="invoice fullWidth pb-4" id="download_vendor_invoice">
                    <div className="position-relative fullWidth invoice_header d-flex justify-content-between align-items-center">
                        <div className="invoice_logo"><img src={require('../../assets/images/ampliTeach.svg').default} alt="AmpliTeach" /></div>
                        <div className="invoice_top_details">
                            <h2 className="text-uppercase mb-4">invoice</h2>
                            <ul className="p-0 m-0 list-unstyled">
                                <li>
                                    <div className="fullWidth d-flex justify-content-between">
                                        <label><strong>Invoice No:</strong></label>
                                        <span>{vendorInvoiceData?.order_number}</span>
                                    </div>
                                    <div className="fullWidth d-flex justify-content-between">
                                        <label><strong>Invoice Date:</strong></label>
                                        <span>{moment().format('ll')}</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="invoice_to_order_info fullWidth justify-content-between">
                        <div className="invoice_order_info fullWidth pl-5">
                            <h4>Vendor Information:</h4>
                            <div className="details_list">
                                <h2 className="mt-3 mb-4">{vendorInvoiceData?.seller?.first_name} {vendorInvoiceData?.seller?.last_name}</h2>
                                <p>{vendorInvoiceData?.seller?.address} <br />
                                    {vendorInvoiceData?.seller?.state} <br />
                                    {vendorInvoiceData?.seller?.postal_code} <br />
                                    {vendorInvoiceData?.seller?.cell_phone}</p>
                            </div>
                        </div>
                        <div className="invoice_to">
                            <h4>Ship TO:</h4>
                            <h2 className="mt-3 mb-4">{vendorInvoiceData?.addressId?.first_name} {vendorInvoiceData?.addressId?.last_name}</h2>
                            <p>{vendorInvoiceData?.addressId?.address1} <br />
                                {vendorInvoiceData?.addressId?.address2} <br />
                                {vendorInvoiceData?.addressId?.state} <br />
                                {vendorInvoiceData?.addressId?.postal_code} <br />
                                {vendorInvoiceData?.addressId?.phone_number}</p>
                        </div>

                    </div>
                    <div className="fullWidth order_details_main px-5">
                        <div className="order_configuration">
                            <h3 className="bold px-4">Order Number - Ampli{vendorInvoiceData?.order_number}</h3>
                            <div className="details_list_main fullWidth px-4">
                                <div className="data_table table-responsive fullWidth mx-0">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <td align="center"><strong>Item</strong></td>
                                                <td></td>
                                                <td><strong>Cost</strong></td>
                                                <td><strong>Qty.</strong></td>
                                                <td align="right"><strong>Total</strong></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {vendorInvoiceData?.items?.map((store_item, i) => {
                                                console.log("store_item", store_item);
                                                return (
                                                    <tr key={i}>
                                                        {store_item.storeId.item_image[0] && <td align="center"><img src={store_item.storeId.item_image[0]?.location} alt="" title="" height="80" width="80" /></td>}
                                                        {!store_item.storeId.item_image.length > 0 && <td align="center"><img src={thumbnail} alt="" title="" height="80" width="80" /></td>}
                                                        <td>
                                                            <p>{store_item.storeId.item_name} <br />
                                                                <span className="text-gray">Item Code: {store_item.storeId.item_code}</span>
                                                            </p>
                                                        </td>
                                                        <td>${store_item.price}</td>
                                                        <td>{store_item.quantity}</td>
                                                        <td align="right">${store_item.total}</td>
                                                    </tr>
                                                )
                                            })
                                            }
                                            <tr className="border-bottom-0">
                                                <td colSpan="3" className="border-bottom-0"></td>
                                                <td colSpan="2">
                                                    <p><strong>Subtotal :</strong></p>
                                                    <p className="no_wrap">Tax Applicable</p>
                                                </td>
                                                <td align="right">
                                                    <p><strong>${vendorInvoiceData?.subTotal}</strong></p>
                                                    <p>${vendorInvoiceData?.taxApplicable}</p>
                                                </td>
                                            </tr>
                                            <tr className="border-bottom-0">
                                                <td colSpan="3" className="border-bottom-0 border-top-0"></td>
                                                <td colSpan="2" className="border-bottom-0">
                                                    <p><strong>Grand Total (<small>Inc.Tax</small>)</strong></p>
                                                </td>
                                                <td align="right" className="border-bottom-0">
                                                    <p className="text-red"><strong>${vendorInvoiceData?.grandTotal}</strong></p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="fullWidth px-5">
                        <p><strong>Terms:</strong></p>
                        <p className="small">Cash and check payments are due upon receipt.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.auth.currentUser,
        loginAsUser: state.auth.loginAsUser,
        vendorInvoiceData: state.storesState.vendorInvoiceData
    }
}

export default connect(mapStateToProps, { downloadVendorInvoice })(VendorInvoice);
