import React from 'react'
// Plugins 
import { useForm } from "react-hook-form";
import { Modal, ModalBody } from 'reactstrap';

// Component 
import Validation from '../../../formValidation';


const GenersForm = ({ addGenreModal, setAddGenreModal, genresList, AddInstrumentByStudent }) => {

    const { register, handleSubmit, errors, watch } = useForm({ mode: "all" });


    return (
        <Modal className="fade show d-block modelColor" isOpen={addGenreModal} centered >

            <div className="modal-header pb-2 px-5">
                <h2 className="mb-0">Add Genres/Instruments</h2>
                <button type="button" className="close" data-dismiss="modal" onClick={() => setAddGenreModal(false)}>
                    <img src={require("../../../../assets/images/modal_cross_icon.svg").default} alt="Close" title="Close" height="15" width="15" />
                </button>
            </div>

            <ModalBody className="mb-5 px-5 mt-4" >
                <form onSubmit={handleSubmit(AddInstrumentByStudent)}>
                    <div className="row">
                        <div className="col-lg-6">
                            <label>Select Instrument/Genre  <span className="text-red">*</span></label>
                            <div className="fullWidth position-relative">
                                <select
                                    name={`instId`}
                                    className={`form-control ${!errors.instId && watch("instId") ? 'valid' : errors.instId ? 'invalid' : ''}`}
                                    ref={register({
                                        required: 'Instrument/Genre is Required'
                                    })}
                                >
                                    <option value="">Select Instrument/Genre</option>
                                    {genresList?.length > 0 && genresList.map((_, i) => {
                                        return (
                                            <option value={_._id} key={i}>{_.name}</option>
                                        )
                                    })}
                                </select>
                                <Validation errors={errors.instId} message={errors?.instId?.message} watch={watch("instId")} />
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <label>Select Level  <span className="text-red">*</span></label>
                            <div className="fullWidth position-relative">
                                <select
                                    name={`level`}
                                    className={`form-control ${!errors.level && watch("level") ? 'valid' : errors.level ? 'invalid' : ''}`}
                                    ref={register({
                                        required: 'Level is Required'
                                    })}
                                >
                                    <option value="">Select Level</option>
                                    <option value="beginner">Beginner</option>
                                    <option value="intermediate">Intermediate</option>
                                    <option value="advanced">Advanced</option>
                                </select>
                                <Validation errors={errors.level} message={errors?.level?.message} watch={watch("level")} />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mt-2 fullWidth form-inline">
                        <button type="submit" className="btn_primary mr-3">Save</button>
                        <button type="button" className="btn_default" onClick={() => setAddGenreModal(false)}>Cancel</button>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    )
}

export default GenersForm
