import { APPLY_FILTER_PARENT, APPLY_SORTBY_PARENT, DROPDOWN_PARENT, FILTERS_BY_PARENT, GET_ALL_PARENT } from '../actionTypes';

const initialState = {
    parents: [],
    dropdownParent:[],
    filterdParents: [],
    filter: false,
    page:1,
    limit:6,
    sortBy: 'createdAt',
    orderBy: -1,
    totalParent:0
}


export default function parentReducer(state = initialState, action) {
    switch (action.type) {

        case GET_ALL_PARENT:
            return {
                ...state,
                parents: action.payload.results,
                page: action.payload.page,
                limit: action.payload.limit,
                totalParent:action.payload.totalResults,
                filter: false
            }
            
        case DROPDOWN_PARENT:
            return {
                ...state,
                dropdownParent: action.payload
            }
    
        case APPLY_FILTER_PARENT:
            return {
                ...state,
                parents: action.payload.userInfo.results,
                page: action.payload.userInfo.page,
                limit: action.payload.userInfo.limit,
                totalParent:action.payload.userInfo.totalResults,
            }

        case APPLY_SORTBY_PARENT:
            return {
                ...state,
                parents: action.payload.results,
                page: action.payload.page,
                limit: action.payload.limit,
                totalParent:action.payload.totalResults,
                sortBy: action.payload.sortBy,
                orderBy: action.payload.orderBy,
                filter: false
            }
        case FILTERS_BY_PARENT:
            if (action.payload.length === 0) {
                return {
                    ...state,
                    filterdParents: [],
                    filter: true
                }
            } else {
                return {
                    ...state,
                    filterdParents: action.payload,
                    filter: true
                }
            }
        default: return state
    }
}