import thumbnail from "../../assets/images/thumbnail.jpg";
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { printToPDFMultiple } from '../../services/invoiceService';
import { downloadInvoice } from '../../redux/store/action';
import moment from 'moment';

const Invoice = ({ match, invoieData, downloadInvoice }) => {

    // console.log("match",match);
    // console.log("invoieData",invoieData);

    let { id } = match.params

    useEffect(() => {
        downloadInvoice(id)
    }, [downloadInvoice, id])


    const handlePrint = async () => {
        await printToPDFMultiple('InvoicePdf', [
            'download_invoice'
        ]);
    }

    return (
        <div className="container-fluid px-0 fullWidth py-5">
            <div className="store invoice_modal mx-auto">
                <div className="fullWidth mb-4" onClick={handlePrint}><a href="#javascript" className="float-right btn_black">Download Invoice</a></div>
                <div className="invoice fullWidth pb-4" id="download_invoice">
                    <div className="position-relative fullWidth invoice_header d-flex justify-content-between align-items-center">
                        <div className="invoice_logo"><img src={require('../../assets/images/ampliTeach.svg').default} alt="AmpliTeach" /></div>
                        <div className="invoice_top_details">
                            <h2 className="text-uppercase mb-4">invoice</h2>
                            <ul className="p-0 m-0 list-unstyled">
                                <li>
                                    <div className="fullWidth d-flex justify-content-between">
                                        <label><strong>Invoice No:</strong></label>
                                        <span>{invoieData?.order_number}</span>
                                    </div>
                                    <div className="fullWidth d-flex justify-content-between">
                                        <label><strong>Invoice Date:</strong></label>
                                        <span>{moment().format('ll')}</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="invoice_to_order_info fullWidth justify-content-between">
                        <div className="invoice_to">
                            <h4>BILL TO:</h4>
                            <h2 className="mt-3 mb-4" style={{ fontSize: '2.8rem' }}>{invoieData?.userId?.school_name}</h2>
                            <p>{invoieData?.addressId?.address1} <br />
                                {/* {invoieData?.addressId?.address2} <br /> */}
                                {invoieData?.addressId?.city}, {invoieData?.addressId?.state} {invoieData?.addressId?.postal_code} <br />
                                {invoieData?.addressId?.country}, {invoieData?.addressId?.cellPhone}<br />
                                {invoieData?.addressId?.email}
                            </p>
                        </div>
                        <div className="invoice_order_info fullWidth pl-5">
                            <div className="invoice_to">
                                <h4>SHIP TO:</h4>
                                <h2 className="mt-3 mb-4" style={{ fontSize: '2.8rem' }}>{invoieData?.userId?.school_name}</h2>
                                <p>{invoieData?.addressId?.address1} <br />
                                    {/* {invoieData?.addressId?.address2} <br /> */}
                                    {invoieData?.addressId?.city}, {invoieData?.addressId?.state} {invoieData?.addressId?.postal_code} <br />
                                    {invoieData?.addressId?.country}, {invoieData?.addressId?.cellPhone} <br />
                                    {invoieData?.addressId?.email}
                                </p>
                            </div>
                            {/* <h4>Order Information:</h4>
                            <div className="details_list">
                                <p className="d-flex fullWidth align-items-center">
                                    <label className="no_wrap mr-3"><strong>Order Date:</strong></label>
                                    <span>{moment(invoieData?.order_date).format('LLL')}</span>
                                </p>
                                <p className="d-flex fullWidth align-items-center">
                                    <label className="no_wrap mr-3 min_width_12"><strong>Name: </strong></label>
                                    <span><a href="#javascript">{invoieData?.userId?.username}</a> <br />
                                        {invoieData?.userId?.role} <br />
                                    </span>
                                </p>
                                <p className="d-flex fullWidth align-items-center">
                                    <label className="no_wrap mr-3"><strong>Email:</strong></label>
                                    <span>{invoieData?.userId?.email}</span>
                                </p>
                                <p className="d-flex fullWidth align-items-center">
                                    {(invoieData?.userId?.working_phone || invoieData?.userId?.cellPhone) &&
                                        <>
                                            <label className="no_wrap mr-3"><strong>Phone:</strong></label>
                                            <span>{invoieData?.userId?.working_phone ? invoieData?.userId?.working_phone : invoieData?.userId?.cellPhone}</span>
                                        </>
                                    }
                                </p>
                            </div> */}
                        </div>
                    </div>
                    <div className="fullWidth order_details_main px-5">
                        <div className="order_configuration">
                            <h3 className="bold px-4">Order Number - Ampli{invoieData?.order_number}</h3>
                            <div className="details_list_main fullWidth px-4">
                                <div className="data_table table-responsive fullWidth mx-0">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <td align="center"><strong>Item</strong></td>
                                                <td></td>
                                                <td><strong>Cost</strong></td>
                                                <td><strong>Qty.</strong></td>
                                                <td align="right"><strong>Total</strong></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {invoieData?.items?.map((store_item, i) => {
                                                console.log("store_item", store_item);
                                                return (
                                                    <tr key={i}>
                                                        {store_item.storeId.item_image[0] && <td align="center"><img src={store_item.storeId.item_image[0]?.location} alt="" title="" height="80" width="80" /></td>}
                                                        {!store_item.storeId.item_image.length > 0 && <td align="center"><img src={thumbnail} alt="" title="" height="80" width="80" /></td>}
                                                        <td>
                                                            <p>{store_item.storeId.item_name} <br />
                                                                <span className="text-gray">Item Code: {store_item.storeId.item_code}</span>
                                                            </p>
                                                        </td>
                                                        <td>${store_item.price}</td>
                                                        <td>{store_item.quantity}</td>
                                                        <td align="right">${store_item.total}</td>
                                                    </tr>
                                                )
                                            })
                                            }
                                            <tr className="border-bottom-0">
                                                <td colSpan="3" className="border-bottom-0"></td>
                                                <td colSpan="2">
                                                    <p><strong>Subtotal :</strong></p>
                                                    <p className="no_wrap">Tax Applicable</p>
                                                    <p className="no_wrap">Shipping Cost</p>
                                                </td>
                                                <td align="right">
                                                    <p><strong>${invoieData?.subTotal}</strong></p>
                                                    <p>${invoieData?.taxApplicable}</p>
                                                    <p>${invoieData?.totalShippingCost}</p>
                                                </td>
                                            </tr>
                                            <tr className="border-bottom-0">
                                                <td colSpan="3" className="border-bottom-0 border-top-0"></td>
                                                <td colSpan="2" className="border-bottom-0">
                                                    <p><strong>Grand Total (<small>Inc.Tax</small>)</strong></p>
                                                </td>
                                                <td align="right" className="border-bottom-0">
                                                    <p className="text-red"><strong>${invoieData?.grandTotal}</strong></p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="fullWidth px-5">
                        <p><strong>Terms:</strong></p>
                        {/* <p className="small">Cash and check payments are due upon receipt.</p> */}
                        <p className="small">This will be a monthly recurring payment.</p>
                    </div>
                </div>
            </div>
        </div >
    )
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.auth.currentUser,
        loginAsUser: state.auth.loginAsUser,
        invoieData: state.storesState.invoieData
    }
}

export default connect(mapStateToProps, { downloadInvoice })(Invoice)
