import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import SweetAlert from "sweetalert2";
import Validation from '../component/formValidation';
import { formatPhoneNumber } from "../utils";
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { getAllInstrument, getAllPlanBuilder, registerStudent } from '../redux/studentBilling/action';
import PhoneInput from 'react-phone-number-input';
import AddGenersForm from "../component/member/student/studentForm/GenersFormInstrument";

const StudentSignup = ({ schoolId }) => {
    const { register, handleSubmit, errors, watch, control, reset, setValue } = useForm({ mode: "all" });
    const dispatch = useDispatch()
    const { id: paramsId } = useParams();
    const id = schoolId || paramsId;
    const instrumentList = useSelector((state) => state.studentBillingState.instrumentList);
    const [studentInstrumentGenre, setStudentInstrumentGenre] = useState([])
    const [addGenreModal, setAddGenreModal] = useState(false)

    useEffect(() => {
        dispatch(getAllInstrument({ userId: id }))
        dispatch(getAllPlanBuilder({
            // paymentMethod: "",
            userId: id
        }))
    }, [])

    const handleRegisterForm = (state) => {
        console.log(state, 'state');
        state.genreId = studentInstrumentGenre.map(data => {
            const obj = { genreId: data?.genreId._id, level: data.level };
            return obj;
        })
        state.adminId = id
        state.role = 'student'
        dispatch(registerStudent(state, 'school')).then((res) => {
            if (res.status === 200) {
                setStudentInstrumentGenre([])
                setValue('cellPhone', '' )
                reset()
                SweetAlert.fire({
                    text: "student register successfully",
                    icon: "success",
                });
            } else {
                SweetAlert.fire({
                    text: res.data.message,
                    icon: "error",
                });
            }
        })
    }

    const AddInstrumentGenresByStudent = (state) => {
        let name = instrumentList.filter((item) => item._id === state.instId);
        Object.assign(state, { name: name[0].name });
        let GenreData = {
            genreId: {
                _id: state.instId,
                name: state.name,
            },
            level: state.level,
        };
        const sendPayload = [...studentInstrumentGenre, { ...GenreData }];
        setStudentInstrumentGenre(sendPayload)
        setAddGenreModal(false);
    };

    const handleChangeInstrumentGenrs = (i, level) => {
        const newArr = studentInstrumentGenre?.map((item,index) => {
            if (index === i) {
                item.level = level;
            }
            const obj = { genreId: item.genreId, level: item.level };
            return obj;
        });
        setStudentInstrumentGenre(newArr);
    };

    const onRemoveInstrumentGenersClick = (i) => {
        let newData = studentInstrumentGenre.filter((d,index) => index !== i)
        setStudentInstrumentGenre(newData);
    }


    return (
        <div className="fullWidth">
            <style>
                {
                    "\
                  // .modal.show{pointer-events:none;background-color:transparent;}\
                  .modal-backdrop, .modal-content{background-color:transparent;}\
                  .login_details{background: #fff; border-radius: 1rem;}\
              "
                }
            </style>
            <div className="login_page signup_page">
                <div className="container">
                    <div className="fullWidth mt-5 mb-4 ml-4">
                        <h2 style={{fontSize:'18px'}}>If you would like to sign up for lessons fill out the following form. We will contact you to complete the process</h2>
                    </div>
                    {/* <hr className="fullWidth mb-2"></hr> */}
                    <form onSubmit={handleSubmit(handleRegisterForm)}>
                        <div className="form-row mx-0">
                            <div className="col-md-6 mb-4">
                                <div className="form-group">
                                    <label>
                                        First Name <span className="validation_compulsory">*</span>
                                    </label>
                                    <div className="fullWidth position-relative">
                                        <input
                                            type="text"
                                            className={`form-control ${!errors.first_name?.message
                                                ? watch("first_name")
                                                    ? "valid"
                                                    : ""
                                                : errors.first_name?.message
                                                    ? "invalid"
                                                    : ""
                                                }`}
                                            name="first_name"
                                            autocomplete="first_name"
                                            ref={register({ required: "First Name is Required" })}
                                        />
                                        <Validation errors={errors.first_name} message={errors?.first_name?.message} watch={watch("first_name")} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-4">
                                <div className="form-group">
                                    <label>
                                        Last Name <span className="validation_compulsory">*</span>
                                    </label>
                                    <div className="fullWidth position-relative">
                                        <input
                                            type="text"
                                            className={`form-control ${!errors.last_name?.message
                                                ? watch("last_name")
                                                    ? "valid"
                                                    : ""
                                                : errors.last_name?.message
                                                    ? "invalid"
                                                    : ""
                                                }`}
                                            name="last_name"
                                            ref={register({ required: "Last Name is Required" })}
                                        />
                                        <Validation errors={errors.last_name} message={errors?.last_name?.message} watch={watch("last_name")} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-4">
                                <div className="form-group">
                                    <label>
                                        Email Address <span className="validation_compulsory">*</span>
                                    </label>
                                    <div className="fullWidth position-relative">
                                        <input
                                            type="email"
                                            name="email"
                                            autocomplete="email"
                                            className={`form-control ${!errors.email?.message
                                                ? watch("email")
                                                    ? "valid"
                                                    : ""
                                                : errors.email?.message
                                                    ? "invalid"
                                                    : ""
                                                }`}
                                            ref={register({
                                                required: "Email is Required",
                                                pattern: {
                                                    value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                                                    message: "Please provide a valid email address",
                                                },
                                            })}
                                        />
                                        <Validation errors={errors.email} message={errors?.email?.message} watch={watch("email")} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-4">
                                <div className="form-group">
                                    <label>
                                        Phone{" "}<span className="validation_compulsory">*</span>
                                    </label>
                                    <div className="fullWidth position-relative">
                                        <Controller
                                            control={control}
                                            name="cellPhone"
                                            rules={{ required: "Cell Phone is Required" }}
                                            render={({ onChange, value }) => (
                                                <PhoneInput
                                                    ref={register}
                                                    defaultCountry="US"
                                                    placeholder="Enter Cell number"
                                                    value={value}
                                                    onChange={onChange}
                                                    className={`form-control  ${!errors.cellPhone?.message
                                                        ? watch("cellPhone")
                                                            ? "valid"
                                                            : ""
                                                        : errors.cellPhone?.message
                                                            ? "invalid"
                                                            : ""
                                                        }`}
                                                    international={true}
                                                    limitMaxLength={true}
                                                />
                                            )}
                                            defaultValue=''
                                        />
                                        <Validation
                                            errors={errors.cellPhone}
                                            message={errors?.cellPhone?.message}
                                            watch={watch("cellPhone")}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-4">
                                <div className="form-group">
                                    <label>
                                        Address <span className="validation_compulsory">*</span>
                                    </label>
                                    <div className="fullWidth position-relative">
                                        <input
                                            type="textarea"
                                            name="address1"
                                            autocomplete="address1"
                                            maxlength="100"
                                            className={`form-control ${!errors.address1?.message
                                                ? watch("address1")
                                                    ? "valid"
                                                    : ""
                                                : errors.address1?.message
                                                    ? "invalid"
                                                    : ""
                                                }`}
                                            defaultValue=''
                                            ref={register({
                                                required: "Address is Required"
                                            })}
                                        />
                                        <Validation errors={errors.address1} message={errors?.address1?.message} watch={watch("address1")} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-4">
                                <div className="form-group">
                                    <label>
                                        City <span className="validation_compulsory">*</span>
                                    </label>
                                    <div className="fullWidth position-relative">
                                        <input
                                            type="text"
                                            name="city"
                                            className={`form-control ${!errors.city?.message
                                                ? watch("city")
                                                    ? "valid"
                                                    : ""
                                                : errors.city?.message
                                                    ? "invalid"
                                                    : ""
                                                }`}
                                            autocomplete="city"
                                            defaultValue=''
                                            ref={register({
                                                required: "City is Required",
                                                maxLength: {
                                                    value: 100,
                                                    message: "City should allow only 100 characters.",
                                                },
                                                pattern: {
                                                    value: /^[A-Za-z ]+$/,
                                                    message: "Should allow only alphabet characters",
                                                },
                                            })}
                                        />
                                        <Validation errors={errors.city} message={errors?.city?.message} watch={watch("city")} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-4">
                                <div className="form-group">
                                    <label>
                                        State <span className="validation_compulsory">*</span>
                                    </label>
                                    <div className="fullWidth position-relative">
                                        <input
                                            type="text"
                                            name="state"
                                            className={`form-control ${!errors.state?.message
                                                ? watch("state")
                                                    ? "valid"
                                                    : ""
                                                : errors.state?.message
                                                    ? "invalid"
                                                    : ""
                                                }`}
                                            autocomplete="state"
                                            defaultValue=''
                                            ref={register({
                                                required: "State is Required",
                                                maxLength: {
                                                    value: 100,
                                                    message:
                                                        "State should allow only 100 characters.",
                                                },
                                                pattern: {
                                                    value: /^[A-Za-z ]+$/,
                                                    message: "Should allow only alphabet characters",
                                                },
                                            })}
                                        />
                                        <Validation errors={errors.state} message={errors?.state?.message} watch={watch("state")} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-4">
                                <div className="form-group">
                                    <label>
                                        Postal Code <span className="validation_compulsory">*</span>
                                    </label>
                                    <div className="fullWidth position-relative">
                                        <input
                                            type="tel"
                                            inputmode="numeric"
                                            maxlength="5"
                                            name="postal_code"
                                            autocomplete="postal_code"
                                            className={`form-control ${!errors.postal_code?.message
                                                ? watch("postal_code")
                                                    ? "valid"
                                                    : ""
                                                : errors.postal_code?.message
                                                    ? "invalid"
                                                    : ""
                                                }`}
                                            ref={register({
                                                required: "Postal Code is Required",
                                                pattern: {
                                                    value: /^[0-9 !-@#$%^&*)(]{1,8}$/,
                                                    message: "Should allow only Numeric Value",
                                                },
                                            })}
                                            defaultValue={''}
                                        />
                                        <Validation errors={errors.postal_code} message={errors?.postal_code?.message} watch={watch("postal_code")} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 mb-4" style={{ paddingLeft: '1.5rem' }}>
                                <div className="form-group">
                                    <div className="fullWidth mt-5">
                                        <h4 style={{ color: 'white' }}>
                                            Instrument/Genre Information{" "}
                                            <i
                                                className="fa fa-plus ml-3 text-red pt-3 cursor_pointer"
                                                aria-hidden="true"
                                                onClick={() => setAddGenreModal(true)}
                                            ></i>
                                        </h4>
                                    </div>
                                    <hr className="fullWidth mb-5" style={{ background: 'white' }} />
                                    {studentInstrumentGenre?.length > 0 &&
                                        studentInstrumentGenre?.map((_inst, i) => {
                                            return (
                                                <div
                                                    className="fullWidth row mx-0 space"
                                                    key={_inst?.genreId?._id}
                                                >
                                                    <div className="col-md-6 px-md-3 px-0">
                                                        <div className="form-group minHeight">
                                                            {/* <label>Genre/Instrument</label> */}
                                                            <div className="fullWidth position-relative">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter Instrument/Genre Name"
                                                                    defaultValue={_inst?.genreId?.name}
                                                                    readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5 px-md-3 px-0">
                                                        <div className="form-group minHeight">
                                                            {/* <label>Genre/Instrument</label> */}
                                                            <div className="fullWidth position-relative">
                                                                <select
                                                                    className={`form-control text_ellipsis studentSelect`}
                                                                    name="level"
                                                                    defaultValue={_inst?.level}
                                                                    onChange={(e) =>
                                                                        handleChangeInstrumentGenrs(
                                                                            i,
                                                                            e.target.value
                                                                        )
                                                                    }
                                                                >
                                                                    {/* <option value="">Please Select One</option> */}
                                                                    <option value="beginner">Beginner</option>
                                                                    <option value="intermediate">
                                                                        Intermediate
                                                                    </option>
                                                                    <option value="advanced">Advanced</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-1 px-0">
                                                        <i
                                                            className="fa fa-trash ml-3 text-red pt-3 cursor_pointer"
                                                            onClick={() =>
                                                                onRemoveInstrumentGenersClick(i)
                                                            }
                                                        ></i>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>
                        <div className="fullWidth mt-5">
                            <input type="submit" className="btn_primary float-left mr-5" value='Submit' />
                        </div>
                    </form>
                </div>
            </div>
            <AddGenersForm
                addGenreModal={addGenreModal}
                setAddGenreModal={setAddGenreModal}
                AddInstrumentByStudent={AddInstrumentGenresByStudent}
                genresList={instrumentList}
            />
        </div>
    )
}

export default StudentSignup