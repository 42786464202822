import { GET_ALL_COURSES, GET_GENERS_CURRICULUM, GET_ALL_GENRES, GET_ALL_LIBRARYS, GET_ALL_LESSONS, CHECK_ISDONE_STATUS, GET_PRACTICE, FILTERS_BY_LIBRARY, CURRENT_PAGE, SET_COURSE_ID, GET_COURSE_LESSONS, APPLY_SORTBY_COURSE, GET_ALL_WAITING_COURSES, UPDATE_GENRESLIST, GET_ALL_COURSES_BY_STUDENTS, UPDATE_LESSON_DRAG, VIDEO_TRACKING } from '../actionTypes';

const initialState = {
    courseId: null,
    courses: [],
    waitingCourses: [],
    lessons: [],
    genre: [],
    library: [],
    practiceList: [],
    filterdLibrary: [],
    filter: false,
    currentPage: 1,

    courseDetails: {},
    AllLessonDetails: [],
    lessonDetails: [],
    coursesStudentWise: [],

    page: 1,
    limit: 8,
    sortBy: 'createdAt',
    orderBy: -1,
    totalCourse: 0,
    totalCoursePages: 0,
    totalWaitingCourse: 0,
    approveCourse: 0,
    notApproveCourse: 0,
    genersCurriculum: [],
    trackVideoPlayer: []
}

export default function coursesReducer(state = initialState, action) {
    switch (action.type) {

        case GET_ALL_COURSES:
            return {
                ...state,
                courses: action.payload.data?.results,
                totalCourse: action.payload?.data?.totalResults,
                totalCoursePages: action.payload?.data?.totalPages,
                page: action.payload?.data?.page ? action.payload?.data?.page : 1,
                limit: action.payload?.data?.limit ? action.payload?.data?.limit : 8,
                approveCourse: action.payload?.data?.approveCourseCount,
                notApproveCourse: action.payload?.data?.notApproveCourseCount,
            }

        case APPLY_SORTBY_COURSE:
            return {
                ...state,
                courses: action.payload.results,
                page: action.payload.page,
                limit: action.payload.limit,
                totalCourse: action.payload.totalResults,
                totalCoursePages: action.payload?.data?.totalPages,
                sortBy: action.payload.sortBy,
                orderBy: action.payload.orderBy,
                approveCourse: action.payload.approveCourseCount,
                notApproveCourse: action.payload.notApproveCourseCount,
                filter: false
            }


        case GET_ALL_WAITING_COURSES:
            return {
                ...state,
                waitingCourses: action.payload.data?.results,
                totalWaitingCourse: action.payload?.data?.totalResults,
                page: action.payload?.data?.page ? action.payload?.data?.page : 1,
                limit: action.payload?.data?.limit ? action.payload?.data?.limit : 8,
                approveCourse: action.payload?.data?.approveCourseCount,
                notApproveCourse: action.payload?.data?.notApproveCourseCount,
            }

        case GET_ALL_LESSONS:
            return {
                ...state,
                lessons: action.payload,
            }

        case GET_ALL_GENRES:
            return {
                ...state,
                genre: action.payload,
            }

        case UPDATE_GENRESLIST:
            return {
                ...state,
                genre: action.payload,
            }

        case GET_ALL_LIBRARYS:
            return {
                ...state,
                library: action.payload,
            }

        case GET_PRACTICE:
            return {
                ...state,
                practiceList: action.payload,
            }

        case GET_COURSE_LESSONS:
            return {
                ...state,
                courseDetails: action.payload.courseDetail,
                lessonDetails: action.payload.lessonDetail,


            }

        case GET_ALL_COURSES_BY_STUDENTS:
            return {
                ...state,
                courses: action.payload.data.results,
                // lessonDetails: action.payload.lessonDetail,
                page: action.payload.data.page,
                limit: action.payload.data.limit,
                totalCourse: action.payload.data.totalResults,
                totalCoursePages: action.payload?.totalPages,
                sortBy: action.payload.data.sortBy,
                orderBy: action.payload.data.orderBy,
            }

        case SET_COURSE_ID:
            return {
                ...state,
                courseId: action.payload,
            }
        case FILTERS_BY_LIBRARY:
            if (action.payload.length === 0) {
                return {
                    ...state,
                    filterdLibrary: [],
                    filter: true
                }
            } else {
                return {
                    ...state,
                    filterdLibrary: action.payload,
                    filter: true
                }
            }


        case CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload,
            }

        case GET_GENERS_CURRICULUM:
            return {
                ...state,
                genersCurriculum: action.payload
            }

        case CHECK_ISDONE_STATUS:
            return {
                ...state,
                genersCurriculum: action.payload
            }
        case "UPDATE_LESSON_DRAG":
            return { ...state, lessonDetails: action.payload }

        case VIDEO_TRACKING:
            return {
                ...state,
                trackVideoPlayer: action.payload
            }


        default: return state
    }
}