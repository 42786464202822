import { GET_CUSTOM_COMMUNICATION } from '../actionTypes';

const initialState = {
    CustomCommunication:[]
}


export default function customCommunicationReducer(state = initialState, action) {
    switch (action.type) {

        case GET_CUSTOM_COMMUNICATION:
            return {
                ...state,
                CustomCommunication: action.payload,
            }
    
        default: return state
    }
}