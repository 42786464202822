import {  GET_SINGLE_PRIVATE_TEACHER_ID,GET_SINGLE_SCHOOL_ITEM,GET_ALL_PRIVATE_TEACHER,FAIL_TO_ADD_PRIVATE_TEACHER,SHOW_MODAL,HIDE_MODAL, APPLY_SORTBY_PRIVATE_TEACHER} from '../actionTypes';

const initialState = {
    teacher_Id:null,
    single_private_teacher_item:null,
    isEdit:false,
    allPrivateTeacher: [],
    page:1,
    limit:6,
    sortBy: 'createdAt',
    orderBy: -1,
    totalPages:0,
    totalPrivateTeacher:0,
    activePrivateTeacherCount:0,
    inActivePrivateTeacherCount:0,
    modalPrivateTeacherProps: {
        open: false
    },
    errMsg:''
}


export default function privateTeacherReducer(state = initialState, action) {
    switch (action.type) {

        case GET_SINGLE_PRIVATE_TEACHER_ID:
            return {
                ...state,
                teacher_Id: action.payload,
            }

        case GET_SINGLE_SCHOOL_ITEM:
            return {
                ...state,
                single_private_teacher_item: action.payload.item,
                isEdit:action.payload.isEdit,
            }

        case GET_ALL_PRIVATE_TEACHER:
            return {
                ...state,
                allPrivateTeacher: action.payload.results,
                page: action.payload.page ? action.payload.page : 1,
                limit: action.payload.limit ? action.payload.limit : 6,
                totalPrivateTeacher: action.payload.totalResults,
                totalPages: action.payload.totalPages,
                activePrivateTeacherCount:action.payload.activePrivateTeacherCount,
                inActivePrivateTeacherCount:action.payload.inActivePrivateTeacherCount   
            }

        case APPLY_SORTBY_PRIVATE_TEACHER:
            return {
                ...state,
                allPrivateTeacher: action.payload.results,
                page: action.payload.page,
                limit: action.payload.limit,
                totalPrivateTeacher: action.payload.totalResults,
                totalPages: action.payload.totalPages,
                sortBy: action.payload.sortBy,
                orderBy: action.payload.orderBy,
                activePrivateTeacherCount:action.payload.activePrivateTeacherCount,
                inActivePrivateTeacherCount:action.payload.inActivePrivateTeacherCount
            }
        
        case SHOW_MODAL:
            return {
                ...state,
                modalPrivateTeacherProps: action.modalPrivateTeacherProps,
            }

        case HIDE_MODAL:
            return {...state,modalPrivateTeacherProps:{open:false}}

        case FAIL_TO_ADD_PRIVATE_TEACHER:
            return {...state,errMsg:action.errMsg}
        
        default: return state
    }
}