import Cookies from 'universal-cookie';
import { LOGIN_SUCCESS, LOGOUT_SUCCESS, LOGIN_AS_IN, LOGIN_AS_OUT } from '../actionTypes';

const cookies = new Cookies();
const userToken = cookies.get('amplitech')
const asUserToken = cookies.get('loginAsUser')
const currentUser = userToken ? JSON.parse(atob(userToken.split('.')[1])) : '';
const loginAsUser = asUserToken ? JSON.parse(atob(asUserToken.split('.')[1])) : '';
const loginAsData = cookies.get('loginAsData')
const currentData = JSON.parse(localStorage.getItem('currentData'))
const loginAsLocalStorageData = JSON.parse(localStorage.getItem('loginAsLocalStorageData'))



const initialState = {
  currentUser: currentUser,
  currentData: currentData,
  loginAsUser: loginAsUser,
  loginAsData: loginAsData,
  loginAsLocalStorageData: loginAsLocalStorageData
}

console.log("currentDataaa", currentData)

export default function loginReducer(state = initialState, action) {
  const { type } = action;

  switch (type) {

    case LOGIN_SUCCESS:
      return {
        ...state,
        currentData: action.payload,
        currentUser: JSON.parse(atob(action.currentUser.split('.')[1])),
      };

    case LOGOUT_SUCCESS:
      return {
        ...state,
        currentUser: null,
        loginAsUser: null,
        loginAsLocalStorageData: null
      };

    case LOGIN_AS_IN:
      return {
        ...state,
        loginAsData: action.payload,
        loginAsLocalStorageData: action.payload,
        loginAsUser: JSON.parse(atob(action.loginAsUser.split('.')[1])),
      };

    case LOGIN_AS_OUT:
      return {
        ...state,
        loginAsUser: null,
        loginAsLocalStorageData: null
      };

    default:
      return state;
  }

}