import { GET_USER_PRFOILE, UPDATE_BIO_INFORMATION, GET_BIO_INFORMATION, STUDENT_PRACTICE_REPORT, STUDENT_PRACTICE_REPORT_Video } from '../actionTypes';

const initialState = {
    userProfile: null,
    bioInformation: null,
    getBioInfo: null,
    practiceReportData: {},
    VideoPracticeData: {}
}

export default function pofileReducer(state = initialState, action) {
    switch (action.type) {

        case GET_USER_PRFOILE:
            return {
                ...state,
                userProfile: action.payload,
            }
        case UPDATE_BIO_INFORMATION:
            return {
                ...state,
                bioInformation: action.payload,
            }
        case GET_BIO_INFORMATION:
            return {
                ...state,
                getBioInfo: action.payload,
            }

        case STUDENT_PRACTICE_REPORT:
            return {
                ...state,
                practiceReportData: action.payload
            }

        case STUDENT_PRACTICE_REPORT_Video:
            return {
                ...state,
                VideoPracticeData: action.payload
            }

        default: return state
    }
}