import { combineReducers } from 'redux'
import auth from './auth/reducer'
import studentsReducer from './students/reducer'
import teachersReducer from './teachers/reducer'
import sidebarReducer from './sidebar/reducer';
import subAdminReducer from './subAdmin/reducer';
import parentReducer from './parent/reducer'
import classesgroupReducer from './classgroup/reducer'
import coursesReducer from './courses/reducer';
import libraryReducer from './library/reducer';
import schoolReducer from './school/reducer';
import privateTeacherReducer from './privateTeacher/reducer';
import buildMyBizReducer from './buildMyBiz/reducer'
import storesReducer from './store/reducer';
import innnerLoaderReducer from './innerLoader/reducer'
import profileReducer from './profile/reducer';
import superAdminCourseReducer from './superadmin/reducer'
import weeklyScheduleReducer from './weeklyScheduling/reducer';
import studentBillingReducer from './studentBilling/reducer';
import studentPracticeTimerReducer from './studentPractice/reducer';
import chatReducer from './chat/reducer';


import notificationReducer from './notifications/reducer';
import settingReducer from './setting/reducer';

import payrollReducer from './reporting/reducer';
import attendanceReport from './AttendanceReport/reducer';

import customCommReducer from './customEmailSms/reducer';
import contractsReducer from './contracts/reducer'
import notificationSettingReducer from './notificationSetting/reducer'
import communicationReducer from './communicationLog/reducer'
import generatePayrollReducer from './generatePayroll/reducer'
import recentActivitiesReducer from './recentActivities/reducer'
import loginWidgetReducer from './loginWidget/reducer'
import searchBarReducer from './searchBar/reducer'
import quizesReducer from './quizes/reducer'
import importMembersReducer from './importMembers/reducer'
import musicalInteractivesReducer from './musicalInteractives/earTrainer/reducer'
import favouriteListReducer from './Favourite/reducer'
import invoice from './invoice/reducer';
import studentReducer from './students/reducer';

export default combineReducers({
  auth,
  buildMyBizState: buildMyBizReducer,
  privateTeacherState: privateTeacherReducer,
  schoolState: schoolReducer,
  studentState: studentsReducer,
  teacherState: teachersReducer,
  sidebar: sidebarReducer,
  subAdminState: subAdminReducer,
  parentState: parentReducer,
  classesgroupState: classesgroupReducer,
  coursesState: coursesReducer,
  libraryState: libraryReducer,
  storesState: storesReducer,
  superAdminCoursesState: superAdminCourseReducer,
  innnerLoaderState: innnerLoaderReducer,
  profileState: profileReducer,
  weeklyScheduleState: weeklyScheduleReducer,
  studentBillingState: studentBillingReducer,
  studentPracticeState: studentPracticeTimerReducer,
  chatState: chatReducer,
  notificationState: notificationReducer,
  setingState: settingReducer,
  studentReducer: studentReducer,

  payrollReportState: payrollReducer,
  // AttendanceReport
  attendanceReport: attendanceReport,
  customCommunicationState: customCommReducer,
  contractsReducer: contractsReducer,
  notificationSettingReducer: notificationSettingReducer,
  communicationReducer: communicationReducer,
  generatePayrollReducer: generatePayrollReducer,
  recentActivitiesReducer: recentActivitiesReducer,
  loginWidgetReducer: loginWidgetReducer,
  searchBarReducer: searchBarReducer,
  quizesReducer: quizesReducer,
  importMembersReducer: importMembersReducer,
  musicalInteractivesReducer: musicalInteractivesReducer,
  favouriteListReducer: favouriteListReducer,
  invoice: invoice
})