import { GET_SIDEBAR_MENU, UPDATE_SIDEBAR_MENU } from '../../redux/actionTypes';


export const getSidebarMenu = (payload) => dispatch => {
    dispatch({
        type: GET_SIDEBAR_MENU,
        payload: payload
    })
}

export const updateSidebarMenu = (payload) => dispatch => {
    dispatch({
        type: UPDATE_SIDEBAR_MENU,
        payload: payload
    })
}