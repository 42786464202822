import { DOWNLOAD_ROLE_CSV } from "../actionTypes";

const initialState = {
  downloadRoleCSV: null,
};

export default function importMembersReducer(state = initialState, action) {
  switch (action.type) {
    case DOWNLOAD_ROLE_CSV:
      return {
        ...state,
        downloadRoleCSV: action.payload,
      };
    default:
      return state;
  }
}
