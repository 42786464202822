import { createStore, applyMiddleware } from 'redux'
import axios from 'axios';
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import rootReducer from '../redux/index'
import Cookies from 'universal-cookie';
import SweetAlert from "sweetalert2";

let prodEnv;
let domain = window.location.hostname;
const liveApi = process.env.REACT_APP_DEV_URL;
const devApi = process.env.REACT_APP_DEV_URL;

if (domain === 'www.ampliteach.com') prodEnv = "prod";

const baseUrl = prodEnv === "prod" ? liveApi : devApi;

const cookies = new Cookies();

export const api = axios.create({
    baseURL: baseUrl
});
api.interceptors.response.use(
    (config) => {
        return config;
    },
    (error) => {
        if (error.response.status == 401) {
          cookies.get('loginAsUser') ? cookies.remove('loginAsUser', { path: "/" }) : cookies.remove('amplitech', { path: "/" })
            window.location.reload()
        }
        if (error.response.status == 402) {
          if (window.location.pathname !== '/dashboard') {
            window.location.href = `${window.location.origin}/dashboard`
          }

          SweetAlert.fire({
            text: error.response.data.message,
            icon: "error",
          });
        }
      return Promise.reject(error);
    }
  );

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk.withExtraArgument(api))))
export default store;