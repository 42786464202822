import {
  ADD_QUIZ_CATEGORY,
  GET_QUIZ_CATEGORIES,
  ADD_QUIZ_QUESTION,
  GET_QUIZZES,
  DELETE_QUESTION,
  UPDATE_QUIZ_QUESTION,
  SUBMIT_QUS_ANSWER,
} from "../actionTypes";

const initialState = {
  addCategory: [],
  getQuizCategories: [],
  addQuestion: [],
  getAllQuizes: [],
  deletedQuestion: [],
  page: 1,
  limit: 20,
  totalQuiz: null,
  totalPages: null,
  updateQuestion: [],
};

export default function quizesReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_QUIZ_CATEGORY:
      return {
        ...state,
        addCategory: action.payload,
      };
    case GET_QUIZ_CATEGORIES:
      return {
        ...state,
        getQuizCategories: action.payload,
      };
    case ADD_QUIZ_QUESTION:
      return {
        ...state,
        addQuestion: action.payload,
      };
    case GET_QUIZZES:
      return {
        ...state,
        getAllQuizes: action.payload.results,
        page: action.payload.page,
        limit: action.payload.limit,
        totalQuiz: action.payload.totalResults,
        totalPages: action.payload.totalPages,
      };
    case DELETE_QUESTION:
      return {
        ...state,
        deletedQuestion: action.payload,
      };
    case UPDATE_QUIZ_QUESTION:
      return {
        ...state,
        updateQuestion: action.payload,
      };
    case SUBMIT_QUS_ANSWER:
      return {
        ...state,
      };
    default:
      return state;
  }
}
