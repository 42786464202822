import { APPLY_FILTER_STUDENT, APPLY_SORTBY_STUDENT, FILTERS_BY_STUDENT, GET_ALL_STUDENT, DROPDOWN_STUDENT, GET_INSTRUMENT_BY_STUDENT, GET_STUDENT_INSTRUMENT_NEW, DELETE_STUDENT_INSTRUMENT_NEW, GET_ALLOW_ADD_STUDENT } from '../actionTypes';

const initialState = {
    students: [],
    dropdownStudent: [],
    filterdStudents: [],
    filter: false,
    page: 1,
    limit: 6,
    sortBy: 'createdAt',
    orderBy: -1,
    totalStudent: 0,
    activeStudent: 0,
    inActiveStudent: 0,
    studentInstrumentGenre: [],
    instrumentGenre: [],
    getAllowStudent: {}
}


export default function studentReducer(state = initialState, action) {

    switch (action.type) {

        case GET_ALL_STUDENT:
            console.log('action.payload', action.payload)
            return {
                ...state,
                count: action.payload,
                students: action.payload.results,
                totalStudent: action.payload.totalResults,
                page: action.payload.page ? action.payload.page : 1,
                limit: action.payload.limit ? action.payload.limit : 6,
                activeStudent: action.payload.activeStudentCount,
                inActiveStudent: action.payload.inActiveStudentCount,
                filter: false
            }

        case DROPDOWN_STUDENT:
            return {
                ...state,
                dropdownStudent: action.payload.results
            }

        case APPLY_FILTER_STUDENT:
            return {
                ...state,
                students: action.payload.userInfo.results,
                totalStudent: action.payload.userInfo.totalResults,
                page: action.payload.userInfo.page,
                limit: action.payload.userInfo.limit,
            }

        case APPLY_SORTBY_STUDENT:
            return {
                ...state,
                students: action.payload.results,
                page: action.payload.page,
                limit: action.payload.limit,
                totalStudent: action.payload.totalResults,
                sortBy: action.payload.sortBy,
                orderBy: action.payload.orderBy,
                activeStudent: action.payload.activeStudentCount,
                inActiveStudent: action.payload.inActiveStudentCount,
                filter: false
            }

        case FILTERS_BY_STUDENT:
            if (action.payload.length === 0) {
                return {
                    ...state,
                    filterdStudents: [],
                    filter: true
                }
            } else {
                return {
                    ...state,
                    filterdStudents: action.payload,
                    filter: true
                }
            }
        case GET_STUDENT_INSTRUMENT_NEW:
            return {
                ...state,
                studentInstrumentGenre: action.payload
            }
        case DELETE_STUDENT_INSTRUMENT_NEW:
            let temp = state.studentInstrumentGenre.filter(item => item?.genreId?._id !== action.payload)
            return {
                ...state,
                studentInstrumentGenre: temp
            }
        case GET_ALLOW_ADD_STUDENT:
            return {
                ...state,
                getAllowStudent: action.payload
            }
        default: return state
    }
}