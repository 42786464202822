import {
  FILTER_LIBRARY,
  GET_ALL_Librarys,
  GET_DROPDOWN_LIBRARY_TEACHER,
  GET_COURSE_WISE_LESSON,
  UPDATE_ALL_LIBRARY
} from "../actionTypes";

const initialState = {
  libraryData: [],
  dropdownTeacher: [],
  filter: {},
  page: 1,
  limit: 10,
  sortBy: "createdAt",
  orderBy: -1,
  totalLibray: 0,
  totalPages: 0,
  courseWiseLesson: [],
};

export default function libraryReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_Librarys:
      return {
        ...state,
        libraryData: action.payload.data?.results,
        totalLibray: action.payload?.data?.totalResults,
        totalPages: action.payload?.data?.totalPages,
        page: action.payload?.data?.page ? action.payload?.data?.page : 1,
        limit: action.payload?.data?.limit ? action.payload?.data?.limit : 10,
      };

    case UPDATE_ALL_LIBRARY:
      return {
        ...state,
        libraryData: action.payload,
      };

    case FILTER_LIBRARY:
      return {
        ...state,
        filter: action.payload,
      };

    case GET_DROPDOWN_LIBRARY_TEACHER:
      return {
        ...state,
        dropdownTeacher: action.payload,
      };

    case GET_COURSE_WISE_LESSON:
      return {
        ...state,
        courseWiseLesson: action.payload,
      };

    default:
      return state;
  }
}
