import {
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  LOGIN_AS_IN,
  LOGIN_AS_OUT,
  INNER_LOADER,
} from "../actionTypes";
import Cookies from "universal-cookie";
import { authHeader } from "../../services/authHeader";
const cookies = new Cookies();

export const userLogin = (data) => async (dispatch, getState, api) => {
  const results = await api
    .post("/auth/amplitech/login", data)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: LOGIN_SUCCESS,
          currentUser: res.data.data.token,
          payload: res.data.data
        });
        cookies.set("amplitech", res.data.data.token, { path: "/" });
        // cookies.set("currentData", res.data.data, { path: "/" });
        localStorage.setItem("currentData", JSON.stringify(res.data.data))
      }
      return res;
    })
    .catch((err) => {
      return err.response;
    });
  return results;
};

export const userLogout = () => async (dispatch, getState, api) => {
  const results = await api
    .get("/auth/logout", { headers: authHeader() })
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: LOGOUT_SUCCESS,
        });
        cookies.remove("amplitech", { path: "/" });
        cookies.remove("loginAsUser", { path: "/" });
        localStorage.removeItem('timerId')
        localStorage.removeItem('memoriesdata')
      }
      return res;
    });
  return results;
};

export const loginAsIn = (data) => async (dispatch, getState, api) => {
  const results = await api.post("/auth/amlitech/loginas", data).then((res) => {
    //console.log("data =============",res);
    if (res.status === 200) {
      dispatch({
        type: LOGIN_AS_IN,
        loginAsUser: res.data.data.token,
        payload: res.data.data
      });
      console.log("res.data.data", res.data.data)
      cookies.set("loginAsUser", res.data.data.token, { path: "/" });
      cookies.set("loginAsData", res.data.data, { path: "/" });
      localStorage.setItem("loginAsLocalStorageData", JSON.stringify(res.data.data))
    }
    return res;
  });
  return results;
};

export const loginAsOut = () => (dispatch) => {
  dispatch({
    type: LOGIN_AS_OUT,
  });
  cookies.remove("loginAsUser", { path: "/" });
  localStorage.removeItem('timerId')
  localStorage.removeItem('memoriesdata')
};

export const forgetUserPassword = (data) => async (dispatch, getState, api) => {
  const results = await api
    .post(`/auth/forgetPassword`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
  return results;
};

export const resetUserPassword =
  (data, token) => async (dispatch, getState, api) => {
    const bearerToken = { Authorization: "Bearer " + token };
    const results = await api
      .post(`/auth/resetPassword`, data, {
        headers: bearerToken,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err.response;
      });
    return results;
  };


export const serverSideValidation = (type, value) => async (dispatch, getState, api) => {
  const data = { type, value }
  const results = await api
    .post(`/auth/checkvalidation`, data, { headers: authHeader() })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
  return results;
};


export const getFillterData = (type, value) => async (dispatch, getState, api) => {
  const data = { type, value }
  const results = await api
    .post(`/auth/filter`, data, { headers: authHeader() })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
  return results;
};

export const reVerifyAcc = (_id) => async (dispatch, getState, api) => {
  const results = await api
    .post(`/auth/reSendVerifyAccount`, _id, { headers: authHeader() })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
  return results;
}


