import { PAUSE_PRACTICE_TIME, CURRENT_PRACTICE_TIME, GET_COMPLETE_INCOMPLETE_PRACTICE, GET_ALL_PRACTICE, APPLY_FILTER_FOR_PRACTICE } from '../actionTypes';

const initialState = {
    currentPracticeTime: null,
    practiceTimer: {
        pause: null
    },
    completePractice: 0,
    inCompletePractice: 0,
    countSong: 0,

    practice: [],
    page: 1,
    limit: 6,
    sortBy: 'due_date',
    orderBy: -1,
    totalPractice: 0,
    totalPages: 0
}


export default function studentPracticeReducer(state = initialState, action) {
    switch (action.type) {

        case GET_ALL_PRACTICE:
            return {
                ...state,
                practice: action.payload.results,
                totalPractice: action.payload.totalResults,
                page: action.payload.page ? action.payload.page : 1,
                limit: action.payload.limit ? action.payload.limit : 6,
                totalPages: action.payload.totalPages,

            }

        case APPLY_FILTER_FOR_PRACTICE:
            return {
                ...state,
                practice: action.payload?.data?.results,
                totalPractice: action.payload?.data?.totalResults,
                page: action.payload?.data?.page ? action.payload?.data?.page : 1,
                limit: action.payload?.data?.limit ? action.payload?.data?.limit : 6,
                sortBy: action.payload?.sortBy,
                orderBy: action.payload?.orderBy,
                totalPages: action.payload?.data?.totalPages
            }

        case CURRENT_PRACTICE_TIME:
            return {
                ...state,
                currentPracticeTime: action.payload,
            }

        case GET_COMPLETE_INCOMPLETE_PRACTICE:
            console.log("action.payload", action.payload);
            return {
                ...state,
                completePractice: action.payload.completePracticeCount,
                inCompletePractice: action.payload.inCompletePracticeCount,
                countSong: action.payload.countSong
            }

        case PAUSE_PRACTICE_TIME:
            return {
                ...state,
                practiceTimer: { ...state.practiceTimer, pause: action.payload },
            }

        default: return state
    }
}
