import {
  CHAT_MODAL,
  CHAT_SCROLLL,
  CHAT_USER_LIST,
  FILE_UPLOAD,
  MESSAGE_COUNT,
  MESSAGE_LOADER,
  MESSAGE_RECEIVED,
  READ_MESSAGE,
  SELECT_RECEIVER_DETAILS,
  SET_ACTIVE_ROOM,
  SET_USER_STATUS,
  SHOW_RECEIVER_DETAIL,
  SINGLE_MESSAGE_RECEIVED,
  SOCKET_AUTHENTICATION,
  SOCKET_CONNECTION,
  TYPING_MESSAGE,
} from "../actionTypes";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const initialState = {
  socketConnect: false,
  isAuthenticate: false,
  chatUserList: [],
  messageCount: {},
  senderDetails: null,
  receiverDetails: null,
  senderId: null,
  receiverId: null,
  activeRoom: null,
  messages: [],
  rightSidebar: false,
  file: null,
  typing: {},
  currentlyTyping: [],
  chatModal: false,
  messageLoader: false,
  page: 1,
  limit: 10,
  readMessageId: "",
  appendChat: false,

  tabId: Math.random()
};

export default function chatReducer(state = initialState, action) {
  switch (action.type) {
    case SOCKET_CONNECTION:
      return {
        ...state,
        socketConnect: action.payload,
      };

    case SOCKET_AUTHENTICATION:
      cookies.set("tabId", state.tabId, { path: "/" });
      console.log("SOCKET_AUTHENTICATION", action.payload)
      return {
        ...state,
        isAuthenticate: action.payload.status === 200 ? true : false,
        senderDetails:
          action.payload.status === 200 ? action.payload.userDetails : null,
        senderId:
          action.payload.status === 200
            ? action.payload.userDetails.userId
            : null,
      };

    case CHAT_USER_LIST:
      return {
        ...state,
        chatUserList: action.payload,
      };


    case SET_USER_STATUS:
      const userStatus = state.chatUserList.map((item) => {
        if (item._id === action.payload.userId) {
          item.isOnline = action.payload.isOnline;
        }
        return item;
      });
      return {
        ...state,
        chatUserList: userStatus,
      };

    case SELECT_RECEIVER_DETAILS:
      return {
        ...state,
        receiverDetails: action.payload.reciever,
        receiverId: action.payload.reciever._id,
        activeRoom: action.payload.reciever._id,
      };

    case SINGLE_MESSAGE_RECEIVED:

      const filterLastMessage = state.chatUserList.map((item) => {
        if ((item._id === action.payload.messages.receiver_id || item._id === action.payload.messages.sender_id)) {
          if (item.lastMessage) {
            item.lastMessage.message = action.payload.messages.message;
            item.lastMessage.createdAt = action.payload.messages.createdAt;
          } else {
            const lastMessage = {
              message: action.payload.messages.message,
              createdAt: action.payload.messages.createdAt,
            };
            item.lastMessage = lastMessage;
          }
        }
        // if (item._id === action.payload.messages.sender_id) {
        //   if (item.lastMessage) {
        //     item.lastMessage.message = action.payload.messages.message;
        //     item.lastMessage.createdAt = action.payload.messages.createdAt;
        //   } else {
        //     const lastMessage = {
        //       message: action.payload.messages.message,
        //       createdAt: action.payload.messages.createdAt,
        //     };
        //     item.lastMessage = lastMessage;
        //   }
        // }

        return item;
      });

      let msg = []
      if (state.activeRoom === action.payload.messages.receiver_id || state.activeRoom === action.payload.messages.sender_id) {
        msg = [...state.messages, action.payload.messages]
      } else {
        msg = [...state.messages]
      }
      //console.log("push Object =========",action.payload.messages,msg);
      return {
        ...state,
        messages: msg,
        chatUserList: filterLastMessage,
      };
    case MESSAGE_RECEIVED:
      return {
        ...state,
        messages: action.payload.messages,
        page: action.payload.page,
        limit: action.payload.limit,
      };

    case MESSAGE_COUNT:
      console.log("bbbbbbbbbbcxxxxxxxxxxxxxxxxxxxx---->", action.payload);

      return {
        ...state,
        messageCount: action.payload,
      };

    case SHOW_RECEIVER_DETAIL:
      return {
        ...state,
        rightSidebar: action.payload,
      };

    case FILE_UPLOAD:
      return {
        ...state,
        file: action.payload,
      };

    case TYPING_MESSAGE:
      let typingUser = Object.assign({}, state.typing);
      if (action.payload) {
        if (!action.payload?.user?.includes(state.senderId)) {
          typingUser[action.payload?.user?.[0]._id] = action.payload?.user;
        }
        return {
          ...state,
          typing: typingUser,
        };
      } else {
        return { ...state, typing: {} };
      }

    case CHAT_MODAL:
      return {
        ...state,
        chatModal: action.payload,
      };

    case SET_ACTIVE_ROOM:
      //  console.log("student chat",action.payload);
      const selectedRoom = state.chatUserList.find(
        (item) => item._id === action.payload
      );
      //   console.log("selectedRoom",selectedRoom);
      return {
        ...state,
        receiverDetails: selectedRoom,
        receiverId: action.payload,
        activeRoom: action.payload,
      };

    case MESSAGE_LOADER:
      return {
        ...state,
        messageLoader: action.payload,
      };

    case READ_MESSAGE:
      return {
        ...state,
        readMessageId: action.payload,
      };

    case CHAT_SCROLLL:
      return {
        ...state,
        appendChat: action.payload,
      };
    default:
      return state;
  }
}
