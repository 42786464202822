import { INNER_LOADER } from '../actionTypes';

const initialState = {
    isLoading: false
}

export default function innerLoaderReducer(state = initialState, action) {
    switch (action.type) {

        case INNER_LOADER:
            return {
                ...state,
                isLoading: action.loading
            }

        default: return state
    }
}