export const SUPERADMIN = [
    {
        Items: [
            {
                title: "Dashboard", tag: "dashboard_nav", path: `${process.env.PUBLIC_URL}/dashboard`, active: false, type: 'link'
            }
        ]
    },
    {
        Items: [
            {
                title: "Members", tag: "members_nav", path: `${process.env.PUBLIC_URL}/members`, type: "sub", active: false, children: [
                    { title: "Schools", path: "/member/list-school", type: 'link' },
                    { title: "Private Teachers", path: "/member/private-teacher", type: 'link' },
                ]
            }
        ]
    },
    {
        Items: [
            {
                title: "Learning", tag: "learning_nav", path: `${process.env.PUBLIC_URL}/learning/courses`, type: "sub", active: false, children: [
                    { title: "Courses", path: "/learning/courses", type: 'link' },
                    { title: "Library", path: "/learning/library", type: 'link' }
                ]
            }
        ]
    },
    {
        Items: [
            {
                title: "Build My Biz", tag: "dashboard_nav", path: `${process.env.PUBLIC_URL}/MyBiz/category/60c9dad868e2ab231faafeb9`, active: false, type: 'link'
            }
        ]
    },
    {
        Items: [
            {
                title: "Store", tag: "dashboard_nav", path: `${process.env.PUBLIC_URL}/store`, type: "sub", active: false, children: [
                    { title: "Order Listing", path: "/stores/order-listing/1", type: 'link' },
                    { title: "Seller", path: "/stores/seller", type: 'link' }
                ]
            }
        ]
    },
    {
        Items: [
            {
                title: "School Billing", tag: "stud_billing_nav", path: `${process.env.PUBLIC_URL}/schoolbilling/planbuilder`, type: "sub", active: false, children: [
                    { title: "Plan Builder", path: "/schoolbilling/planbuilder", type: 'link' },
                    { title: "Book Plan", path: "/schoolbilling/bookplan", type: 'link' },
                    // { title: "Plan Widget", path: "/schoolbilling/planwidget", type: 'link' },
                    // { title: "Login Widget", path: "/schoolbilling/loginwidget", type: 'link' }
                ]
            }
        ]
    },
    {
        Items: [
            {
                title: "Reports & Info", tag: "reporting_nav", path: `${process.env.PUBLIC_URL}/reports/payment-reporting`, type: "sub", active: false, children: [
                    { title: "Payment Report", path: "/reports/payment-reporting", type: 'link' },
                    // { title: "School Report", path: "/reports/school-reporting", type: 'link' }
                ]
            }
        ]
    },
    {
        Items: [
            {
                title: "Quizzes", tag: "quizzes_nav", path: `${process.env.PUBLIC_URL}/quizzes`, active: false, type: 'link'
            }
        ]
    },
    {
        Items: [
            {
                title: "Musical Interactives", tag: "musicalInteractives_nav", path: `${process.env.PUBLIC_URL}/musicalInteractives/earTrainer`, type: "sub", active: false, children: [
                    { title: "Ear Trainer", path: "/musicalInteractives/earTrainer", type: 'link' },
                ]
            }
        ]
    },
]

export const ADMIN = [
    {
        Items: [
            {
                title: "Dashboard", tag: "dashboard_nav", path: `${process.env.PUBLIC_URL}/dashboard`, active: false, type: 'link'
            }
        ]
    },
    {
        Items: [
            {
                title: "Members", tag: "members_nav", path: `${process.env.PUBLIC_URL}/members`, type: "sub", active: false, children: [
                    { title: "Sub Admins", path: "/member/sub-admins", type: 'link' },
                    { title: "Teachers", path: "/member/teachers", type: 'link' },
                    { title: "Students", path: "/member/students", type: 'link' },
                    { title: "Parents", path: "/member/parents", type: 'link' },
                    { title: "Add Family", path: "/family/add", type: 'link' },
                    { title: "Classes/Groups", path: "/member/classes-group", type: 'link' }
                ]
            }
        ]
    },
    {
        Items: [
            {
                title: "Learning", tag: "learning_nav", path: `${process.env.PUBLIC_URL}/learnings`, type: "sub", active: false, children: [
                    { title: "Courses", path: "/learning/courses", type: 'link' },
                    { title: "Approve Course", path: "/learning/approve-course", type: 'link' },
                    { title: "Library", path: "/learning/library", type: 'link' }
                ]
            }
        ]
    },
    {
        Items: [
            {
                // title: "Build My Biz", tag: "dashboard_nav", path: `${process.env.PUBLIC_URL}/MyBiz/category/60c9dad868e2ab231faafeb9`, active: false, type: 'link'
                title: "Build My Biz", tag: "dashboard_nav", path: `${process.env.PUBLIC_URL}/MyBizNew`, active: false, type: 'link'
                // children:[
                //     { title: "Strategies for success", path: "/category/60c9dad868e2ab231faafeb9", type: 'link' },
                //     { title: "Marketing & advertisement", path: "/category/60c9db1968e2ab231faafeba", type: 'link' },
                // { title: "Business Administration", path: "/category/60c9db2868e2ab231faafebb", type: 'link' },
                // { title: "Sales Training", path: "/category/60c9db3668e2ab231faafebc", type: 'link' },
                // { title: "Teacher Training", path: "/category/60c9db5268e2ab231faafebd", type: 'link' },
                // { title: "Curriculum", path: "/category/60c9db6068e2ab231faafebe", type: 'link' },
                // ]
            }
        ]
    },

    {
        Items: [
            {
                title: "My Schedule", tag: "schedualing_nav", type: "sub", path: `${process.env.PUBLIC_URL}/scheduling/weekly`, active: false, children: [
                    { title: "Weekly Schedule", path: "/scheduling/weekly", type: 'link' },
                    { title: "Daily Schedule", path: "/scheduling/daily", type: 'link' },
                    // { title: "Weekly Schedule", path: "/scheduling/weekly", type: 'link' }
                ]
            }
        ]
    },
    {
        Items: [
            {
                title: "Plans / Payments", tag: "stud_billing_nav", path: `${process.env.PUBLIC_URL}/studentbilling/planbuilder`, type: "sub", active: false, children: [
                    { title: "Plan Builder", path: "/studentbilling/planbuilder", type: 'link' },
                    // { title: "Group Plans", path: "/studentbilling/groupplans", type: 'link' },
                    // { title: "Store Builder", path: "/studentbilling/storebuilder", type: 'link' },
                    // { title: "Approve Plan", path: "/studentbilling/approveplan", type: 'link' },
                    { title: "Plan Widget", path: "/studentbilling/planwidget", type: 'link' },
                    // { title: "Store Widget", path: "/studentbilling/storewidget", type: 'link' },
                    { title: "Login Widget", path: "/studentbilling/loginwidget", type: 'link' },
                    { title: "Student Login Widget", path: "/studentbilling/studentWidget", type: 'link' },
                    // { title: "New Invoice", path: "/studentbilling/newInvoice", type: 'link' },
                    { title: "Invoicing", path: "/studentbilling/invoiceList", type: 'link' },
                    // { title: "Recurring Invoices", path: "/studentbilling/recuringInvoice", type: 'link' },
                ]
            }
        ]
    },
    {
        Items: [
            {
                title: "Reports & Info", tag: "reporting_nav", path: `${process.env.PUBLIC_URL}/reportings`, type: "sub", active: false, children: [
                    { title: "Attendance", path: "/reporting/attendance", type: 'link' },
                    { title: "Payments", path: "/reports/payment-reporting", type: 'link' },
                    { title: "Payroll", path: "/reporting/payrolls", type: 'link' },
                    { title: "Approved Payroll", path: "/reporting/aproovedPayrolls", type: 'link' },
                    { title: "New Widget Students", path: "/reporting/approvedStudent", type: 'link' },
                    { title: "Student Report", path: "/reporting/student", type: 'link' },
                    { title: "Communication Log", path: "/reporting/communication-log", type: 'link' },
                    // { title: "Payment Report", path: "/reports/payment-reporting", type: 'link' }
                ]
            }
        ]
    },
    {
        Items: [
            {
                title: "Group Email", tag: "dashboard_nav", path: `${process.env.PUBLIC_URL}/email`, active: false, type: 'link'
            }
        ]
    },
]

export const showStoreAdmin = {
    Items: [
        {
            title: "Store", tag: "dashboard_nav", path: `${process.env.PUBLIC_URL}/stores/product-listing`, type: "sub", active: false, children: [
                { title: "Inventory", path: "/stores/stocks", type: 'link' },
            ]
        }
    ]
}

export const SUBADMINMENUITEM = [
    {
        Items: [
            {
                title: "Dashboard", tag: "dashboard_nav", path: `${process.env.PUBLIC_URL}/dashboard`, active: false, type: 'link'
            }
        ]
    },
    {
        Items: [
            {
                title: "Members", tag: "members_nav", path: `${process.env.PUBLIC_URL}/members`, type: "sub", active: false, children: [
                    { title: "Teachers", path: "/member/teachers", type: 'link' },
                    { title: "Students", path: "/member/students", type: 'link' },
                    { title: "Parents", path: "/member/parents", type: 'link' },
                    { title: "Add Family", path: "/family/add", type: 'link' },
                    { title: "Classes/Groups", path: "/member/classes-group", type: 'link' }
                ]
            }
        ]
    },
    {
        Items: [
            {
                title: "Learning", tag: "learning_nav", path: `${process.env.PUBLIC_URL}/learnings`, type: "sub", active: false, children: [
                    { title: "Courses", path: "/learning/courses", type: 'link' },
                    { title: "Approve Course", path: "/learning/approve-course", type: 'link' },
                    { title: "Library", path: "/learning/library", type: 'link' }
                ]
            }
        ]
    },
    {
        Items: [
            {
                // title: "Build My Biz", tag: "dashboard_nav", path: `${process.env.PUBLIC_URL}/MyBiz/category/60c9dad868e2ab231faafeb9`, active: false, type: 'link'
                title: "Build My Biz", tag: "dashboard_nav", path: `${process.env.PUBLIC_URL}/MyBizNew`, active: false, type: 'link'
            }
        ]
    },
    {
        Items: [
            {
                title: "Store", tag: "dashboard_nav", path: `${process.env.PUBLIC_URL}/stores/product-listing`, type: "sub", active: false
            }
        ]
    },
    {
        Items: [
            {
                title: "My Schedule", tag: "schedualing_nav", path: `${process.env.PUBLIC_URL}/scheduling/weekly`, type: "sub", active: false, children: [
                    { title: "Weekly Schedule", path: "/scheduling/weekly", type: 'link' },
                    { title: "Daily Schedule", path: "/scheduling/daily", type: 'link' },
                    // { title: "Monthly Schedule", path: "/scheduling/monthly", type: 'link' }
                ]
            }
        ]
    },
    {
        Items: [
            {
                title: "Plans / Payments", tag: "stud_billing_nav", path: `${process.env.PUBLIC_URL}/studentbilling/planbuilder`, type: "sub", active: false, children: [
                    { title: "Plan Builder", path: "/studentbilling/planbuilder", type: 'link' },
                    { title: "Plan Widget", path: "/studentbilling/planwidget", type: 'link' },
                    { title: "Login Widget", path: "/studentbilling/loginwidget", type: 'link' },
                    { title: "Student Login Widget", path: "/studentbilling/studentWidget", type: 'link' },
                    { title: "Invoicing", path: "/studentbilling/invoiceList", type: 'link' },
                    // { title: "Registration Widget", path: "/studentbilling/registerwidget", type: 'link' },
                ]
            }
        ]
    },
    {
        Items: [
            {
                title: "Reports & Info", tag: "reporting_nav", path: `${process.env.PUBLIC_URL}/reportings`, type: "sub", active: false, children: [
                    // { title: "Student Practice", path: "/dashboard", type: 'link' },
                    { title: "Attendance", path: "/reporting/attendance", type: 'link' },
                    // { title: "Payments", path: "/reporting/payments", type: 'link' },
                    { title: "Payments", path: "/reports/payment-reporting", type: 'link' },
                    { title: "New Widget Students", path: "/reporting/approvedStudent", type: 'link' },
                    { title: "Student Report", path: "/reporting/student", type: 'link' },
                    { title: "Communication Log", path: "/reporting/communication-log", type: 'link' }
                ]
            }
        ]
    },
    {
        Items: [
            {
                title: "Group Email", tag: "dashboard_nav", path: `${process.env.PUBLIC_URL}/email`, active: false, type: 'link'
            }
        ]
    },
]

export const TEACHERMENUITEM = [
    {
        Items: [
            {
                title: "Dashboard", tag: "dashboard_nav", path: `${process.env.PUBLIC_URL}/dashboard`, active: false, type: 'link'
            }
        ]
    },
    {
        Items: [
            {
                title: "Members", tag: "members_nav", path: `${process.env.PUBLIC_URL}/members`, type: "sub", active: false, children: [
                    { title: "Students", path: "/member/students", type: 'link' },
                    { title: "Classes/Groups", path: "/member/classes-group", type: 'link' }
                ]
            }
        ]
    },
    {
        Items: [
            {
                title: "Learning", tag: "learning_nav", path: `${process.env.PUBLIC_URL}/learning/courses`, type: "sub", active: false, children: [
                    { title: "Courses", path: "/learning/courses", type: 'link' },
                    { title: "Practice", path: "/learning/practice", type: 'link' },
                    { title: "Library", path: "/learning/library", type: 'link' }
                ]
            }
        ]
    },
    {
        Items: [
            {
                // title: "Build My Biz", tag: "dashboard_nav", path: `${process.env.PUBLIC_URL}/MyBiz/category/60c9dad868e2ab231faafeb9`, active: false, type: 'link'
                title: "Build My Biz", tag: "dashboard_nav", path: `${process.env.PUBLIC_URL}/MyBizNew`, active: false, type: 'link'
            }
        ]
    },
    // {
    //     Items: [
    //         {
    //             title: "Store", tag: "dashboard_nav", path: `${process.env.PUBLIC_URL}/stores/product-listing`, type: "sub", active: false 
    //         }
    //     ]
    // },
    {
        Items: [
            {
                title: "My Schedule", tag: "schedualing_nav", path: `${process.env.PUBLIC_URL}/scheduling/weekly`, type: "sub", active: false, children: [
                    { title: "Weekly Schedule", path: "/scheduling/weekly", type: 'link' },
                    { title: "Daily Schedule", path: "/scheduling/daily", type: 'link' },
                    // { title: "Monthly Schedule", path: "/scheduling/monthly", type: 'link' }
                ]
            }
        ]
    },
    // {
    //     Items: [
    //         {
    //             title: "Plans / Payments", tag: "stud_billing_nav", path: `${process.env.PUBLIC_URL}/studentbilling/planbuilder`, type: "sub", active: false, children: [
    //                 { title: "Plan Builder", path: "/studentbilling/planbuilder", type: 'link' },
    //                 { title: "Plan Widget", path: "/studentbilling/planwidget", type: 'link' },
    //                 // { title: "Registration Widget", path: "/studentbilling/registerwidget", type: 'link' },
    //                 { title: "Login Widget", path: "/studentbilling/loginwidget", type: 'link' }
    //             ]
    //         }
    //     ]
    // },
    {
        Items: [
            {
                title: "Reports & Info", tag: "reporting_nav", path: `${process.env.PUBLIC_URL}/reportings`, type: "sub", active: false, children: [
                    // { title: "Student Practice", path: "/dashboard", type: 'link' },
                    { title: "Attendance", path: "/reporting/attendance", type: 'link' },
                    { title: "Payments", path: "/reports/payment-reporting", type: 'link' },
                    // { title: "Payments", path: "/reporting/payments", type: 'link' },
                    { title: "Communication Log", path: "/reporting/communication-log", type: 'link' }
                ]
            }
        ]
    }
]

export const STUDENTMENUITEM = [
    {
        Items: [
            {
                title: "Dashboard", tag: "dashboard_nav", path: `${process.env.PUBLIC_URL}/dashboard`, type: "sub", active: false, children: [
                    { title: "Courses", path: "/learning/courses", type: 'link' },
                    { title: "Practice", path: "/learning/practice", type: 'link' },
                    { title: "Library", path: "/learning/library", type: 'link' }
                ]
            }
        ]
    },
    {
        Items: [
            {
                title: "My Schedule", tag: "schedualing_nav", path: `${process.env.PUBLIC_URL}/scheduling/weekly`, type: "sub", active: false, children: [
                    { title: "Weekly Schedule", path: "/scheduling/weekly", type: 'link' },
                    { title: "Monthly Schedule", path: "/scheduling/monthly", type: 'link' }
                ]
            }
        ]
    },
    {
        Items: [
            {
                title: "Payment / Billing", tag: "dashboard_nav", path: `${process.env.PUBLIC_URL}/PaymentBilling`, active: false, type: 'link'
            }
        ]
    },
    {
        Items: [
            {
                title: "Reports & Info", tag: "reporting_nav", path: `${process.env.PUBLIC_URL}/reportings`, type: "sub", active: false, children: [
                    // { title: "Student Practice", path: "/dashboard", type: 'link' },
                    { title: "Attendance", path: "/reporting/attendance", type: 'link' },
                    { title: "Payments", path: "/reporting/payments", type: 'link' },
                    { title: "Student Practice", path: "/reporting/student-practice", type: 'link' },
                    { title: "Communication Log", path: "/reporting/communication-log", type: 'link' },
                ]
            }
        ]
    }
]

export const PARENTMENUITEM = [

    {
        Items: [
            {
                title: "Members", tag: "members_nav", path: `${process.env.PUBLIC_URL}/member/children`, type: "sub", active: false, children: [
                    { title: "Children", path: "/member/children", type: 'link' },
                ]
            }
        ]
    },
    {
        Items: [
            {
                title: "Payment / Billing", tag: "dashboard_nav", path: `${process.env.PUBLIC_URL}/PaymentBilling`, active: false, type: 'link'
            }
        ]
    },
    {
        Items: [
            {
                title: "Reports & Info", tag: "reporting_nav", path: `${process.env.PUBLIC_URL}/reportings`, type: "sub", active: false, children: [
                    // { title: "Student Practice", path: "/dashboard", type: 'link' },
                    { title: "Attendance", path: "/reporting/attendance", type: 'link' },
                    { title: "Payments", path: "/reporting/payments", type: 'link' },
                    { title: "Student Practice", path: "/reporting/student-practice", type: 'link' },
                    { title: "Communication Log", path: "/reporting/communication-log", type: 'link' }
                ]
            }
        ]
    }
]
