import { GET_ALL_CLESSES_OR_GROUP, FILTERS_BY_CLESSES_OR_GROUP,GET_CLESSES_OR_GROUP_BY_TEACHER, APPLY_SORTBY_CLESSES_GROUP, APPLY_FILTER_CLESSES_GROUP } from '../actionTypes';

const initialState = {
    classesgroup: [],
    filterdclassesgroup: [],
    filter: false,
    page:1,
    limit:6,
    sortBy: 'createdAt',
    orderBy: -1,
    totalClassGroup:0
}

export default function classesgroupReducer(state = initialState, action) {
    switch (action.type) {

        case GET_ALL_CLESSES_OR_GROUP:
            return {
                ...state,
                classesgroup: action.payload.results,
                page: action.payload.page,
                limit: action.payload.limit,
                totalClassGroup:action.payload.totalResults,
                filter: false
            }

        case APPLY_FILTER_CLESSES_GROUP:
            return {
                ...state,
                classesgroup: action.payload.class_group.results,
                page: action.payload.class_group.page,
                limit: action.payload.class_group.limit,
                totalClassGroup:action.payload.class_group.totalResults,
            }

        case APPLY_SORTBY_CLESSES_GROUP:
            return {
                ...state,
                classesgroup: action.payload.results,
                page: action.payload.page,
                limit: action.payload.limit,
                totalClassGroup:action.payload.totalResults,
                sortBy: action.payload.sortBy,
                orderBy: action.payload.orderBy,
                filter: false
            }
    
        case GET_CLESSES_OR_GROUP_BY_TEACHER:
            return {
                ...state,
                classesgroup: action.payload.results,
                page: action.payload.page,
                limit: action.payload.limit,
                totalClassGroup:action.payload.totalResults,
            }

        case FILTERS_BY_CLESSES_OR_GROUP:
            if (action.payload.length === 0) {
                return {
                    ...state,
                    filterdclassesgroup: [],
                    filter: true
                }
            } else {
                return {
                    ...state,
                    filterdclassesgroup: action.payload,
                    filter: true
                }
            }
        default: return state
    }
}