import moment from "moment";

export const timeGreeting = () => {
  const d = new Date();
  const time = d.getHours();
  if (time < 12) {
    return "Good Morning";
  }
  if (time < 17) {
    return "Good Afternoon";
  } else {
    return "Good Evening";
  }
};

export const formatPhoneNumber = (str) => {
  var value = str;
  value = value
    .replace(/\D+/g, "")
    .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  return value;
};

export const zipCodeFormatNumber = (str) => {
  return str.replace(/[^\d]/g, "").replace(/(\d{3})(\d{4})/, "$1-$2");
};

export const getMembersDateFormate = (date) => {
  const createdAt = new Date(date);
  var dd = createdAt.getDate();
  var yyyy = createdAt.getFullYear();
  const monthNames = [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  if (dd < 10) {
    dd = "0" + dd;
  }
  return monthNames[createdAt.getMonth()] + " " + dd + ", " + yyyy;
};

export const onlyNumber = event => {

  const keycode = event.which;
  if (keycode === 13) {
  } else if (
    !(
      event.shiftKey === false &&
      (keycode === 8 ||
        keycode === 46 ||
        keycode === 37 ||
        keycode === 39 ||
        (keycode >= 48 && keycode <= 57))
    )
  )
    event.preventDefault();
};

// export const cardValidator = (cn) => {
//   let amex = new RegExp('^3[47][0-9]{13}$');
//   let visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$');            

//   let mastercard = new RegExp('^5[1-5][0-9]{14}$');
//   let mastercard2 = new RegExp('^2[2-7][0-9]{14}$');

//   let disco1 = new RegExp('^6011[0-9]{12}[0-9]*$');
//   let disco2 = new RegExp('^62[24568][0-9]{13}[0-9]*$');
//   let disco3 = new RegExp('^6[45][0-9]{14}[0-9]*$');

//   let diners = new RegExp('^3[0689][0-9]{12}[0-9]*$');
//   let jcb =  new RegExp('^35[0-9]{14}[0-9]*$');

//   console.log("visa.test(cn)",visa.test(cn));
//   if (visa.test(cn)) {
//     return 'VISA';
//   }
//   if (amex.test(cn)) {
//     return 'AMEX';
//   }
//   if (mastercard.test(cn) || mastercard2.test(cn)) {
//     return 'MASTERCARD';
//   }
//   if (disco1.test(cn) || disco2.test(cn) || disco3.test(cn)) {
//     return 'DISCOVER';
//   }
//   if (diners.test(cn)) {
//     return 'DINERS';
//   }
//   if (jcb.test(cn)) {
//     return 'JCB';
//   }
//   return undefined;
// }


export const dayPipe = (date) => {
  var DATE = moment(date);
  var REFERENCE = moment();
  var TODAY = REFERENCE.clone().startOf('day');
  var YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');

  // If you want only date from date object
  if (DATE.isSame(REFERENCE, 'week')) {
    // Check if date is today, yesterday, weekday or others
    if (DATE.isSame(TODAY, 'd')) return DATE.format('hh:mm A');
    else if (DATE.isSame(YESTERDAY, 'd')) return 'Yesterday';
    else return DATE.format('DD/MM/YYYY');
  }
};


export function setWithExpiry(key, value, ttl) {
  const item = {
    value: value,
    expiry: new Date().getTime() + ttl
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getWithExpiry(key) {
  const itemString = window.localStorage.getItem(key);
  if (!itemString) return null;

  const item = JSON.parse(itemString);
  const isExpired = new Date().getTime() > item.expiry;

  if (isExpired) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
}