import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import {
  SuperAdminRoute,
  AdminRoute,
  SubAdminRoute,
  TeacherRoute,
  StudentRoute,
  ParentRoute,
} from "./route";
const PrivateRouter = () => {
  const { currentUser, loginAsUser } = useSelector((state) => state.auth);
  const [privateRoutes, setPrivateRoutes] = useState([]);

  useEffect(() => {
    const routes = () => {
      if (loginAsUser) {
        switch (loginAsUser?.role) {
          case "admin":
            return AdminRoute;

          case "subadmin":
            return SubAdminRoute;

          case "teacher":
            return TeacherRoute;

          case "student":
            return StudentRoute;

          case "parent":
            return ParentRoute;

          default:
            return [];
        }
      } else {
        switch (currentUser?.role) {
          case "superadmin":
            return SuperAdminRoute;

          case "admin":
            return AdminRoute;

          case "subadmin":
            return SubAdminRoute;

          case "teacher":
            return TeacherRoute;

          case "student":
            return StudentRoute;

          case "parent":
            return ParentRoute;

          default:
            return [];
        }
      }
    };
    setPrivateRoutes(routes());
  }, [currentUser, loginAsUser]);

  return privateRoutes.map(({ path, Component, title }, key) => (
    <Route
      path={path}
      render={(props) => (
        <>
          {title && (
            <Helmet>
              <title>{title}</title>
            </Helmet>
          )}
          <Component {...props} />
        </>
      )}
      key={key}
    />
  ));
};

export default PrivateRouter;
