import { GET_ALL_SUBADMIN, FILTERS_BY_SUBADMIN,EDIT_SUBADMIN_ITEM,GET_SUBADMIN_ID, APPLY_SORTBY_SUBADMIN, APPLY_FILTER_SUBADMIN } from '../actionTypes';

const initialState = {
    subAdmin: [],
    subAdminId: null,
    filterdSubAdmin: [],
    filter: false,
    editSubAdminItem:{},
    page:1,
    limit:6,
    sortBy: 'createdAt',
    orderBy: -1,
    totalSubAdmin:0
}

export default function subAdminReducer(state = initialState, action) {
    switch (action.type) {

        case GET_ALL_SUBADMIN:
            return {
                ...state,
                subAdmin: action.payload.results,
                page: action.payload.page,
                limit: action.payload.limit,
                totalSubAdmin:action.payload.totalResults,
                filter: false
            }

        case APPLY_FILTER_SUBADMIN:
            return {
                ...state,
                subAdmin: action.payload.userInfo.results,
                page: action.payload.userInfo.page,
                limit: action.payload.userInfo.limit,
                totalSubAdmin:action.payload.userInfo.totalResults,
            }
    
        case APPLY_SORTBY_SUBADMIN:
            return {
                ...state,
                subAdmin: action.payload.results,
                page: action.payload.page,
                limit: action.payload.limit,
                totalSubAdmin:action.payload.totalResults,
                sortBy: action.payload.sortBy,
                orderBy: action.payload.orderBy,
                filter: false
            }
    

        case EDIT_SUBADMIN_ITEM:
            return {
                ...state,
                editSubAdminItem: action.payload.item,
                isEdit: action.payload.isEdit,
            }

        case GET_SUBADMIN_ID:
            return {
                ...state,
                subAdminId:action.id
            }

        case FILTERS_BY_SUBADMIN:
            if (action.payload.length === 0) {
                return {
                    ...state,
                    filterdSubAdmin: [],
                    filter: true
                }
            } else {
                return {
                    ...state,
                    filterdSubAdmin: action.payload,
                    filter: true
                }
            }


        default: return state
    }
}