import { GET_ALL_SUPERADMIN_COURSES,EDIT_SUPERADMIN_COURSE_ITEM,GET_SUPERADMIN_COURSE_ID } from '../actionTypes';

const initialState = {
    superAdminCourse: [],
    superAdminCourseId: null,
    // filterdSubAdmin: [],
    // filter: false,
    editSuperAdminCourseItem:{},
}

export default function superAdminCourseReducer(state = initialState, action) {
    switch (action.type) {

        case GET_ALL_SUPERADMIN_COURSES:
            return {
                ...state,
                superAdminCourse: action.payload,
                filter: false
            }

        case EDIT_SUPERADMIN_COURSE_ITEM:
            return {
                ...state,
                editSuperAdminCourseItem: action.payload.item,
                isEdit: action.payload.isEdit,
            }

        case GET_SUPERADMIN_COURSE_ID:
            return {
                ...state,
                superAdminCourseId:action.id
            }

        // case FILTERS_BY_SUBADMIN:
        //     if (action.payload.length === 0) {
        //         return {
        //             ...state,
        //             filterdSubAdmin: [],
        //             filter: true
        //         }
        //     } else {
        //         return {
        //             ...state,
        //             filterdSubAdmin: action.payload,
        //             filter: true
        //         }
        //     }


        default: return state
    }
}