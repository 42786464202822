import { GET_ATTENDANCE_TEACHER_REPORT, GET_STUDENTLIST_TEACHERWISE_REPORT, GET_ATTENDANCE_STUDENT_REPORT, GET_RETENTION_REPORT, UPDATE_BANK_EXP_DATE, GET_BANKED_REPORT, DELETE_BANKED_REPORT, GET_VIDEOS, GET_ASSIGN_PRACTICE, GET_PRACTICE_TIMER } from '../actionTypes';

const initialState = {
    attendanceTeacherReport: [],
    attendanceStudentReport: [],
    getStudentReportAttendance: {},
    retentionReport: [],
    assignPractice: [],
    practiceTimer: [],
    videosDetails: [],
    bankedReport: [],
    // pagination
    page: 1,
    limit: 5,
    totalPages: 0,
    totalResults: 0,

    practicepage: 1,
    practicelimit: 5,
    practicetotalPages: 0,
    practicetotalResults: 0,

    assignpage: 1,
    assignlimit: 5,
    assigntotalPages: 0,
    assigntotalResults: 0

}

export default function reportingReducer(state = initialState, action) {
    switch (action.type) {

        case GET_ATTENDANCE_TEACHER_REPORT:
            return {
                ...state,
                attendanceTeacherReport: action.payload,
            }
        case GET_STUDENTLIST_TEACHERWISE_REPORT:
            return {
                ...state,
                attendanceStudentReport: action.payload,
            }
        case GET_ATTENDANCE_STUDENT_REPORT:
            let studentSchedule = action.payload?.[0]?.studentSchedule?.reduce((acc, cur) => ({ ...cur, ...acc }))
            let groupSchedule = action.payload?.[0]?.groupSchedule?.reduce((acc, cur) => ({ ...cur, ...acc }))
            const { studAttendReports, classAttendReports } = action?.payload[1]
            return {
                ...state,
                getStudentReportAttendance: { studentSchedule: { ...studentSchedule }, ...action.payload[1], groupSchedule: { ...groupSchedule } },
                page: studAttendReports ? studAttendReports?.page : classAttendReports?.page,
                totalPages: studAttendReports ? studAttendReports?.totalPages : classAttendReports?.totalPages,
                totalResults: studAttendReports ? studAttendReports.totalResults : classAttendReports?.totalResults,
                limit: studAttendReports ? studAttendReports?.limit : classAttendReports?.limit
            }
        case GET_RETENTION_REPORT:
            return {
                ...state,
                retentionReport: action.payload,
                page: action?.payload.page,
                totalPages: action?.payload.totalPages,
                totalResults: action?.payload.totalResults,
                limit: action?.payload.limit

            }
        case GET_BANKED_REPORT:
            let bankReport = action.payload?.allBankedLesson?.map((item) => {
                if (item.bankLessons.length) {
                    Object.assign(item.bankLessons[0], { teacherName: item.teacherName });
                }
                return item
            })
            return {
                ...state,
                bankedReport: bankReport
            }
        case DELETE_BANKED_REPORT:
            const { deleteIndex, assignedId } = action.payload;
            let temp = { ...state };
            deleteIndex?.map(num => {
                assignedId?.map(id => {
                    let del = state.bankedReport[num].bankLessons.findIndex(data => data.assignedId === id);
                    if (del > -1) {
                        temp.bankedReport[num].bankLessons.splice(del, 1);
                    }
                });
            })
            return { ...temp }

        case 'GET_BANK_EXP_DATE':
            let tempBankedReport = [...state.bankedReport]
            tempBankedReport[action.payload.index].bankLessons[action.payload.subIndex] = { ...tempBankedReport[action.payload.index].bankLessons[action.payload.subIndex], bankedExpDate: action.payload?.date }
            return { ...state, bankReport: tempBankedReport }

        case UPDATE_BANK_EXP_DATE:
            return {
                ...state,
            }

        case GET_ASSIGN_PRACTICE:
            return {
                ...state,
                assignPractice: action.payload,
                assignpage: action?.payload.page,
                assigntotalPages: action?.payload.totalPages,
                assigntotalResults: action?.payload.totalResults,
                assignlimit: action?.payload.limit

            }

        case GET_PRACTICE_TIMER:
            return {
                ...state,
                practiceTimer: action.payload,
                practicepage: action?.payload.page,
                practicetotalPages: action?.payload.totalPages,
                practicetotalResults: action?.payload.totalResults,
                practicelimit: action?.payload.limit

            }

        case GET_VIDEOS:
            return {
                ...state,
                videosDetails: action.payload.results,
                page: action?.payload.page,
                totalPages: action?.payload.totalPages,
                totalResults: action?.payload.totalResults,
                limit: action?.payload.limit

            }

        default: return state
    }
}