import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const Home = () => {
  const history = useHistory();

  useEffect(() => {
    window.open('https://about.ampliteach.com/','_self')
  }, [history]);

  return null;
};

export default Home;
