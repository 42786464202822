import { GET_ALL_TEACHER, FILTERS_BY_TEACHER, APPEND_INSTRUMENT_GENRE, REMOVE_APPEND_INSTRUMENT_GENRE, GET_INSTRUMENT_BY_TEACHER, APPLY_FILTER_TEACHER, APPLY_SORTBY_TEACHER, GET_INSTRUMENT_BY_TEACHER_NEW, DELETE_INSTRUMENT_BY_TEACHER_NEW, DROPDOWN_TEACHER, GET_DROPDOWN_TEACHER } from '../actionTypes';

const initialState = {
    teachers: [],
    dropdownTeacher: [],
    filterdTeachers: [],
    filter: false,

    instrumentGenre: [],

    teacherInstrumentGenre: [],
    InstrumentGenreTeacherNew: [],
    page: 1,
    limit: 6,
    sortBy: 'createdAt',
    orderBy: -1,
    totalTeacher: 0,

    // scheduleDropdownTeacher:[]
}


export default function teacherReducer(state = initialState, action) {
    switch (action.type) {

        case GET_ALL_TEACHER:
            return {
                ...state,
                teachers: action.payload.results,
                totalTeacher: action.payload.totalResults,
                page: action.payload.page ? action.payload.page : 1,
                limit: action.payload.limit ? action.payload.limit : 6,
                filter: false
            }

        case DROPDOWN_TEACHER:
            return {
                ...state,
                dropdownTeacher: action.payload.results
            }

        case GET_DROPDOWN_TEACHER:
            return {
                ...state,
                dropdownTeacher: action.payload
            }

        case APPLY_FILTER_TEACHER:
            return {
                ...state,
                teachers: action.payload.userInfo.results,
                page: action.payload.userInfo.page,
                limit: action.payload.userInfo.limit,
                totalTeacher: action.payload.userInfo.totalResults,
            }

        case APPLY_SORTBY_TEACHER:
            return {
                ...state,
                teachers: action.payload.results,
                totalTeacher: action.payload.totalResults,
                page: action.payload.page,
                limit: action.payload.limit,
                sortBy: action.payload.sortBy,
                orderBy: action.payload.orderBy,
                filter: false
            }

        case APPEND_INSTRUMENT_GENRE:
            return {
                ...state,
                instrumentGenre: state.instrumentGenre.concat(action.payload)
            }

        case GET_INSTRUMENT_BY_TEACHER:
            // console.log("-----------",action.payload);
            return {
                ...state,
                teacherInstrumentGenre: action.payload
            }

        case REMOVE_APPEND_INSTRUMENT_GENRE:
            return {
                ...state,
                instrumentGenre: action.payload
            }

        case FILTERS_BY_TEACHER:
            if (action.payload.length === 0) {
                return {
                    ...state,
                    filterdTeachers: [],
                    filter: true
                }
            } else {
                return {
                    ...state,
                    filterdTeachers: action.payload,
                    filter: true
                }
            }
        case GET_INSTRUMENT_BY_TEACHER_NEW:
            return {
                ...state,
                InstrumentGenreTeacherNew: action.payload
            }

        case DELETE_INSTRUMENT_BY_TEACHER_NEW:
            let temp = state.InstrumentGenreTeacherNew.filter(item => item?.genreId?._id !== action.payload)
            return {
                ...state,
                InstrumentGenreTeacherNew: temp
            }
        default: return state
    }
}