import { SEARCH_TEXT } from '../actionTypes';

const initialState = {
    searchData: []
}

export default function searchBarReducer(state = initialState, action) {
    switch (action.type) {
        case SEARCH_TEXT:
            return {
                ...state,
                searchData: action.payload
            }

        default: return state
    }
}