import supportGraphic from "../../assets/images/support_graphic.png";
import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSidebarMenu, updateSidebarMenu } from "../../redux/sidebar/action";
import { Modal, ModalBody } from "reactstrap";
import SweetAlert from "sweetalert2";
import {
  SUPERADMIN,
  ADMIN,
  SUBADMINMENUITEM,
  TEACHERMENUITEM,
  STUDENTMENUITEM,
  PARENTMENUITEM,
  showStoreAdmin,
} from "./menu";

import {
  practiceTimePlayPause,
  getStudentsPractice,
  totalStudentPracticeTime,
} from "../../redux/studentPractice/action";
import { useHistory } from "react-router-dom";
import moment from "moment";

const getWidth = () => window.innerWidth;

const Sidebar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [width, setWidth] = useState(getWidth());
  const sidebarState = useSelector((state) => state.sidebar);
  console.log("sidebarState", sidebarState);
  const { currentUser, loginAsUser } = useSelector((state) => state.auth);
  const { currentData, loginAsData, loginAsLocalStorageData } = useSelector((state) => state.auth);
  const currentPracticeTime = useSelector(
    (state) => state.studentPracticeState.currentPracticeTime
  );

  const practiceList = useSelector((state) => state.coursesState.practiceList);

  // console.log("practiceList",practiceList);

  const [sidebarActivity, setSidebarActivity] = useState(false);

  const [timerId, setTimerId] = useState(null);
  const [startTime, setStartTime] = useState(localStorage.getItem('starttime') ? localStorage.getItem('starttime') : 0);
  const [elapsedTime, setElapsedTime] = useState(localStorage.getItem('time') === NaN ? 0 : localStorage.getItem('time'));
  const [hours, setHours] = useState("00");
  const [second, setSecond] = useState("00");
  const [minute, setMinute] = useState("00");
  const [isActive, setIsActive] = useState(false);
  const [visibilityState, setVisibilityState] = useState('visible')
  // const [counter, setCounter] = useState(0);

  // console.log("isActive",isActive);

  // console.log("second",second);

  const [stopTimerModal, setStopTimerModal] = useState(false);

  const toggleSidebarActivity = (activity) => {
    if (activity) {
      setSidebarActivity(activity);
      document.querySelector(".sidebar").classList.add("toggled");
      document.querySelector(".close_sidebar").classList.add("toggled");
    } else {
      setSidebarActivity(activity);
      document.querySelector(".sidebar").classList.remove("toggled");
      document.querySelector(".close_sidebar").classList.remove("toggled");
    }
  };

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("timerId"))) {
      setTimerId(JSON.parse(localStorage.getItem("timerId")));
    }
    if (currentUser?.role === "student" && !loginAsUser) {
      dispatch(totalStudentPracticeTime(currentUser?.userId)).then(
        (resTotal) => {
          if (resTotal.status === 200) {
            localStorage.setItem(
              "isActive",
              JSON.stringify(
                resTotal.data.data.isPaused === false ? true : false
              )
            );
            setIsActive(JSON.parse(localStorage.getItem("isActive")));
            localStorage.setItem(
              "timeCounter",
              JSON.stringify(
                !resTotal.data.data.isPaused
                  ? resTotal.data.data.totalSeconds + 1
                  : resTotal.data.data.totalSeconds
              )
            );
            const hoursCounnter = Math.floor(
              JSON.parse(localStorage.getItem("timeCounter") / (60 * 60))
            );
            const secondCounter =
              JSON.parse(localStorage.getItem("timeCounter")) % 60;
            const minuteCounter = Math.floor(
              JSON.parse(localStorage.getItem("timeCounter") / 60)
            );

            let computedHours =
              String(hoursCounnter).length === 1
                ? `0${hoursCounnter}`
                : hoursCounnter;

            let computedSecond =
              String(secondCounter).length === 1
                ? `0${secondCounter}`
                : secondCounter;

            let computedMinute =
              String(minuteCounter).length === 1
                ? `0${minuteCounter}`
                : minuteCounter;

            localStorage.setItem(
              "timerInfo",
              JSON.stringify({
                hrs: computedHours,
                min: computedMinute,
                sec: computedSecond,
              })
            );
            const { hrs, min, sec } = JSON.parse(
              localStorage.getItem("timerInfo")
            );
            setHours(hrs);
            setSecond(sec);
            setMinute(min);
          }
        }
      );
    }
    if ((currentUser?.role === "superadmin" && loginAsUser?.role === "student") ||
      (currentUser?.role === "admin" && loginAsUser?.role === "student") ||
      (currentUser?.role === "subadmin" && loginAsUser?.role === "student") ||
      (currentUser?.role === "teacher" && loginAsUser?.role === "student")
    ) {
      dispatch(totalStudentPracticeTime(loginAsUser?.userId)).then(
        (resTotal) => {
          if (resTotal?.status === 200) {
            console.log("isPaused", resTotal.data.data.isPaused);
            localStorage.setItem(
              "isActive",
              JSON.stringify(
                resTotal.data.data.isPaused === false ? true : false
              )
            );
            setIsActive(JSON.parse(localStorage.getItem("isActive")));
            localStorage.setItem(
              "timeCounter",
              JSON.stringify(
                !resTotal.data.data.isPaused
                  ? resTotal.data.data.totalSeconds + 1
                  : resTotal.data.data.totalSeconds
              )
            );
            const hoursCounnter = Math.floor(
              JSON.parse(localStorage.getItem("timeCounter") / (60 * 60))
            );
            const secondCounter =
              JSON.parse(localStorage.getItem("timeCounter")) % 60;
            const minuteCounter = Math.floor(
              JSON.parse(localStorage.getItem("timeCounter") / 60)
            );

            let computedHours =
              String(hoursCounnter).length === 1
                ? `0${hoursCounnter}`
                : hoursCounnter;

            let computedSecond =
              String(secondCounter).length === 1
                ? `0${secondCounter}`
                : secondCounter;

            let computedMinute =
              String(minuteCounter).length === 1
                ? `0${minuteCounter}`
                : minuteCounter;

            localStorage.setItem(
              "timerInfo",
              JSON.stringify({
                hrs: computedHours,
                min: computedMinute,
                sec: computedSecond,
              })
            );
            const { hrs, min, sec } = JSON.parse(localStorage.getItem("timerInfo"));
            setHours(hrs);
            setSecond(sec);
            setMinute(min);
          }
        }
      );
    }
  }, [dispatch, currentUser, loginAsUser]);

  useEffect(() => {
    // setVisibilityState(document.visibilityState)
    console.log("studentWebsiteIn-------------", true)
    if (JSON.parse(localStorage.getItem("timerId"))) {
      setTimerId(JSON.parse(localStorage.getItem("timerId")));
    }
    if (currentUser?.role === "student" && !loginAsUser) {
      dispatch(totalStudentPracticeTime(currentUser?.userId)).then(
        (resTotal) => {
          if (resTotal.status === 200) {
            localStorage.setItem(
              "isActive",
              JSON.stringify(
                resTotal.data.data.isPaused === false ? true : false
              )
            );
            setIsActive(JSON.parse(localStorage.getItem("isActive")));
            localStorage.setItem(
              "timeCounter",
              JSON.stringify(
                !resTotal.data.data.isPaused
                  ? resTotal.data.data.totalSeconds + 1
                  : resTotal.data.data.totalSeconds
              )
            );
            const hoursCounnter = Math.floor(
              JSON.parse(localStorage.getItem("timeCounter") / (60 * 60))
            );
            const secondCounter =
              JSON.parse(localStorage.getItem("timeCounter")) % 60;
            const minuteCounter = Math.floor(
              JSON.parse(localStorage.getItem("timeCounter") / 60)
            );

            let computedHours =
              String(hoursCounnter).length === 1
                ? `0${hoursCounnter}`
                : hoursCounnter;

            let computedSecond =
              String(secondCounter).length === 1
                ? `0${secondCounter}`
                : secondCounter;

            let computedMinute =
              String(minuteCounter).length === 1
                ? `0${minuteCounter}`
                : minuteCounter;

            localStorage.setItem(
              "timerInfo",
              JSON.stringify({
                hrs: computedHours,
                min: computedMinute,
                sec: computedSecond,
              })
            );
            const { hrs, min, sec } = JSON.parse(
              localStorage.getItem("timerInfo")
            );
            setHours(hrs);
            setSecond(sec);
            setMinute(min);
          }
        }
      );
    }
    if ((currentUser?.role === "superadmin" && loginAsUser?.role === "student") ||
      (currentUser?.role === "admin" && loginAsUser?.role === "student") ||
      (currentUser?.role === "subadmin" && loginAsUser?.role === "student") ||
      (currentUser?.role === "teacher" && loginAsUser?.role === "student")
    ) {
      dispatch(totalStudentPracticeTime(loginAsUser?.userId)).then(
        (resTotal) => {
          if (resTotal?.status === 200) {
            console.log("isPaused", resTotal.data.data.isPaused);
            localStorage.setItem(
              "isActive",
              JSON.stringify(
                resTotal.data.data.isPaused === false ? true : false
              )
            );
            setIsActive(JSON.parse(localStorage.getItem("isActive")));
            localStorage.setItem(
              "timeCounter",
              JSON.stringify(
                !resTotal.data.data.isPaused
                  ? resTotal.data.data.totalSeconds + 1
                  : resTotal.data.data.totalSeconds
              )
            );
            const hoursCounnter = Math.floor(
              JSON.parse(localStorage.getItem("timeCounter") / (60 * 60))
            );
            const secondCounter =
              JSON.parse(localStorage.getItem("timeCounter")) % 60;
            const minuteCounter = Math.floor(
              JSON.parse(localStorage.getItem("timeCounter") / 60)
            );

            let computedHours =
              String(hoursCounnter).length === 1
                ? `0${hoursCounnter}`
                : hoursCounnter;

            let computedSecond =
              String(secondCounter).length === 1
                ? `0${secondCounter}`
                : secondCounter;

            let computedMinute =
              String(minuteCounter).length === 1
                ? `0${minuteCounter}`
                : minuteCounter;

            localStorage.setItem(
              "timerInfo",
              JSON.stringify({
                hrs: computedHours,
                min: computedMinute,
                sec: computedSecond,
              })
            );
            const { hrs, min, sec } = JSON.parse(
              localStorage.getItem("timerInfo")
            );
            setHours(hrs);
            setSecond(sec);
            setMinute(min);
          }
        }
      );
    }
  }, [document.visibilityState, dispatch, currentUser, loginAsUser])


  useEffect(() => {
    const getRoleBaseSidebar = () => {
      if (loginAsUser) {
        const isPrivateTeacher = loginAsUser?.addrole === "Private teacher";
        switch (loginAsUser?.role) {
          case "admin":
            let ADMINMENUITEM = [...ADMIN]
            if(isPrivateTeacher){
              ADMIN[1].Items[0].children.splice(0,2)
              ADMINMENUITEM =[...ADMIN]
            }
            if (loginAsLocalStorageData?.showStore) {
              return [...ADMINMENUITEM, showStoreAdmin]
            }
            return ADMINMENUITEM;

          case "subadmin":
            if (loginAsLocalStorageData?.allow_payroll_report) {
              SUBADMINMENUITEM[7].Items[0].children.unshift({ title: "Approved Payroll", path: "/reporting/aproovedPayrolls", type: 'link' })
              SUBADMINMENUITEM[7].Items[0].children.unshift({ title: "Payroll", path: "/reporting/payrolls", type: 'link' })
            }
            return SUBADMINMENUITEM;

          case "teacher":
            return TEACHERMENUITEM;

          case "student":
            return STUDENTMENUITEM;

          case "parent":
            return PARENTMENUITEM;

          default:
            return [];
        }
      } else {
        switch (currentUser?.role) {
          case "superadmin":
            return SUPERADMIN;

          case "admin":
            if (currentData?.showStore) {
              return [...ADMIN, showStoreAdmin]
            }
            return ADMIN;

          case "subadmin":
          if (currentData?.allow_payroll_report) {
            SUBADMINMENUITEM[7].Items[0].children.unshift({ title: "Approved Payroll", path: "/reporting/aproovedPayrolls", type: 'link' })
            SUBADMINMENUITEM[7].Items[0].children.unshift({ title: "Payroll", path: "/reporting/payrolls", type: 'link' })
          }
            return SUBADMINMENUITEM;

          case "teacher":
            return TEACHERMENUITEM;

          case "student":
            return STUDENTMENUITEM;

          case "parent":
            return PARENTMENUITEM;

          default:
            return [];
        }
      }
    };
    dispatch(getSidebarMenu(getRoleBaseSidebar()));
  }, [dispatch, currentUser, loginAsUser]);

  useEffect(() => {
    let intervalId;
    // setIsActive(JSON.parse(localStorage.getItem('isActive')))

    intervalId = setInterval(() => {
      if (JSON.parse(localStorage.getItem("isActive"))) {
        setIsActive(JSON.parse(localStorage.getItem("isActive")));
        const hoursCounnter = Math.floor(
          localStorage.getItem("timeCounter") / (60 * 60)
        );
        const secondCounter = localStorage.getItem("timeCounter") % 60;
        const minuteCounter = Math.floor(
          localStorage.getItem("timeCounter") / 60
        );

        let computedHours =
          String(hoursCounnter).length === 1
            ? `0${hoursCounnter}`
            : hoursCounnter;

        let computedSecond =
          String(secondCounter).length === 1
            ? `0${secondCounter}`
            : secondCounter;

        let computedMinute =
          String(minuteCounter).length === 1
            ? `0${minuteCounter}`
            : minuteCounter;

        const counters = Number(localStorage.getItem("timeCounter"));
        localStorage.setItem("timeCounter", counters + 1);
        localStorage.setItem(
          "timerInfo",
          JSON.stringify({
            hrs: computedHours,
            min: computedMinute,
            sec: computedSecond,
          })
        );
        const { hrs, min, sec } = JSON.parse(localStorage.getItem("timerInfo"));
        setHours(hrs);
        setSecond(sec);
        setMinute(min);
      } else {
        if (JSON.parse(localStorage.getItem("timerInfo"))) {
          const { hrs, min, sec } = JSON.parse(localStorage.getItem("timerInfo"));
          setHours(hrs);
          setSecond(sec);
          setMinute(min);
        }
        setIsActive(false);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [isActive]);

  useEffect(() => {
    const currentUrl = window.location.pathname;
    sidebarState.mainmenu.map((item) => {
      item.Items.filter((Item) => {
        if (Item.path === currentUrl) setNavItem(Item);
        if (!Item.children) return false;
        Item.children.filter((subItem) => {
          if (subItem.path === currentUrl) {
            setNavItem(subItem);
          }
          if (!subItem.children) return false;
          return subItem;
        });
        return Item;
      });
      return item;
    });
    // eslint-disable-next-line
  }, [dispatch, window.location.pathname]);

  useEffect(() => {
    if (width <= 1280) {
      document.querySelector(".sidebar").classList.add("toggled");
      document.querySelector(".close_sidebar").classList.add("toggled");
    } else {
      document.querySelector(".sidebar").classList.remove("toggled");
      document.querySelector(".close_sidebar").classList.remove("toggled");
    }
    const resizeListener = () => {
      setWidth(getWidth());
    };
    window.addEventListener("resize", resizeListener);
    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, [width]);

  useEffect(() => {
    let interval;
    if (isActive) {
      interval = setInterval(() => {
        const now = moment();
        const diff = moment.duration(now.diff(startTime)).asMilliseconds();
        localStorage.setItem('time', diff)
        setElapsedTime(diff);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isActive, startTime]);

  const secondTime = (milliseconds) => {
    const duration = moment.duration(milliseconds);
    return `${duration.seconds()}`;
  };
  const minuteTime = (milliseconds) => {
    const duration = moment.duration(milliseconds);
    return `${duration.minutes()}`;
  };
  const hourTime = (milliseconds) => {
    const duration = moment.duration(milliseconds);
    return `${duration.hours()}`;
  };

  const onStartAndPauseTimer = (active) => {
    localStorage.setItem("isActive", JSON.stringify(active));
    setIsActive(JSON.parse(localStorage.getItem("isActive")));
    const studentId = loginAsUser ? loginAsUser?.userId : currentUser?.userId;
    const sendPayload = {
      studentId: studentId,
      type: JSON.parse(localStorage.getItem("isActive")) ? "start" : "pause",
    };
    if (timerId)
      Object.assign(sendPayload, {
        timerId: JSON.parse(localStorage.getItem("timerId")),
      });
    dispatch(practiceTimePlayPause(sendPayload)).then((res) => {
      if (res.status === 200) {
        localStorage.setItem("timerId", JSON.stringify(res.data.data._id));
        setTimerId(JSON.parse(localStorage.getItem("timerId")));
        const now = localStorage.getItem('starttime') ? moment().subtract(moment(localStorage.getItem('starttime'))) : moment();
        localStorage.setItem('starttime', now)
        setStartTime(now);
      }
    });
  };

  const stopTimer = () => {
    const studentId = loginAsUser ? loginAsUser?.userId : currentUser?.userId;
    const sendPayload = {
      studentId: studentId,
      type: "stop",
    };
    if (timerId)
      Object.assign(sendPayload, {
        timerId: JSON.parse(localStorage.getItem("timerId")),
      });
    dispatch(practiceTimePlayPause(sendPayload)).then((res) => {
      if (res.status === 200) {
        localStorage.removeItem("timerId");
        localStorage.removeItem("starttime");
        localStorage.removeItem("time");
        setIsActive(false);
        setElapsedTime(0);
        setStopTimerModal(false);
        // setCounter(0);
        setHours("00");
        setSecond("00");
        setMinute("00");
        SweetAlert.fire({
          text: `Congratulations, you practiced ${hourTime(elapsedTime)}:${minuteTime(elapsedTime)}:${secondTime(elapsedTime)} for today`,
          icon: "success",
        });
        localStorage.setItem("isActive", JSON.stringify(false));
        localStorage.setItem(
          "timerInfo",
          JSON.stringify({ hrs: "00", min: "00", sec: "00" })
        );
        localStorage.setItem("timeCounter", 0);
        localStorage.setItem("timerId", null);
      }
    });
  };

  const setNavItem = (item) => {
    if (item.type === 'link' && item.tag) {
      RemoveHumberger()
    }
    sidebarState.mainmenu.map((menuitem) => {
      menuitem.Items.filter((Item) => {
        if (Item !== item || item.active) {
          Item.active = false;
        }
        if (Item.children && Item.children.includes(item)) {
          Item.active = true;
        }
        if (Item.children) {
          Item.children.filter((submenuItem) => {
            submenuItem.active = false;
            return submenuItem;
          });
        }
        return Item;
      });
      return menuitem;
    });
    item.active = !item.active;
    dispatch(updateSidebarMenu([...sidebarState.mainmenu]));
  };

  const toggleNavItem = (item) => {
    if (!item.active) {
      sidebarState.mainmenu.map((menuitem) => {
        menuitem.Items.filter((Item) => {
          if (menuitem.Items.includes(item)) {
            Item.active = false;
          }
          if (!Item.children) return false;
          Item.children.forEach((subItem) => {
            if (Item.children.includes(item)) {
              subItem.active = false;
            }
            if (sidebarActivity) {
              subItem.active = false;
            }
            return subItem;
          });
          return Item;
        });
        return menuitem;
      });
      RemoveHumberger()
    }
    item.active = !item.active;
    dispatch(updateSidebarMenu([...sidebarState.mainmenu]));
  };

  const RemoveHumberger = () => {
    document.querySelector(".sidebar")?.classList?.remove("show");
    document.querySelector(".humberger_toggle")?.classList?.remove("active");
  };


  return (
    <Fragment>
      <div className="sidebar show1">
        <div className="sidebar_brand d-flex align-items-center justify-content-center">
          <a className="logo" href={((currentUser?.role === "superadmin" && loginAsUser?.role === "parent") ||
            (currentUser?.role === "admin" && loginAsUser?.role === "parent") ||
            (currentUser?.role === "subadmin" && loginAsUser?.role === "parent") ||
            (currentUser?.role === "parent" && !loginAsUser) ? '/member/children' : "/dashboard"
          )}>
            <img
              src={require("../../assets/images/ampliTeach.svg").default}
              alt="AmpliTech"
              title="AmpliTech"
            />
          </a>
        </div>
        {(currentUser?.role === "student" ||
          loginAsUser?.role === "student") && (
            <div className="practiceTimer fullWidth mt-5 mb-4">
              <label className="fullWidth practiceTimer_label">
                <img
                  src={require("../../assets/images/icon_clock.svg").default}
                  alt=""
                  title=""
                  className="mr-3"
                />{" "}
                Practice Timer
              </label>
              <div className="fullWidth practiceTimer_section mt-md-3 mt-0 pr-2">
                <span
                  className="practiceTimer_play"
                  onClick={() => onStartAndPauseTimer(!isActive)}
                >
                  <img
                    src={
                      require(`../../assets/images/${isActive ? "icon_timer_pause.svg" : "icon_timer_play.svg"
                        }`).default
                    }
                    alt=""
                    title=""
                  />
                </span>
                <span className="practiceTimer_counter">
                  {hourTime(elapsedTime)}h {minuteTime(elapsedTime)}m {secondTime(elapsedTime)}s
                </span>
                <span
                  className="practiceTimer_stop"
                  onClick={() => setStopTimerModal(true)}
                >
                  <img
                    src={
                      require("../../assets/images/icon_timer_stop.svg").default
                    }
                    alt=""
                    title=""
                  />
                </span>
              </div>
            </div>
          )}
        <ul className="navbar-nav sidebar_nav fullWidth">
          {sidebarState.mainmenu.map((item) =>
            item.Items.map((menuitem, i) => {
              return (
                <li
                  className={`nav-item ${menuitem.active ||
                    menuitem.children?.find(f_item => f_item.path === window.location.pathname)
                    ? "sub_nav"
                    : ""
                    }`}
                  key={i}
                >
                  {menuitem.type === "sub" ? (
                    <Link
                      className={`nav-link ${menuitem.tag} ${menuitem.active ||
                        menuitem.path === window.location.pathname ||
                        menuitem.children?.find(f_item => f_item.path === window.location.pathname)
                        ? "active "
                        : ""
                        } `}
                      to={menuitem.path}
                      onClick={() => setNavItem(menuitem)}
                    >
                      <span>{menuitem.title}</span>
                    </Link>
                  ) : (
                    ""
                  )}
                  {menuitem.type === "link" ? (
                    <Link
                      className={`nav-link ${menuitem.tag} ${menuitem.active ||
                        menuitem.path === window.location.pathname
                        ? "active "
                        : ""
                        } `}
                      to={menuitem.path}
                      onClick={() => setNavItem(menuitem)}
                    >
                      <span>{menuitem.title}</span>
                    </Link>
                  ) : (
                    ""
                  )}
                  {menuitem.children ? (
                    <ul
                      className={`fullWidth nav ${menuitem.active ||
                        menuitem.children?.find(f_item => f_item.path === window.location.pathname)
                        ? "d-block"
                        : "d-none"
                        } `}
                    >
                      {menuitem.children.map((subitem, i) => {
                        return (
                          <li className="nav-item" key={i}>
                            <Link
                              className={`nav-link ${subitem.active ||
                                subitem.path === window.location.pathname
                                ? "active"
                                : ""
                                }`}
                              to={subitem.path}
                              onClick={() => toggleNavItem(subitem)}
                            >
                              <span>{subitem.title}</span>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    ""
                  )}
                </li>
              );
            })
          )}
        </ul>
        {((currentUser?.role === "superadmin" && loginAsUser?.role === "teacher") ||
          (currentUser?.role === "admin" && loginAsUser?.role === "teacher") ||
          (currentUser?.role === "subadmin" && loginAsUser?.role === "teacher") ||
          (currentUser?.role === "teacher" && !loginAsUser) ||
          (currentUser?.role === "superadmin" && loginAsUser?.role === "student") ||
          (currentUser?.role === "subadmin" && loginAsUser?.role === "student") ||
          (currentUser?.role === "admin" && loginAsUser?.role === "student") ||
          (currentUser?.role === "teacher" && loginAsUser?.role === "student") ||
          (currentUser?.role === "parent" && loginAsUser?.role === "student") ||
          (currentUser?.role === "student" && !loginAsUser)) ? "" :
          <div className="more_features text-center position-relative mt-auto d-lg-inline-block d-none">
            <img
              src={supportGraphic}
              alt=""
              title=""
              className="d-inline-block"
              width="206"
              height="157"
            />
            {/* <p>
              Contact for <a href="#more">more</a> features.
            </p> */}

            <span className="support">
              <Link to={'/support'} className="d-flex justify-content-center align-items-center mx-auto" >
                <img
                  src={require("../../assets/images/support_icon.svg").default}
                  alt="Support"
                  title="Support"
                  className="float-left"
                />{" "}
                Contact for More Features
              </Link>
            </span>
          </div>
        }

        <div
          className="sidebar_toggle"
          onClick={() => toggleSidebarActivity(!sidebarActivity)}
        >
          {/* <a href="#javascript" className="d-flex justify-content-center align-items-center border-circle text-white close_sidebar"> <i className="fas fa-angle-left"></i> </a> */}
          <a
            href="#javascript"
            className="d-flex justify-content-center align-items-center border-circle text-white close_sidebar"
          >
            <img
              src={
                require("../../assets/images/arrow_right_white.svg").default
              }
              alt=""
            />
          </a>
        </div>
        <div className="submenu_hide_icon d-lg-none d-flex">
          <span id="submenu_hide_icon" onClick={() => RemoveHumberger()}>
            <img
              src={require("../../assets/images/icon_cross_white.svg").default}
              alt="Close"
              title="Close"
              height="20"
              width="20"
            />
          </span>
        </div>
      </div>

      <Modal
        className="fade show d-block"
        isOpen={stopTimerModal}
        toggle={() => setStopTimerModal(false)}
        centered
      >
        <div className="modal-header pb-2 px-md-5 px-4">
          <h2 className="mb-0">Confirmation</h2>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => setStopTimerModal(false)}
          >
            <img
              src={require("../../assets/images/modal_cross_icon.svg").default}
              alt="Close"
              title="Close"
              height="15"
              width="15"
            />
          </button>
        </div>

        <ModalBody className="px-md-5 px-4 mt-4">
          <p className="mb-4">
            Are you sure that you want to stop Practice timer?
          </p>
          <div className="fullWidth mt-5">
            <button
              type="button"
              className="btn_primary float-left mr-5 text-capitalize"
              onClick={stopTimer}
            >
              yes
            </button>
            <button
              type="button"
              className="btn_default text-capitalize"
              onClick={() => setStopTimerModal(false)}
            >
              no
            </button>
          </div>
        </ModalBody>
      </Modal>
    </Fragment >
  );
};

export default Sidebar;
