import {
  GET_PAYROLL_REPORT,
  GET_STUDENT_PAYROLL_REPORT,
  DELETE_PAYROLL_REPORT,
  STUDENT_REPORT_BY_ADMIN,
  STUDENT_REPORT_BY_ADMIN_FORM_DATA,
  PAYMENT_REPORT_ALL,
  SET_PAYMNET_REMINDER,
  PAYMNET_NOTE_REPORT,
  GET_GENERATE_CSV,
  UPDATE_PAYMENT_DETAILS,
  BILLING_PAYMENT_REPORT,
  STUDENT_PAYMENT_METHOD,
  REMOVE_EDITED_PLAN_DATA,
  APROOVE_PAYROLL_REPORT,
  GET_APROOVE_PAYROLL,
  WIDGET_STUDENT,
} from "../actionTypes";

const initialState = {
  payrollReportData: [],
  payrollStudentData: [],
  updatePaymentDetails: {},
  bilingPaymentReport: [],
  studentPaymentReport: [],
  // studenr report
  studenReportData: [],
  studentFormData: [],
  paymnetReport: [],
  csvReport: null,
  // pagination
  page: 1,
  limit: 10,
  totalPages: 0,
  totalResults: 0,
  pagePayroll: 1,
  limitPayroll: 10,
  totalPagesPayroll: 0,
  totalResultsPayroll: 0,
  approvePayroll:{},
  viewPayroll: {},
  widgetStudents: []
};

export default function reportingReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PAYROLL_REPORT:
      return {
        ...state,
        payrollReportData: action.payload,
        pagePayroll: action.payload?.page,
        totalPagesPayroll: action.payload?.totalPages,
        totalResultsPayroll: action.payload?.totalResults,
        limitPayroll: action.payload?.limit,
      };
    case GET_APROOVE_PAYROLL: 
    return {
      ...state,
      viewPayroll: action.payload
    };
    case GET_STUDENT_PAYROLL_REPORT:
      return {
        ...state,
        payrollStudentData: action.payload,
        page: action.payload?.page,
        totalPages: action.payload?.totalPages,
        totalResults: action.payload?.totalResults,
        limit: action.payload?.limit,
      };
    case DELETE_PAYROLL_REPORT:
      let data = state.payrollReportData.results.filter(
        (item) => item._id !== action.payload
      );
      return {
        ...state,
        payrollReportData: { ...state.payrollReportData, results: [...data] },
      };

    case STUDENT_REPORT_BY_ADMIN:
      return {
        ...state,
        studenReportData: action.payload.results,
        page: action.payload?.page,
        totalPages: action.payload?.totalPages,
        totalResults: action.payload?.totalResults,
        limit: action.payload?.limit,
      };
    case STUDENT_REPORT_BY_ADMIN_FORM_DATA:
      return {
        ...state,
        studentFormData: action.payload,
      };

    case GET_GENERATE_CSV:
      console.log("csv reducer", action.payload);
      return {
        ...state,
        csvReport: action.payload,
      };

    case PAYMENT_REPORT_ALL:
      return {
        paymnetReport: action.payload,
        page: action.payload?.page,
        totalPages: action.payload?.totalPages,
        totalResults: action.payload?.totalResults,
        limit: action.payload?.limit,
      };
    case UPDATE_PAYMENT_DETAILS:
      return {
        updatePaymentDetails: action.payload,
      };
    case BILLING_PAYMENT_REPORT:
      return {
        bilingPaymentReport: action.payload,
      };
    case STUDENT_PAYMENT_METHOD:
      return {
        studentPaymentReport: action.payload,
      };
    case REMOVE_EDITED_PLAN_DATA:
      return {
        bilingPaymentReport: action.payload,
      };
    case WIDGET_STUDENT:
      return {
        ...state,
        widgetStudents: action.payload
      }
    default:
      return state;
  }
}
