import ampliTeachWhite from "../assets/images/AmpliTeach_white.png";
import React, { useState, useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { userLogin, forgetUserPassword } from "../redux/auth/action";
import SweetAlert from "sweetalert2";
// import success_true_tick from "./../assets/images/success_true_tick.svg"

const SignIn = ({
  history,
  currentUser,
  loginAsUser,
  userLogin,
  forgetUserPassword,
}) => {
  const { register, handleSubmit, errors, setError } = useForm();
  const [modal, setModal] = useState(true);
  const [section, setSection] = useState("login");

  useEffect(() => {
    if (
      currentUser &&
      (currentUser?.role === "parent" || loginAsUser?.role === "parent")
    ) {
      history.push("/member/children");
    } else {
      if (currentUser) {
        history.push("/dashboard");
      }
    }
  }, [currentUser, loginAsUser, history]);

  const loginAdmin = (data) => {
    userLogin(data).then((res) => {
      if (res?.status === 200) {
        if (res.data.data.role === "parent") {
          history.push("/member/children");
        } else {
          history.push("/dashboard");
        }
      } else {
        if (res?.data?.message === "User Not Found") {
          setError("username", {
            type: "manual",
            message: res.data.message,
          });
        } else {
          setError("password", {
            type: "manual",
            message: res.data.message,
          });
        }
      }
    });
  };

  const onforgetPassWordClick = (username) => {
    forgetUserPassword(username).then((res) => {
      if (res.status === 200) {
        SweetAlert.fire({
          text: "Reset password link has been sent to your mail successfully please check the mail.",
          icon: "success",
        });
      }
    });
  };

  const nameValidate = (value) => {
    const specialChar = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    if (/^\s/.test(value)) {
      return "as first character whiteSpace not allowed"
    }
    return true
  }


  // const checkEmailAndPassword = (type,value) => {
  //     if(!props.isEdit){
  //         serverSideValidation(type,value).then(result => {
  //             return result
  //         }).catch(errors => {
  //             const formError = { type: "manual", message: errors.response.data.message }
  //             setError(type,formError);
  //         })
  //     }
  // }

  return (
    <div className="fullWidth">
      <style>
        {
          "\
                    // .modal.show{pointer-events:none;background-color:transparent;}\
                    .modal-backdrop, .modal-content{background-color:transparent;}\
                    .login_details{background: #fff; border-radius: 1rem;}\
                "
        }
      </style>
      <div className="login_page"></div>
      <Modal
        className="login_modal fade show d-block"
        isOpen={modal}
        // toggle={() => setModal(false)}
        centered
      >
        <div className="fullWidth text-center mb-sm-5 mb-3">
          <img
            src={ampliTeachWhite}
            alt="AmpliTech"
            title="AmpliTech"
            height="60"
          />
        </div>
        <h1 className="text-center text-white mb-sm-5 mb-3 mt-sm-0 mt-4">
          Welcome to AmpliTeach
        </h1>
        <ModalBody className="p-0 mx-auto mb-0 mt-4 login_details">
          {section === "login" ? (
            <>
              <div className="login_part">
                <div className="login_header">
                  <div className="login_header_logo">
                    <img
                      src={require("../assets/images/ampliTeach.svg").default}
                      alt="AmpliTech"
                      title="AmpliTech"
                      height="60"
                      className="mb-4"
                    />
                  </div>
                  <h2 className="mb-4">Log in to your account</h2>
                </div>
                <form className="mb-4" onSubmit={handleSubmit(loginAdmin)}>
                  <div className="form-group">
                    <label>Username</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="username"
                      name="username"
                      ref={register({ required: "Username is Required", validate: nameValidate })}
                    />
                    {errors.username && (
                      <div className="invalid-feedback pl-4 d-block">
                        {errors.username.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group password_form_group">
                    <label>Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      name="password"
                      ref={register({ required: "Password is Required" })}
                    />
                    {errors.password && (
                      <div className="invalid-feedback pl-4 d-block">
                        {errors.password.message}
                      </div>
                    )}
                  </div>

                  <div className="form-group mt-4">
                    <div className="form-check mb-4">
                      <label className="form-check-label custom-control custom-checkbox">
                        <input
                          className="custom-control-input"
                          type="checkbox"
                          name="allow_plan0"
                          id="allow_plan0"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="allow_plan0"
                        >
                          Remember Me
                        </label>
                      </label>
                    </div>
                  </div>

                  <button type="submit" className="btn_primary fullWidth">
                    Log in
                  </button>
                </form>

                <p className="forgot_password">
                  <a
                    href="#javascript"
                    className="float-right"
                    onClick={() => setSection("reset")}
                  >
                    Forgot password?
                  </a>
                </p>
              </div>
              <div className="card marketing">
                <h2 class="mb-4">Learn More</h2>
                <p className="">AmpliTeach is the best Music Studio
                  Platform, nothing else even comes close! Watch your business grow while
                  you do less work. Everything you need to grow and take control.</p>
                <ul className="list_unstyled">
                  <li>Manage your Schedule, Students and Billing</li>
                  <li>Keep your Students Engaged and Learning</li>
                  <li>Tools to Grow as big as you can Dream!</li>
                </ul>
                <a href="http://about.ampliteach.com/"> <button type="submit" className="btn_primary fullWidth">Click here</button></a>
              </div>
            </>
          ) : (
            <div className="login_part">
              <div className="login_header mb-5">
                <div className="login_header_logo">
                  <img
                    src={require("../assets/images/ampliTeach.svg").default}
                    alt="AmpliTech"
                    title="AmpliTech"
                    height="60"
                    className="mb-4"
                  />
                </div>
                <h2>Forgot Password!</h2>
                <p className="mt-4 text-left">
                  Just enter your username, and we will send you a link to reset
                  your password.
                </p>
              </div>
              <form
                className="mb-4"
                onSubmit={handleSubmit(onforgetPassWordClick)}
              >
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Username"
                    name="username"
                    ref={register()}
                  />
                </div>
                <button type="submit" className="btn_primary fullWidth">
                  Continue
                </button>
              </form>
              <p>
                <a href="javascript:void()" onClick={() => setSection("login")}>
                  Back to sign in
                </a>
              </p>
            </div>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.currentUser,
    loginAsUser: state.auth.loginAsUser,
  };
};

export default connect(mapStateToProps, { userLogin, forgetUserPassword })(
  SignIn
);
