import React from 'react'

const Loader = () => {

    // const [show, setShow] = useState(true)
    // useEffect(() => {
    //     const timeout = setTimeout(() => {
    //         setShow(false)
    //     }, 1000);
    //     return () => {
    //         clearTimeout(timeout)
    //     }
    // }, [show])
    
    return (
        <div className="preloader" >
            <div className="line-scale-party">
                <div></div><div></div>
                <div></div><div></div>
                <div></div><div></div>
                <div></div><div></div>
            </div>
        </div>
    )
}
export default Loader
