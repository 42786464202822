import {
    GET_ALL_STORE_ITEM,
    GET_STORE_ID,
    EDIT_STORE_ITEM,
    ADD_STORE_ITEM_INTO_CART,
    REMOVE_STORE_ITEM_FROM_CART,
    INCREMENT_STORE_QUENTITY,
    DECREMENT_STORE_QUENTITY,
    GET_DEFAUKT_QUANTITY,
    UPDATE_STORE_QUANTITY,
    VIEW_STORE_ITEM_FROM_CART,
    GET_ORDER_LIST,
    VIEW_SINGLE_STORE_PRODUCT,
    SEARCH_STORE_ITEM,
    DOWNLOAD_INVOICE,
    DOWNLOAD_VENDOR_INVOICE,
    DELIVERY_ADDRESS_DETAILS,
    ORDER_DETAILS,
    VENDOR_ORDER_DETAILS,
    ADD_SELLER_INFORMATION,
    GET_SELLER_INFORMATION,
    UPDATE_SELLER_INFORMATION,
    DELETE_SELLER_INFORMATION,
    GET_STORE_ITEM_LIST,
    ADD_STORE_ITEM_IN_LIST,
    GROUP_EMAIL_RES
} from '../../redux/actionTypes';
import { authHeader } from '../../services/authHeader';
import SweetAlert from "sweetalert2";
import { toast } from "react-toastify";

export const getAllStoreItem = (item_name, page, limit, select_category) => async (dispatch, getState, api) => {
    const result = await api.post(`/store/getStoreList`, { item_name, page, limit, select_category }, { headers: authHeader() }).then(res => {
        if (res.status === 200) {
            dispatch({
                type: GET_ALL_STORE_ITEM,
                payload: res.data.data
            })
        }
        return res
    })
    return result
}

export const addNewStore = (payload, storeItemImageFiles, taxApplicableValue, item_name, currentPage, limit) => async (dispatch, getState, api) => {
    const data = {
        ...payload,
        item_image: storeItemImageFiles,
        tax_applicable: taxApplicableValue,
        item_code: payload.item_code ? payload.item_code : null,
        item_price: payload.item_price ? payload.item_price : null,
        quantity: payload.quantity ? payload.quantity : null,
        shipping_cost: payload.shipping_cost ? payload.shipping_cost : null
    }
    const results = await api.post(`/store/addstore`, data, { headers: authHeader() }).then(res => {
        if (res.status === 200) {
            dispatch(getAllStoreItem(item_name, currentPage, limit))
        }
        return res
    }).catch(err => {
        return err.response
    })
    return results
}

export const updateStore = (item, id, storeItemImageFiles, taxApplicableValue, item_name, currentPage, limit) => async (dispatch, getState, api) => {
    const data = {
        ...item,
        userId: id,
        item_image: storeItemImageFiles,
        tax_applicable: taxApplicableValue,
        item_code: item.item_code ? item.item_code : null,
        item_price: item.item_price ? item.item_price : null,
        quantity: item.quantity ? item.quantity : null,
        shipping_cost: item.shipping_cost ? item.shipping_cost : null
    }
    const results = await api.post(`/store/updateStore`, data, { headers: authHeader() }).then(res => {
        if (res.status === 200) {
            dispatch(getAllStoreItem(item_name, currentPage, limit))
        }
        return res
    })
    return results
}

export const deleteStore = (id, item_name, currentPage, limit) => async (dispatch, getState, api) => {
    const data = { id }
    const results = await api.delete(`/store/removeStore`, { data, headers: authHeader() }).then(results => {
        if (results.status === 200) {
            dispatch(getAllStoreItem(item_name, currentPage, limit))
        }
        return results
    })
    return results
}

export const storeImage = (payload) => async (dispatch, getState, api) => {
    let formData = new FormData();
    for (let i = 0; i < payload.length; i++) {
        formData.append('uploadedImages', payload[i])
    }
    const results = await api.post(`/library/multiplefileUpload`, formData, { headers: authHeader() }).then(res => {
        return res
    })
    return results
}


export const editStoreItem = (item, isEdit) => dispatch => {
    dispatch({
        type: EDIT_STORE_ITEM,
        payload: { item, isEdit }
    })
}


export const getStoreId = (id) => dispatch => {
    dispatch({
        type: GET_STORE_ID,
        id
    })
}

export const addStoreItemIntoCart = (store_item) => async (dispatch, getState, api) => {
    const results = await api.post(`/cart/addtoCart`, store_item, { headers: authHeader() }).then(res => {
        return res
    })
    return results
}

export const viewStoreCart = (userId) => async (dispatch, getState, api) => {
    const results = await api.post(`/cart/viewCart`, { userId }, { headers: authHeader() }).then(res => {
        if (res.status === 200) {
            dispatch({
                type: VIEW_STORE_ITEM_FROM_CART,
                cart_item: res.data.data,
                isCart: true
            })
        } else {
            dispatch({
                type: VIEW_STORE_ITEM_FROM_CART,
                isCart: false
                // cart_item:null
            })
        }

        return res
    }).catch(err => {
        if (err) {
            dispatch({
                type: VIEW_STORE_ITEM_FROM_CART,
                cart_item: null,
                isCart: false
                // cart_item:null
            })
        }
    })
    return results
}

export const confirmOrder = (userId, addressId, customerProfileId, customerPaymentProfileId) => async (dispatch, getState, api) => {
    const results = await api.post(`/order/addtoOrder`, { userId, addressId, customerProfileId, customerPaymentProfileId }, { headers: authHeader() }).then(res => {
        console.log("xcvvvvvvvvvvvvvvvvvvvv", res);
        if (res.status === 400) {
            SweetAlert.fire({
                text: "Plan Viewed Successfully.",
                icon: "error",
            });
        }
        return res
    })
    return results
}

export const removeStoreItemFromCart = (userId, storeId) => async (dispatch, getState, api) => {
    const results = await api.post(`/cart/removeCart`, { userId, storeId }, { headers: authHeader() }).then(res => {
        if (res.status === 200) {
            dispatch(viewStoreCart(userId))
            // dispatch({
            //     type: VIEW_STORE_ITEM_FROM_CART,
            //     cart_item:res.data.data,
            //     isCart:true
            // })
        }
        return res
    })
    return results
}


export const viewSingleStoreItem = (storeId) => async (dispatch, getState, api) => {
    const results = await api.get(`/store/viewsingleProduct/${storeId}`, { headers: authHeader() }).then((res) => {
        if (res.status === 200) {
            dispatch({
                type: VIEW_SINGLE_STORE_PRODUCT,
                payload: res.data.data

            })
        }
        return res
    })
    return results
}

export const incrementStoreQuentity = (id) => dispatch => {
    dispatch({
        type: INCREMENT_STORE_QUENTITY,
        id
    })
}

export const decrementStoreQuentity = (id) => dispatch => {
    dispatch({
        type: DECREMENT_STORE_QUENTITY,
        id
    })
}

export const getDefaultStoreQuentity = (store_quantity) => dispatch => {
    dispatch({
        type: GET_DEFAUKT_QUANTITY,
        store_quantity
    })
}

export const updateStoreQuentity = (quantity_number, id) => dispatch => {
    dispatch({
        type: UPDATE_STORE_QUANTITY,
        quantity_number, id
    })
}


// export const AddNewStore = () => async (dispatch, getState, api) => {
//     return api.get(`/store/getStoreList`, { headers: authHeader() })
// }

// export const editStoreItem = (item) => dispatch => {
//     dispatch({
//         type: EDIT_STORE_ITEM,
//         payload: {item}
//     })
// }

// export const deleteStoreID = (store_id) => dispatch => {
//     dispatch({
//         type: DELETE_STORE_ID,
//         payload: store_id
//     })
// }

export const getOrderList = (userId, page, limit, startdate, enddate) => async (dispatch, getState, api) => {
    const results = await api.post(`/order/getorderList`, { userId, page, limit, startdate, enddate }, { headers: authHeader() }).then((res) => {
        dispatch({
            type: GET_ORDER_LIST,
            payload: res.data.data
        })
        return res
    }).catch((err) => {
        return err.response
    })
    return results
}

export const getOrderStatus = (data) => async (dispatch, getState, api) => {
    const result = await api.post(`/order/orderList`, data, { headers: authHeader() }).then(res => {
        return res
    })
    return result
}


export const updateOrderStatus = (orderId, status) => async (dispatch, getState, api) => {
    const result = await api.post(`/order/updateStatus`, { orderId, status }, { headers: authHeader() }).then(res => {
        return res
    })
    return result
}

export const searchStore = (item_name) => async (dispatch, getState, api) => {
    const result = await api.post(`/store/search`, { item_name }, { headers: authHeader() }).then(res => {
        if (res.status === 200) {
            dispatch({
                type: SEARCH_STORE_ITEM,
                payload: res.data.data
            })
        }
        return res
    })
    return result
}

export const downloadInvoice = (orderId) => async (dispatch, getState, api) => {
    const results = await api.get(`/order/getSingleUserOrder?id=${orderId}&type=1`, { headers: authHeader() }).then((res) => {
        // console.log("invoice  res",res);
        if (res.status === 200) {
            dispatch({
                type: DOWNLOAD_INVOICE,
                payload: res.data.data

            })
        }
        return res
    })
    return results
}


export const downloadVendorInvoice = (orderId) => async (dispatch, getState, api) => {
    const results = await api.get(`/order/getSingleUserOrder?id=${orderId}&type=2`, { headers: authHeader() }).then((res) => {
        // console.log("invoice  res",res);
        if (res.status === 200) {
            dispatch({
                type: DOWNLOAD_VENDOR_INVOICE,
                payload: res.data.data

            })
        }
        return res
    })
    return results
}

export const viewOrderDetails = (orderId) => async (dispatch, getState, api) => {
    const results = await api.get(`/order/getSingleUserOrder?id=${orderId}&type=1`, { headers: authHeader() }).then((res) => {
        if (res.status === 200) {
            dispatch({
                type: ORDER_DETAILS,
                payload: res.data.data

            })
        }
        return res
    })
    return results
}

export const viewVendorOrderDetails = (orderId) => async (dispatch, getState, api) => {
    const results = await api.get(`/order/getSingleUserOrder?id=${orderId}&type=2`, { headers: authHeader() }).then((res) => {
        if (res.status === 200) {
            dispatch({
                type: VENDOR_ORDER_DETAILS,
                payload: res.data.data

            })
        }
        return res
    })
    return results
}

export const confirmDeliveryAddress = (data) => async (dispatch, getState, api) => {
    const result = await api.post("/order/addUserOrderAddress", data, { headers: authHeader() }).then(res => {
        return res
    })
    return result
}


export const viewDeliveryAddressDetails = (userId) => async (dispatch, getState, api) => {
    const result = await api.post("/order/viewAddress", { userId }, { headers: authHeader() }).then(res => {
        if (res.status === 200) {
            dispatch({
                type: DELIVERY_ADDRESS_DETAILS,
                payload: res.data.data
            })
        }
        return res
    })
    return result
}


export const storeImageUpload = (payload) => async (dispatch, getState, api) => {
    let formData = new FormData();
    for (let i = 0; i < payload.length; i++) {
        formData.append('fileUpload', payload[i])
    }
    const results = await api
        .post(`fileUpload/upload`, formData, { headers: authHeader() })
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
    return results;
};

export const addSellerInformation = (data) => async (dispatch, getState, api) => {
    const result = await api.post("/seller/add", data, { headers: authHeader() }).then(res => {
        if (res.status === 200) {
            dispatch({
                type: ADD_SELLER_INFORMATION,
                payload: res.data.data
            })
        }
        return res
    })
    return result
}

export const getSellerInformation = (data) => async (dispatch, getState, api) => {
    const result = await api.get(data?.isDropDown ? `/seller/get?userId=${data.userId}&isDropDown=true` : `/seller/get?page=${data.page}&limit=${data.limit}&userId=${data.userId}`, { headers: authHeader() }).then(res => {
        if (res.status === 200) {
            dispatch({
                type: GET_SELLER_INFORMATION,
                payload: res.data.data
            })
        }
        return res
    })
    return result
}

export const updateSellerInformation = (id, data) => async (dispatch, getState, api) => {
    const result = await api.post(`/seller/update/${id}`, data, { headers: authHeader() }).then(res => {
        if (res.status === 200) {
            dispatch({
                type: UPDATE_SELLER_INFORMATION,
                payload: res.data.data
            })
        }
        return res
    })
    return result
}

export const deleteSeller = (id) => async (dispatch, getState, api) => {
    const data = { id };
    const results = await api
        .delete(`/seller/delete/${id}`, {
            headers: authHeader(),
        })
        .then((res) => {
            if (res.status === 200) {
                dispatch({
                    type: DELETE_SELLER_INFORMATION,
                    payload: res.data.data,
                });
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });
    return results;
};


export const getStoreItemList = (data) => async (dispatch, getState, api) => {
    const result = await api.post("/schoolStore/getStoreList", data, { headers: authHeader() }).then(res => {
        if (res.status === 200) {
            dispatch({
                type: GET_STORE_ITEM_LIST,
                payload: res.data.data
            })
        }
        return res
    })
    return result
}

export const addStoreItemInList = (data) => async (dispatch, getState, api) => {
    const result = await api.post("/schoolStore/buyStoreItem", data, { headers: authHeader() }).then(res => {
        if (res.status === 200) {
            dispatch({
                type: ADD_STORE_ITEM_IN_LIST,
                payload: res.data.data
            })
        }
        return res
    })
    return result
}

// Group email

export const groupEmail = (data) => async (dispatch, getState, api) => {
    const result = await api.post("/massEmail/sendMassEmail", data, { headers: authHeader() }).then(res => {
        if (res.status === 200) {
            toast.success("Mail Sent successfully", {
                position: toast.POSITION.TOP_RIGHT,
            });
            dispatch({
                type: GROUP_EMAIL_RES,
                payload: res.data
            })
        }
        return res
    })
    return result
}