import {
  GET_FAVOURITE_SONG_LIST,
  ADD_FAVOURITE_SONG,
  UPDATE_FAVOURITE_SONG,
  DELETE_FAVOURITE_SONG,
} from "../actionTypes";

const initialState = {
  addedFavouriteSong: [],
  favouriteSongList: [],
  updatedFavouriteSong: [],
  page: 1,
  limit: 20,
  totalSongs: null,
  totalPages: null,
};

export default function favouriteListReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_FAVOURITE_SONG:
      return {
        ...state,
        addedFavouriteSong: action.payload,
      };
    case GET_FAVOURITE_SONG_LIST:
      return {
        ...state,
        favouriteSongList: action.payload.results,
        page: action.payload.page,
        limit: action.payload.limit,
        totalSongs: action.payload.totalResults,
        totalPages: action.payload.totalPages,
      };
    case UPDATE_FAVOURITE_SONG:
      return {
        ...state,
        updatedFavouriteSong: action.payload,
      };

    case DELETE_FAVOURITE_SONG:
      return {
        ...state,
      };

    default:
      return state;
  }
}
