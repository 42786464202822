import { APPLY_FILTER_FOR_PRACTICE, CURRENT_PRACTICE_TIME, GET_ALL_PRACTICE, GET_COMPLETE_INCOMPLETE_PRACTICE } from "../../redux/actionTypes";
import { authHeader } from "../../services/authHeader";
import { Loading } from "../innerLoader/action";

// export const getAllPlanBuilder = () => async (dispatch, getState, api) => {
//   const results = await api
//     .get(`/studentBilling/getListPlanBuilder`, {
//       headers: authHeader(),
//     })
//     .then((res) => {
//       if (res.status === 200) {
//         dispatch({
//           type: GET_PLAN_BUILDER,
//           payload: res.data.data.reverse(),
//         });
//       }
//       return res;
//     });
//   return results;
// };

export const getAllPracticeByTeacher = (data) => async (dispatch, getState, api) => {
  const results = await api
    .post(`/practice/getPractice`, data, {
      headers: authHeader(),
    })
    .then((res) => {
      if (res.status === 200) {
          dispatch({
              type: GET_ALL_PRACTICE,
              payload: res.data.data,
          });
          }
      return res;
    })
    .catch((err) => {
      return err.response;
    });
  return results;
};

export const getAllPracticeByStudent = (data) => async (dispatch, getState, api) => {
  const results = await api
    .post(`/practice/getPracticeStudents`, data, {
      headers: authHeader(),
    })
    .then((res) => {
      if (res.status === 200) {
          dispatch({
              type: GET_ALL_PRACTICE,
              payload: res.data.data,
          });
          }
      return res;
    })
    .catch((err) => {
      return err.response;
    });
  return results;
};

export const sortPracticeByTeacher = (data) => async (dispatch, getState, api) => {
  const results = await api
    .post(`/practice/getPractice`, data, {
      headers: authHeader(),
    })
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: APPLY_FILTER_FOR_PRACTICE,
          payload: {data:res.data.data,sortBy:data.sortBy,orderBy:data.orderBy},
        });
        dispatch(Loading(false));
      }
      return res;
    });
  return results;
};

export const sortPracticeByStudent = (data) => async (dispatch, getState, api) => {
  const results = await api
    .post(`/practice/getPracticeStudents`, data, {
      headers: authHeader(),
    })
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: APPLY_FILTER_FOR_PRACTICE,
          payload: {data:res.data.data,sortBy:data.sortBy,orderBy:data.orderBy},
        });
        dispatch(Loading(false));
      }
      return res;
    });
  return results;
};


export const totalStudentPracticeTime = (studentId) => async (dispatch, getState, api) => {
    const results = await api
      .post(`/practice/getCurrentPracticeTime`, {studentId}, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status === 200) {
            dispatch({
                type: CURRENT_PRACTICE_TIME,
                payload: res.data.data,
            });
            }
        return res;
      })
      .catch((err) => {
        return err.response;
      });
    return results;
  };

export const getStudentsPractice = (data) => async (dispatch, getState, api) => {
    console.log("==========================",data);
    const results = await api
      .post(`/practice/getPracticeStudents`, data, {
        headers: authHeader(),
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err.response;
      });
    return results;
  };


export const practiceTimePlayPause = (payload) => async (dispatch, getState, api) => {
    const results = await api
      .post(`/practice/practiceTimer`, payload, {
        headers: authHeader(),
      })
      .then((res) => {
          console.log("practice Time ====",res.data);
        // if (res.status === 200) {
        //   dispatch(getAllPlanBuilder());
        // }
        return res;
      })
      .catch((err) => {
        return err.response;
      });
    return results;
  };


  export const getCompleteInCompletePractice = (studentId) => async (dispatch, getState, api) => {
    const results = await api
      .post(`/practice/completeInCompletePracticeCount`, {studentId}, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status === 200) {
            dispatch({
                type: GET_COMPLETE_INCOMPLETE_PRACTICE,
                payload: res.data.data,
            });
            }
        return res;
      })
      .catch((err) => {
        return err.response;
      });
    return results;
  };
