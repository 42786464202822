import {
  LOGIN_WIDGET_DATA,
  GET_LOGIN_WIDGET_DATA,
  GET_PLAN_LIST,
  DELETE_WIDGET,
  VIEW_PLAN,
  GET_API_FOR_LOGIN_WIDGET,
  SUCCESS_PLAN
} from "../actionTypes";

const initialState = {
  getApiLoginWidget: {},
  successRes : null,
  loginWidgetData: [],
  planList: [],
  widgetName: "",
};

export default function loginWidgetReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_WIDGET_DATA:
      console.log("oituoirtoiret",action.payload )
      return {
          ...state,
          loginWidgetData: [...state.loginWidgetData, action.payload],
          getApiLoginWidget: {...state.getApiLoginWidget, ...action.payload}
      };
      case SUCCESS_PLAN:
      console.log("oituoirtoiret",action.payload )
      return {
          ...state,
          successRes: action.payload,
          // loginWidgetData: [...state.loginWidgetData, action.payload],
          getApiLoginWidget: {...state.getApiLoginWidget, ...action.payload}
      };
    case GET_API_FOR_LOGIN_WIDGET: 
    return {
      ...state,
      getApiLoginWidget: action.payload
    }
    case GET_LOGIN_WIDGET_DATA:
      return {
        ...state,
        loginWidgetData: action.payload,
         widgetName: action.payload[action.payload.length - 1]?.widgetName,
      };
    case GET_PLAN_LIST:
      return {
        ...state,
        planList: action.payload,
      };
    case DELETE_WIDGET:
      return {
        ...state,
      };
    case VIEW_PLAN:
      return {
        ...state
      }


    default:
      return state;
  }
}
