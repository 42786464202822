// Build My Biz
export const GET_BUILD_MY_BIZ_DOCUMENT = "GET_BUILD_MY_BIZ_DOCUMENT";
export const GET_ALL_CATEGORY = "GET_ALL_CATEGORY";
export const EDIT_DOCUMENT_ITEM = "EDIT_DOCUMENT_ITEM";
export const DELETE_DOCUMENT_ID = "DELETE_DOCUMENT_ID";
export const GET_DOCUMENT_UPLOADED_FILES = "GET_DOCUMENT_UPLOADED_FILES";
export const DOCUMENT_UPLOAD_STATUS = "DOCUMENT_UPLOAD_STATUS";
export const VIEW_DETIAL_OF_DOCUMENTS = "VIEW_DETIAL_OF_DOCUMENTS";
export const ADD_DOCUMENT_UPLOADED_FILES = "ADD_DOCUMENT_UPLOADED_FILES";
export const REMOVE_DOCUMENT_UPLOADED_FILES = "REMOVE_DOCUMENT_UPLOADED_FILES";
export const GET_CATEGORY_ID = "GET_CATEGORY_ID";
export const UPDATE_DRAG_DATA = "UPDATE_DRAG_DATA"

// Admin Type
export const GET_SINGLE_SCHOOL_ID = "GET_SINGLE_SCHOOL_ID";
export const GET_SINGLE_SCHOOL_ITEM = "GET_SINGLE_SCHOOL_ITEM";
export const GET_ALL_SCHOOL = "GET_ALL_SCHOOL";
export const FAIL_TO_ADD_SCHOOL = "FAIL_TO_ADD_SCHOOL";
export const APPLY_SORTBY_SCHOOL = "APPLY_SORTBY_SCHOOL";
export const SAVE_MERCHANT_INFO = "SAVE_MERCHANT_INFO";
export const GET_MERCHANT_INFO = "GET_MERCHANT_INFO"

// search bar

export const SEARCH_TEXT = "SEARCH_TEXT"

//recent activities

export const GET_RECENT_ACTIVITIES = "GET_RECENT_ACTIVITIES";
export const ADD_RECENT_ACTIVITIES_FOR_STUDENTS = "ADD_RECENT_ACTIVITIES_FOR_STUDENTS"

// Private Teacher
export const GET_SINGLE_PRIVATE_TEACHER_ID = "GET_SINGLE_PRIVATE_TEACHER_ID";
export const GET_SINGLE_PRIVATE_TEACHER_ITEM =
  "GET_SINGLE_PRIVATE_TEACHER_ITEM";
export const GET_ALL_PRIVATE_TEACHER = "GET_ALL_PRIVATE_TEACHER";
export const FAIL_TO_ADD_PRIVATE_TEACHER = "FAIL_TO_ADD_PRIVATE_TEACHER";
export const APPLY_SORTBY_PRIVATE_TEACHER = "APPLY_SORTBY_PRIVATE_TEACHER";

// SubAdmin Type
export const GET_ALL_SUBADMIN = "GET_ALL_SUBADMIN";
export const APPLY_FILTER_SUBADMIN = "APPLY_FILTER_SUBADMIN";
export const APPLY_SORTBY_SUBADMIN = "APPLY_SORTBY_SUBADMIN";

export const EDIT_SUBADMIN_ITEM = "EDIT_SUBADMIN_ITEM";
export const GET_SUBADMIN_ID = "GET_SUBADMIN_ID";

// Teachers Type
export const GET_ALL_TEACHER = "GET_ALL_TEACHER"
export const DROPDOWN_TEACHER = "DROPDOWN_TEACHER"
export const APPLY_FILTER_TEACHER = "APPLY_FILTER_TEACHER"
export const APPLY_SORTBY_TEACHER = "APPLY_SORTBY_TEACHER"
export const GET_INSTRUMENT_BY_TEACHER = "GET_INSTRUMENT_BY_TEACHER"
export const APPEND_INSTRUMENT_GENRE = "APPEND_INSTRUMENT_GENRE"
export const REMOVE_APPEND_INSTRUMENT_GENRE = "REMOVE_APPEND_INSTRUMENT_GENRE"
export const GET_DROPDOWN_TEACHER = "GET_DROPDOWN_TEACHER"


// Students Type
export const GET_ALL_STUDENT = "GET_ALL_STUDENT"
export const DROPDOWN_STUDENT = "DROPDOWN_STUDENT"
export const APPLY_FILTER_STUDENT = "APPLY_FILTER_STUDENT"
export const APPLY_SORTBY_STUDENT = "APPLY_SORTBY_STUDENT"
export const REMOVE_EDITED_PLAN_DATA = "REMOVE_EDITED_PLAN_DATA"
export const REMOVE_PAYMENT_DATA_DETAILS = "REMOVE_PAYMENT_DATA_DETAILS"
export const CLEAR_PAYMENT_DETAILS_DATA = "CLEAR_PAYMENT_DETAILS_DATA"

// Parent Type 
export const GET_ALL_PARENT = "GET_ALL_PARENT"
export const DROPDOWN_PARENT = "DROPDOWN_PARENT"
export const APPLY_FILTER_PARENT = "APPLY_FILTER_PARENT"
export const APPLY_SORTBY_PARENT = "APPLY_SORTBY_PARENT"

// classess/group Type 
export const GET_ALL_CLESSES_OR_GROUP = "GET_ALL_CLESSES_OR_GROUP"
export const APPLY_FILTER_CLESSES_GROUP = "APPLY_FILTER_CLESSES_GROUP"
export const APPLY_SORTBY_CLESSES_GROUP = "APPLY_SORTBY_CLESSES_GROUP"
export const GET_CLESSES_OR_GROUP_BY_TEACHER = "GET_CLESSES_OR_GROUP_BY_TEACHER"

//Pagination
export const CURRENT_PAGE = "CURRENT_PAGE";

// Courses
export const GET_ALL_COURSES = "GET_ALL_COURSES";
export const GET_ALL_WAITING_COURSES = "GET_ALL_WAITING_COURSES";
export const APPLY_SORTBY_COURSE = "APPLY_SORTBY_COURSE";
export const GET_ALL_LESSONS = "GET_ALL_LESSONS";
export const GET_ALL_GENRES = "GET_ALL_GENRES";
export const UPDATE_GENRESLIST = "UPDATE_GENRESLIST"
export const GET_ALL_LIBRARYS = "GET_ALL_LIBRARYS";
export const SET_COURSE_ID = "SET_COURSE_ID";
export const GET_PRACTICE = "GET_PRACTICE";
export const GET_COURSE_LESSONS = "GET_COURSE_LESSONS";
export const GET_ALL_COURSES_BY_STUDENTS = "GET_ALL_COURSES_BY_STUDENTS";
export const GET_GENERS_CURRICULUM = "GET_GENERS_CURRICULUM";
export const CHECK_ISDONE_STATUS = "CHECK_ISDONE_STATUS";
export const UPDATE_LESSON_DRAG_DATA = "UPDATE_LESSON_DRAG_DATA";
export const UPDATE_LESSON_DRAG = "UPDATE_LESSON_DRAG";
export const VIDEO_TRACKING = "VIDEO_TRACKING"

// Library
export const GET_ALL_Librarys = "GET_ALL_Librarys";
export const FILTER_LIBRARY = "FILTER_LIBRARY";
export const GET_DROPDOWN_LIBRARY_TEACHER = "GET_DROPDOWN_LIBRARY_TEACHER";
export const GET_COURSE_WISE_LESSON = "GET_COURSE_WISE_LESSON";
export const UPDATE_ALL_LIBRARY = "UPDATE_ALL_LIBRARY";

// Filters
export const FILTERS_BY_SUBADMIN = "FILTERS_BY_SUBADMIN";
export const FILTERS_BY_TEACHER = "FILTERS_BY_TEACHER";
export const FILTERS_BY_STUDENT = "FILTERS_BY_STUDENT";
export const FILTERS_BY_PARENT = "FILTERS_BY_PARENT";
export const FILTERS_BY_CLESSES_OR_GROUP = "FILTERS_BY_CLESSES_OR_GROUP";
export const FILTERS_BY_LIBRARY = "FILTERS_BY_LIBRARY";

// Sidebar Type
export const GET_SIDEBAR_MENU = "GET_SIDEBAR_MENU";
export const UPDATE_SIDEBAR_MENU = "UPDATE_SIDEBAR_MENU";

// Search Type
export const SEARCH_BY = "SEARCH_BY";
export const SORT_BY = "SORT_BY";

// Authentication
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGIN_AS_IN = "LOGIN_AS_IN";
export const LOGIN_AS_OUT = "LOGIN_AS_OUT";

// MODAL
export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";

//Store Type
export const ADD_STORE = "ADD_STORE";
export const EDIT_STORE_ITEM = "EDIT_STORE_ITEM";
export const UPDATE_STORE = "UPDATE_STORE";
export const GET_STORE_ID = "GET_STORE_ID";
export const GET_ALL_STORE_ITEM = "GET_ALL_STORE_ITEM";
export const SEARCH_STORE_ITEM = "SEARCH_STORE_ITEM";
export const DELIVERY_ADDRESS_DETAILS = "DELIVERY_ADDRESS_DETAILS";

export const ADD_STORE_ITEM_INTO_CART = "ADD_STORE_ITEM_INTO_CART";
export const VIEW_STORE_ITEM_FROM_CART = "VIEW_STORE_ITEM_FROM_CART";
export const REMOVE_STORE_ITEM_FROM_CART = "REMOVE_STORE_ITEM_FROM_CART";
export const INCREMENT_STORE_QUENTITY = "INCREMENT_STORE_QUENTITY";
export const DECREMENT_STORE_QUENTITY = "DECREMENT_STORE_QUENTITY";
export const GET_DEFAUKT_QUANTITY = "GET_DEFAUKT_QUANTITY";
export const UPDATE_STORE_QUANTITY = "UPDATE_STORE_QUANTITY";
export const GET_ORDER_LIST = "GET_ORDER_LIST";
export const VIEW_SINGLE_STORE_PRODUCT = "VIEW_SINGLE_STORE_PRODUCT";
export const DOWNLOAD_INVOICE = "DOWNLOAD_INVOICE";
export const DOWNLOAD_VENDOR_INVOICE = "DOWNLOAD_VENDOR_INVOICE";
export const ORDER_DETAILS = "ORDER_DETAILS";
export const VENDOR_ORDER_DETAILS = "VENDOR_ORDER_DETAILS";
export const ADD_SELLER_INFORMATION = "ADD_SELLER_INFORMATION";
export const GET_SELLER_INFORMATION = "GET_SELLER_INFORMATION";
export const UPDATE_SELLER_INFORMATION = "UPDATE_SELLER_INFORMATION";
export const DELETE_SELLER_INFORMATION = "DELETE_SELLER_INFORMATION";
export const GET_STORE_ITEM_LIST = "GET_STORE_ITEM_LIST";
export const ADD_STORE_ITEM_IN_LIST = "ADD_STORE_ITEM_IN_LIST"

// export const EDIT_STORE_ITEM = "EDIT_STORE_ITEM";
// export const DELETE_STORE_ID = "DELETE_STORE_ID";

// Loader
export const INNER_LOADER = "INNER_LOADER";

// User Profile
export const GET_USER_PRFOILE = "GET_USER_PRFOILE";
export const UPDATE_BIO_INFORMATION = "UPDATE_BIO_INFORMATION";
export const GET_BIO_INFORMATION = "GET_BIO_INFORMATION"
export const STUDENT_PRACTICE_REPORT = "STUDENT_PRACTICE_REPORT";
export const STUDENT_PRACTICE_REPORT_Video = "STUDENT_PRACTICE_REPORT_Video";

//SuperAdmin Course Type
export const GET_ALL_SUPERADMIN_COURSES = "GET_ALL_SUPERADMIN_COURSES";
export const EDIT_SUPERADMIN_COURSE_ITEM = "EDIT_SUPERADMIN_COURSE_ITEM";
export const GET_SUPERADMIN_COURSE_ID = "GET_SUPERADMIN_COURSE_ID";
export const GET_ALL_COURSESLIST_ITEM = "GET_ALL_COURSESLIST_ITEM";

// Week Scheduling
export const GET_WEEK_SLOT = "GET_WEEK_SLOT";
export const GET_TEACHER_DAILY_PREVIEW = "GET_TEACHER_DAILY_PREVIEW";
export const GET_DAY_SLOT = "GET_DAY_SLOT";
export const STUDENT_BANKED_LESSON = "STUDENT_BANKED_LESSON"
export const RESCHEDULE_BANKED_LESSON = "RESCHEDULE_BANKED_LESSON"

export const GET_MONTH_SLOT = "GET_MONTH_SLOT";

export const ASSIGN_STUDENT_LIST = "ASSIGN_STUDENT_LIST";
export const UN_ASSIGN_STUDENT_LIST = "UN_ASSIGN_STUDENT_LIST";
export const SET_EDIT_ASSIGN_STATE = "SET_EDIT_ASSIGN_STATE";

export const OPEN_BLOCK_OFF_SLOT_MODAL_ACTION =
  "OPEN_BLOCK_OFF_SLOT_MODAL_ACTION";

export const RECURRING_OPEN_BLOCK_OFF_SLOT_MODAL_ACTION =
  "RECURRING_OPEN_BLOCK_OFF_SLOT_MODAL_ACTION";

export const CUSTOM_OPEN_BLOCK_OFF_SLOT_MODAL_ACTION =
  "CUSTOM_OPEN_BLOCK_OFF_SLOT_MODAL_ACTION";

export const ASSIGN_STUDENT_ACTION = "ASSIGN_STUDENT_ACTION";
export const UNASSIGN_STUDENT_ACTION = "UNASSIGN_STUDENT_ACTION";
export const ASSIGN_CLASS_ACTION = "ASSIGN_CLASS_ACTION";
export const UNASSIGN_CLASS_ACTION = "UNASSIGN_CLASS_ACTION";

export const GET_SELECTED_TEACHER_SLOT = "GET_SELECTED_TEACHER_SLOT";

export const CHANGE_TEACHER_ACTION = "CHANGE_TEACHER_ACTION";

export const GET_SINGLE_WEEK_OPENBLOCKOFF = "GET_SINGLE_WEEK_OPENBLOCKOFF";

export const EDIT_OPEN_STATE = "EDIT_OPEN_STATE";
export const EDIT_BLOCKOFF_STATE = "EDIT_BLOCKOFF_STATE";

export const SET_WEEK_DATE_RANGE = "SET_WEEK_DATE_RANGE";

export const SET_DAY_DATE_RANGE = "SET_DAY_DATE_RANGE";

export const SET_MONTH_DATE_RANGE = "SET_MONTH_DATE_RANGE";

export const BANK_MODAL_ACTION = "BANK_MODAL_ACTION";

export const CHECK_IN_SCHEDULE = "CHECK_IN_SCHEDULE";

// student login

export const GET_STUDENT_WEEKLY_SLOT = "GET_STUDENT_WEEKLY_SLOT";

export const GET_STUDENT_RESCHEDULE_SLOT = "GET_STUDENT_RESCHEDULE_SLOT";

// Room

export const GET_PREFRENCES_ROOM = "GET_PREFRENCES_ROOM";
export const GET_ROOM_LIST = "GET_ROOM_LIST";
export const GET_ROOM_LIST_BYTIME = "GET_ROOM_LIST_BYTIME";
export const GET_LESSON_LIST_BY_TEACHER = "GET_LESSON_LIST_BY_TEACHER";
export const GET_PREFRENCE_DETAILS = "GET_PREFRENCE_DETAILS"
export const UPDATE_PREFRENCES_DETAILS = "UPDATE_PREFRENCES_DETAILS";

//teacherArr
export const GET_CLASS_GROUP_TEACHER_ARR = "GET_CLASS_GROUP_TEACHER_ARR";

// student billing

export const GET_PLAN_BUILDER = "GET_PLAN_BUILDER"
export const GET_WAITING_PLAN_BUILDER = "GET_WAITING_PLAN_BUILDER"
export const FETCH_SINGLE_PLAN = "FETCH_SINGLE_PLAN"
export const GET_ALL_CARD = "GET_ALL_CARD"
export const GET_ALL_PLAN = "GET_ALL_PLAN"
export const GET_ALL_GROUP_STUDENT = 'GET_ALL_GROUP_STUDENT'
export const GET_ALL_INSTRUMENT = "GET_ALL_INSTRUMENT"
export const GET_SUBSCRIBE_PLAN = "GET_SUBSCRIBE_PLAN"
export const GET_INSTRUMENT_TEACHER = "GET_INSTRUMENT_TEACHER"
export const SELECT_PLAN = "SELECT_PLAN"
export const SELECT_INVOICE_PAY = "SELECT_INVOICE_PAY"
export const LOGIN_WIDGET_DATA = "LOGIN_WIDGET_DATA"
export const GET_LOGIN_WIDGET_DATA = "GET_LOGIN_WIDGET_DATA"
export const GET_PLAN_LIST = "GET_PLAN_LIST"
export const VIEW_PLAN = "VIEW_PLAN"
export const SUCCESS_PLAN = "SUCCESS_PLAN"
export const DELETE_WIDGET = "DELETE_WIDGET"
export const GET_API_FOR_LOGIN_WIDGET = "GET_API_FOR_LOGIN_WIDGET"
export const GET_PAYMENT_METHODS = "GET_PAYMENT_METHODS"
export const GET_PAYMENT_DATA_DETAILS = "GET_PAYMENT_DATA_DETAILS"
export const GET_WIDGET_DATA = 'GET_WIDGET_DATA'
export const CANCEL_PAYMENT = "CANCEL_PAYMENT"
export const GET_ENABLE_PLAN_LIST = "GET_ENABLE_PLAN_LIST"
export const BOOK_PLAN = "BOOK_PLAN"
export const GET_PLAN_SCHOOL_PRIVATE_TEACHER = "GET_PLAN_SCHOOL_PRIVATE_TEACHER"

// Practice Student 

// Practice Student

export const GET_ALL_PRACTICE = "GET_ALL_PRACTICE";
export const APPLY_FILTER_FOR_PRACTICE = "APPLY_FILTER_FOR_PRACTICE";
export const CURRENT_PRACTICE_TIME = "CURRENT_PRACTICE_TIME";
export const PAUSE_PRACTICE_TIME = "PAUSE_PRACTICE_TIME";
export const GET_COMPLETE_INCOMPLETE_PRACTICE =
  "GET_COMPLETE_INCOMPLETE_PRACTICE";

// Chats

export const SOCKET_CONNECTION = "SOCKET_CONNECTION";
export const SOCKET_AUTHENTICATION = "SOCKET_AUTHENTICATION";
export const CHAT_USER_LIST = "CHAT_USER_LIST";
export const SELECT_RECEIVER_DETAILS = "SELECT_RECEIVER_DETAILS";
export const MESSAGE_RECEIVED = "MESSAGE_RECEIVED";
export const SINGLE_MESSAGE_RECEIVED = "SINGLE_MESSAGE_RECEIVED";
export const MESSAGE_COUNT = "MESSAGE_COUNT";

export const SHOW_RECEIVER_DETAIL = "SHOW_RECEIVER_DETAIL";
export const FILE_UPLOAD = "FILE_UPLOAD";

export const SET_USER_STATUS = "SET_USER_STATUS";

export const TYPING_MESSAGE = "TYPING_MESSAGE";

export const CHAT_MODAL = "CHAT_MODAL";

export const SET_ACTIVE_ROOM = "SET_ACTIVE_ROOM";

export const MESSAGE_LOADER = "MESSAGE_LOADER";
export const READ_MESSAGE = "READ_MESSAGE";
export const CHAT_SCROLLL = "CHAT_SCROLLL";

// Notification

export const NOTIFICATION_COUNT = "NOTIFICATION_COUNT";
export const SCHEDULING_NOTIFICATION_STATUS = "SCHEDULING_NOTIFICATION_STATUS";
export const GET_NEW_NOTIFICATION = "GET_NEW_NOTIFICATION";
export const APPLY_FILTER_NOTIFICATION = "APPLY_FILTER_NOTIFICATION";

// Prefrences Setting
export const EDIT_ROOM_DATA = "EDIT_ROOM_DATA";
export const GET_PAYMENT_METHOD_LIST = "GET_PAYMENT_METHOD_LIST";
export const ADD_PAYMENT_METHOD_LIST = "ADD_PAYMENT_METHOD_LIST";
export const DELETE_PAYMENT_METHOD_LIST = "DELETE_PAYMENT_METHOD_LIST";

// Reporting
export const GET_PAYROLL_REPORT = "GET_PAYROLL_REPORT";
export const GET_APROOVE_PAYROLL = "GET_APROOVE_PAYROLL";
export const GET_STUDENT_PAYROLL_REPORT = "GET_STUDENT_PAYROLL_REPORT";
export const DELETE_PAYROLL_REPORT = "DELETE_PAYROLL_REPORT";
export const STUDENT_REPORT_BY_ADMIN = "STUDENT_REPORT_BY_ADMIN";
export const STUDENT_REPORT_BY_ADMIN_FORM_DATA = "STUDENT_REPORT_BY_ADMIN_FORM_DATA";
export const GET_GENERATE_CSV = "GET_GENERATE_CSV";

// Attendance Report

export const GET_ATTENDANCE_TEACHER_REPORT = "GET_ATTENDANCE_TEACHER_REPORT";
export const GET_STUDENTLIST_TEACHERWISE_REPORT =
  "GET_STUDENTLIST_TEACHERWISE_REPORT";
export const GET_ATTENDANCE_STUDENT_REPORT = "GET_ATTENDANCE_STUDENT_REPORT";

// student instrumanet

export const GET_INSTRUMENT_BY_STUDENT = "GET_INSTRUMENT_BY_STUDENT";

//
export const GET_STUDENT_INSTRUMENT_NEW = "GET_STUDENT_INSTRUMENT_NEW";
export const GET_INSTRUMENT_BY_TEACHER_NEW = "GET_INSTRUMENT_BY_TEACHER_NEW";
export const DELETE_STUDENT_INSTRUMENT_NEW = "DELETE_STUDENT_INSTRUMENT_NEW";
export const DELETE_INSTRUMENT_BY_TEACHER_NEW = "DELETE_INSTRUMENT_BY_TEACHER_NEW";
export const GET_ALLOW_ADD_STUDENT = "GET_ALLOW_ADD_STUDENT";
export const GET_RETENTION_REPORT = "GET_RETENTION_REPORT";
export const PAYMENT_REPORT_ALL = "PAYMENT_REPORT_ALL";
export const SET_PAYMNET_REMINDER = "SET_PAYMNET_REMINDER";
export const PAYMNET_NOTE_REPORT = "PAYMNET_NOTE_REPORT";
export const GET_BANKED_REPORT = "GET_BANKED_REPORT";
export const DELETE_BANKED_REPORT = "DELETE_BANKED_REPORT";
export const UPDATE_PAYMENT_DETAILS = 'UPDATE_PAYMENT_DETAILS';
export const UPDATE_BANK_EXP_DATE = "UPDATE_BANK_EXP_DATE";
export const BILLING_PAYMENT_REPORT = "BILLING_PAYMENT_REPORT";
export const STUDENT_PAYMENT_METHOD = "STUDENT_PAYMENT_METHOD";
export const WIDGET_STUDENT = "WIDGET_STUDENT";
export const GET_ASSIGN_PRACTICE = "GET_ASSIGN_PRACTICE";
export const GET_PRACTICE_TIMER = "GET_PRACTICE_TIMER";
export const GET_VIDEOS = "GET_VIDEOS";

// custom comminication
export const GET_CUSTOM_COMMUNICATION = "GET_CUSTOM_COMMUNICATION";

// contracts 

export const SAVE_CONTRACTS_DETAILS = "SAVE_CONTRACTS_DETAILS";
export const GET_CONTRACTS_DETAILS = "GET_CONTRACTS_DETAILS";

// notification setting

export const GET_NOTIFICATION_SETTING_DETAILS = "GET_NOTIFICATION_SETTING_DETAILS";
export const UPDATE_NOTIFICATION_SETTING_DETAILS = "UPDATE_NOTIFICATION_SETTING_DETAILS";

// communication log

export const GET_COMMUNICATION_LOG = "GET_COMMUNICATION_LOG"

//generate payroll

export const GET_GENERATE_PAYROLL = "GET_GENERATE_PAYROLL"
export const HANDLE_GENERATE_PAYROLL_DETAILS = "HANDLE_GENERATE_PAYROLL_DETAILS"

// Quizes

export const ADD_QUIZ_CATEGORY = "ADD_QUIZ_CATEGORY"
export const GET_QUIZ_CATEGORIES = "GET_QUIZ_CATEGORIES"
export const ADD_QUIZ_QUESTION = "ADD_QUIZ_QUESTION"
export const GET_QUIZZES = "GET_QUIZZES"
export const DELETE_QUESTION = "DELETE_QUESTION"
export const UPDATE_QUIZ_QUESTION = "UPDATE_QUIZ_QUESTION"
export const SUBMIT_QUS_ANSWER = "SUBMIT_QUS_ANSWER"

// import members

export const DOWNLOAD_ROLE_CSV = "DOWNLOAD_ROLE_CSV"

// Musical Interactives

export const ADD_EAR_TRAINER = "ADD_EAR_TRAINER"
export const GET_EAR_TRAINER = "GET_EAR_TRAINER"
export const DELETE_EAR_TRAINER = "DELETE_EAR_TRAINER"
export const UPDATE_EAR_TRAINER = "UPDATE_EAR_TRAINER"
export const GET_EAR_TRAINER_CATEGORY = "GET_EAR_TRAINER_CATEGORY"
export const ADD_EAR_TRAINER_CATEGORY = "ADD_EAR_TRAINER_CATEGORY"
export const EDIT_EAR_TRAINER_QUESTION = "EDIT_EAR_TRAINER_QUESTION"
export const GET_EAR_TRAINER_BY_LEVEL = "GET_EAR_TRAINER_BY_LEVEL"
export const CLEAR_EAR_TRAINER = "CLEAR_EAR_TRAINER"
export const UPDATE_EAR_TRAINER_CATEGORY = "UPDATE_EAR_TRAINER_CATEGORY"
export const DELETE_EAR_TRAINER_CATEGORY = "DELETE_EAR_TRAINER_CATEGORY"

// Favourites

export const GET_FAVOURITE_SONG_LIST = 'GET_FAVOURITE_SONG_LIST'
export const ADD_FAVOURITE_SONG = 'ADD_FAVOURITE_SONG'
export const UPDATE_FAVOURITE_SONG = 'UPDATE_FAVOURITE_SONG'
export const DELETE_FAVOURITE_SONG = 'DELETE_FAVOURITE_SONG'

// group email

export const GROUP_EMAIL_RES = 'GROUP_EMAIL_RES'

// invoice

export const GET_INVOICE_RES = 'GET_INVOICE_RES'
export const ADD_INVOICE_RES = 'ADD_INVOICE_RES'
export const RECURING_INVOICE_RES = 'RECURING_INVOICE_RES'