import React from "react";
import Sidebar from "../layout/sidebar";
import { withRouter } from "react-router-dom";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { socketConnect, socketSendRequest, socket } from "../socketService";
import { SOCKET_CONNECTION } from "../redux/actionTypes";
import Cookies from "universal-cookie";
import { ToastContainer } from 'react-toastify';
import { sendFCMToken } from "../redux/notifications/action";

const App = ({ children, history }) => {

  const cookies = new Cookies();
  const dispatch = useDispatch()
  const { currentUser, loginAsUser } = useSelector((state) => state.auth);
  const isAuthenticate = useSelector(state => state.chatState.isAuthenticate)
  const tabId = useSelector(state => state.chatState.tabId)


  useEffect(() => {
    if (!currentUser && document.location.pathname !== '/studentSignup' && document.location.pathname !== '/') {
      history.push("/login");
    }
  }, [currentUser, history]);

  useEffect(() => {
    socketConnect(dispatch, (flag) => {
      if (flag) {
        dispatch({ type: SOCKET_CONNECTION, payload: flag })
      }
    })
    // unsubscribe from event for preventing memory leaks
    return () => {
      socket.off('response');
    };
  }, [])

  const userToken = cookies.get('amplitech')
  //console.log("userToken",userToken);

  useEffect(() => {
    const enableChat = currentUser?.role === 'superadmin' || currentUser?.role === 'admin' || currentUser?.role === 'subadmin' || loginAsUser?.role === 'superadmin' || loginAsUser?.role === 'admin' || loginAsUser?.role === 'subadmin' || currentUser?.role === 'teacher' || currentUser?.role === 'student' || loginAsUser?.role === 'teacher' || loginAsUser?.role === 'student'
    if (enableChat) {
      const userToken = cookies.get('amplitech')
      const asUserToken = cookies.get('loginAsUser')
      if (currentUser && !isAuthenticate) {
        socketSendRequest('auth', { token: loginAsUser ? asUserToken : userToken })
        socketSendRequest('screenManager', {})
      }
    }
  }, [currentUser?.role, loginAsUser?.role, cookies])


  //console.log("==========app messaging ============",window.messaging);

  useEffect(() => {
    const enableChat = currentUser?.role === 'superadmin' || currentUser?.role === 'admin' || currentUser?.role === 'subadmin' || loginAsUser?.role === 'superadmin' || loginAsUser?.role === 'admin' || loginAsUser?.role === 'subadmin' || currentUser?.role === 'teacher' || currentUser?.role === 'student' || loginAsUser?.role === 'teacher' || loginAsUser?.role === 'student'
    if (enableChat) {
      if (window.messaging) {
        window.messaging.getToken().then((currentToken) => {
          if (currentToken) {
            dispatch(sendFCMToken({
              userId: loginAsUser ? loginAsUser?.userId : currentUser?.userId,
              fcmToken: currentToken
            }))
            localStorage.setItem('notification_token', currentToken);
          } else {
            // Show permission request.
          }
        }).catch((err) => {
        });

        window.messaging.onMessage((payload) => {
          // console.log('================ FG Payload ================', payload);
          if (!document.hasFocus() && Number(cookies.get('tabId')) === tabId) {
            const options = {
              body: payload.notification.body,
              icon: payload.notification?.icon,
              // data: payload.data.click_action, //the url which we gonna use later
              // sound: '/sound.mp3',
              silent: true,
            };
            // const a = new Audio('/sound.mp3');
            // a.play();
            const notification = new Notification(payload.notification.title, options);
            notification.onclick = (event) => {
              // console.log('================ FG Notification Click Event ================', event);
              // console.log('================ FG Payload ================', event);
              window.focus();
            };
          }


        });
      }

      if (navigator.serviceWorker) {
        navigator.serviceWorker.addEventListener('message', (message) => {
        });
      }

    }
  }, [])


  return (
    <div id="wrapper">
      <Sidebar />
      <div id="content-wrapper" className="d-flex flex-column">
        {children}
      </div>
      <ToastContainer />
    </div>
  );
};

export default withRouter(App);
