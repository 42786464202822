import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { onlyNumber } from '../utils'
import Validation from '../component/formValidation'
import { addInvoicePay, getInvoicePay } from '../redux/studentBilling/action'
import { useParams } from 'react-router-dom/cjs/react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import SweetAlert from "sweetalert2";

const InvoicePay = () => {
	const { register, handleSubmit, errors, watch, reset } = useForm({ mode: 'onSubmit' })
	const { id: paramsId } = useParams();
	const dispatch = useDispatch()
	const invoicePayData = useSelector((state) => state.studentBillingState.invoicePayData)
	const [details, setDetails] = useState({
		firstName: "",
		lastName: "",
		email: "",
		phoneNumber: "",
		country: "",
		city: "",
		state: "",
		postalCode: "",
		address1: "",
		securityCode: "",
	});

	useEffect(() => {
		dispatch(getInvoicePay(paramsId))
	}, [])

	const SubmitCardDetails = (state) => {
		dispatch(addInvoicePay({
			cardNo: state.card_number,
			expDate: `${state.card_year}-${state.card_month}`,
			cardCode: state.csv_number,
			invoiceId: paramsId,
			adminId: invoicePayData?.adminId?._id,
			amount: state.amount || invoicePayData?.dueDeposit,
			email: state.email,
			cellPhone: state.phoneNumber,
			firstName: state.firstName,
			lastName: state.lastName,
			address1: state.address1,
			city: state.city,
			state: state.state,
			country: state.country,
			postalCode: state.postalCode,
			schoolName: invoicePayData?.adminId?.school_name,
		})).then((res) => {
			if (res.status === 200) {
				reset()
				SweetAlert.fire({ text: res.data.message, icon: 'success', showConfirmButton: false, allowOutsideClick: false })
			} else {
				SweetAlert.fire({ text: res.data.message, icon: 'error' })
				console.log("cerr================", res.data.message);
			}
		})
	}

	const handleChangeDetails = (e) => {
		switch (e.target.name) {
			case "firstName":
				setDetails({ ...details, firstName: e.target.value });
				break;

			case "lastName":
				setDetails({ ...details, lastName: e.target.value });
				break;

			case "email":
				setDetails({ ...details, email: e.target.value });
				break;

			case "phoneNumber":
				setDetails({ ...details, phoneNumber: e.target.value });
				break;

			case "country":
				setDetails({ ...details, country: e.target.value });
				break;

			case "city":
				setDetails({ ...details, city: e.target.value });
				break;

			case "state":
				setDetails({ ...details, state: e.target.value });
				break;
			case "postalCode":
				setDetails({ ...details, postalCode: e.target.value });
				break;
			case "address1":
				setDetails({ ...details, address1: e.target.value });
				break;
			case "securityCode":
				setDetails({ ...details, securityCode: e.target.value });
				break;

			default:
				break;
		}
	};

	const cardValidator = (cn) => {
		let amex = new RegExp('^3[47][0-9]{13}$');
		let visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$');

		let mastercard = new RegExp('^5[1-5][0-9]{14}$');
		let mastercard2 = new RegExp('^2[2-7][0-9]{14}$');

		let disco1 = new RegExp('^6011[0-9]{12}[0-9]*$');
		let disco2 = new RegExp('^62[24568][0-9]{13}[0-9]*$');
		let disco3 = new RegExp('^6[45][0-9]{14}[0-9]*$');

		let diners = new RegExp('^3[0689][0-9]{12}[0-9]*$');
		let jcb = new RegExp('^35[0-9]{14}[0-9]*$');

		var rupayRegEx = new RegExp(/^6(?!011)(?:0[0-9]{14}|52[12][0-9]{12})$/);
		if (visa.test(cn)) {
			return true;
		  }
		  if (rupayRegEx.test(cn)) {
			return true;
		  }
		  if (amex.test(cn)) {
			return true;
		  }
		  if (mastercard.test(cn) || mastercard2.test(cn)) {
			return true;
		  }
		  if (disco1.test(cn) || disco2.test(cn) || disco3.test(cn)) {
			return true;
		  }
		  if (diners.test(cn)) {
			return true;
		  }
		  if (jcb.test(cn)) {
			return true;
		  }
		return 'Card Number is InValid';
	}

	return (
		<div className='invoice_pay container'>
			<div className="modal-header pb-2" style={{ padding: '0px' }}>
				<h2 className="mb-0">Add Card Details</h2>
			</div>
			<div>
				<ul className='invoice_pay_details mt-4'>
					<li><b>School Name:</b> {invoicePayData?.adminId?.school_name}</li>
					<li><b>Invoice #:</b> {invoicePayData?.invoiceNo}</li>
					<li><b>Bill To:</b> {invoicePayData?.studentId?.first_name} {invoicePayData?.studentId?.last_name}</li>
					<li><b>Balance Due:</b> ${invoicePayData?.dueDeposit}</li>
				</ul>
			</div>
			<form onSubmit={handleSubmit(SubmitCardDetails)}>
				<h4 className="mb-4 mt-4">Credit Card information</h4>
				<div className="form-row mx-0">
					<div className="col-md-6">
						<div className="form-group fullWidth mb-4">
							<lable>
								Card Number <span className="text-red">*</span>
							</lable>
							<input
								type="text"
								className={`form-control ${!errors.card_number && watch("card_number")
									? "valid"
									: errors.card_number
										? "invalid"
										: ""
									}`}
								onKeyPress={onlyNumber}
								maxLength={16}
								name="card_number"
								ref={register({
									required: 'Card Number is Required',
									validate: cardValidator,
									// pattern:
									// 	{
									// 		value: /^(?:5[1-5][0-9]{14})$/,
									// 		message:"Card Number is Invalid",
									// 	}

								})}
								placeholder="**** **** **** ****"
							/>
							<Validation
								errors={errors.card_number}
								message={errors?.card_number?.message}
								watch={watch("card_number")}
							/>
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-group fullWidth mb-4">
							<lable>
								CSV <span className="text-red">*</span>
							</lable>
							<input
								type="text"
								className={`form-control ${!errors.csv_number && watch("csv_number")
									? "valid"
									: errors.csv_number
										? "invalid"
										: ""
									}`}
								onKeyPress={onlyNumber}
								name="csv_number"
								ref={register({ required: 'Csv is Required' })}

							/>
							<Validation
								errors={errors.csv_number}
								message={errors?.csv_number?.message}
								watch={watch("csv_number")}
							/>
						</div>
					</div>
				</div>
				<div className="form-row mx-0">
					<div className="col-md-6">
						<div className="form-group fullWidth mb-4">
							<lable>
								Expiration Date <span className="text-red">*</span>
							</lable>
							<div className="fullWidth d-flex align-items-center">
								<select
									className={`form-control mr-4 ${!errors.card_month && watch("card_month")
										? "valid"
										: errors.card_month
											? "invalid"
											: ""
										}`}
									name="card_month"
									ref={register({ required: 'Month is Required' })}
								>
									<option value="">MM</option>
									<option value="01">01</option>
									<option value="02">02</option>
									<option value="03">03</option>
									<option value="04">04</option>
									<option value="05">05</option>
									<option value="06">06</option>
									<option value="07">07</option>
									<option value="08">08</option>
									<option value="09">09</option>
									<option value="10">10</option>
									<option value="11">11</option>
									<option value="12">12</option>
								</select>
								<Validation
									errors={errors.card_month}
									message={errors?.card_month?.message}
									watch={watch("card_month")}
								/>
								<select
									className={`form-control ${!errors.card_year && watch("card_year")
										? "valid"
										: errors.card_year
											? "invalid"
											: ""
										}`}
									name="card_year"
									ref={register({ required: 'Year is Required' })}
								>
									<option value="">YYYY</option>
									<option value="2021">2021</option>
									<option value="2022">2022</option>
									<option value="2023">2023</option>
									<option value="2024">2024</option>
									<option value="2025">2025</option>
									<option value="2026">2026</option>
									<option value="2027">2027</option>
									<option value="2028">2028</option>
									<option value="2029">2029</option>
									<option value="2030">2030</option>
									<option value="2031">2031</option>
									<option value="2032">2032</option>
									<option value="2033">2033</option>
									<option value="2034">2034</option>
									<option value="2035">2035</option>
									<option value="2036">2036</option>
								</select>
								<Validation
									errors={errors.card_year}
									message={errors?.card_year?.message}
									watch={watch("card_year")}
								/>
							</div>
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-group fullWidth mb-4">
							<lable>
								Amount To Pay Now <span className="text-red">*</span>
							</lable>
							<input
								type="text"
								className={`form-control ${!errors.amount && watch("amount")
									? "valid"
									: errors.amount
										? "invalid"
										: ""
									}`}
								onKeyPress={onlyNumber}
								name="amount"
								ref={register({ required: 'Csv is Required' })}
								disabled={true}
								value={invoicePayData?.dueDeposit}
							/>
							<Validation
								errors={errors.amount}
								message={errors?.amount?.message}
								watch={watch("amount")}
							/>
						</div>
					</div>
				</div>
				<h4 className="mb-4 mt-4">Billing Information</h4>
				<div className="form-row mx-0">
					<div className="col-md-6">
						<div className="form-group fullWidth mb-4">
							<label>
								First Name <span className="text-red">*</span>
							</label>
							<input
								type="text"
								name="firstName"
								className={`form-control ${!errors.firstName && watch("firstName")
									? "valid"
									: errors.firstName
										? "invalid"
										: ""
									}`}
								onChange={(e) => handleChangeDetails(e)}
								ref={register({ required: "First Name is Required" })}
								defaultValue={invoicePayData?.studentId?.first_name}
							/>
							<Validation
								errors={errors.firstName}
								message={errors?.firstName?.message}
								watch={watch("firstName")}
							/>
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-group fullWidth mb-4">
							<label>
								Last Name <span className="text-red">*</span>
							</label>
							<input
								type="text"
								name="lastName"
								className={`form-control ${!errors.lastName && watch("lastName")
									? "valid"
									: errors.lastName
										? "invalid"
										: ""
									}`}
								ref={register({ required: "Last Name is Required" })}
								onChange={(e) => handleChangeDetails(e)}
								defaultValue={invoicePayData?.studentId?.last_name}
							/>
							<Validation
								errors={errors.lastName}
								message={errors?.lastName?.message}
								watch={watch("lastName")}
							/>
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-group fullWidth mb-4">
							<label>
								Email <span className="text-red">*</span>
							</label>
							<input
								type="text"
								name="email"
								className={`form-control ${!errors.email && watch("email")
									? "valid"
									: errors.email
										? "invalid"
										: ""
									}`}
								ref={register({ required: "Email is Required" })}
								onChange={(e) => handleChangeDetails(e)}
								defaultValue={invoicePayData?.studentId?.email}
							/>
							<Validation
								errors={errors.email}
								message={errors?.email?.message}
								watch={watch("email")}
							/>
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-group fullWidth mb-4">
							<label>
								Address 1 <span className="text-red">*</span>
							</label>
							<input
								type="text"
								className={`form-control ${!errors.address1 && watch("address1")
									? "valid"
									: errors.address1
										? "invalid"
										: ""
									}`}
								ref={register({ required: "Address is Required" })}
								name="address1"
								onChange={(e) => handleChangeDetails(e)}
								defaultValue={invoicePayData?.studentId?.address1}
							/>
							<Validation
								errors={errors.address1}
								message={errors?.address1?.message}
								watch={watch("address1")}
							/>
						</div>
					</div>

					<div className="col-md-6">
						<div className="form-group fullWidth mb-4">
							<label>
								City <span className="text-red">*</span>
							</label>
							<input
								type="text"
								className={`form-control ${!errors.city && watch("city")
									? "valid"
									: errors.city
										? "invalid"
										: ""
									}`}
								ref={register({ required: "City is Required" })}
								name="city"
								onChange={(e) => handleChangeDetails(e)}
								defaultValue={invoicePayData?.studentId?.city}
							/>
							<Validation
								errors={errors.city}
								message={errors?.city?.message}
								watch={watch("city")}
							/>
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-group fullWidth mb-4">
							<label>State</label> <span className="text-red">*</span>
							<input
								type="text"
								className={`form-control ${!errors.state && watch("state")
									? "valid"
									: errors.state
										? "invalid"
										: ""
									}`}
								ref={register({ required: "State is Required" })}
								name="state"
								onChange={(e) => handleChangeDetails(e)}
								defaultValue={invoicePayData?.studentId?.state}
							/>
							<Validation
								errors={errors.state}
								message={errors?.state?.message}
								watch={watch("state")}
							/>
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-group fullWidth mb-4">
							<label>Postal Code</label> <span className="text-red">*</span>
							<input
								type="text"
								className={`form-control ${!errors.postalCode && watch("postalCode")
									? "valid"
									: errors.postalCode
										? "invalid"
										: ""
									}`}
								ref={register({ required: "Postal Code is Required" })}
								name="postalCode"
								onChange={(e) => handleChangeDetails(e)}
								defaultValue={invoicePayData?.studentId?.postal_code}
							/>
							<Validation
								errors={errors.postalCode}
								message={errors?.postalCode?.message}
								watch={watch("postalCode")}
							/>
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-group fullWidth mb-4">
							<label>
								Country <span className="text-red">*</span>
							</label>
							<input
								type="text"
								className={`form-control ${!errors.country && watch("country")
									? "valid"
									: errors.country
										? "invalid"
										: ""
									}`}
								ref={register({ required: "Country is Required" })}
								name="country"
								onChange={(e) => handleChangeDetails(e)}
								defaultValue={invoicePayData?.studentId?.country}
							/>
							<Validation
								errors={errors.country}
								message={errors?.country?.message}
								watch={watch("country")}
							/>
						</div>
					</div>

					<div className="col-md-6">
						<div className="form-group fullWidth mb-4">
							<label>
								Phone <span className="text-red">*</span>
							</label>
							<input
								type="text"
								className={`form-control ${!errors.phoneNumber && watch("phoneNumber")
									? "valid"
									: errors.phoneNumber
										? "invalid"
										: ""
									}`}
								name="phoneNumber"
								ref={register({ required: "Phone is Required" })}
								onChange={(e) => handleChangeDetails(e)}
								defaultValue={invoicePayData?.studentId?.cellPhone || invoicePayData?.studentId?.cellPhone}
							/>
							<Validation
								errors={errors.phoneNumber}
								message={errors?.phoneNumber?.message}
								watch={watch("phoneNumber")}
							/>
						</div>
					</div>
				</div>

				<div className="fullWidth mt-5">
					<button
						type="submit"
						className="btn_primary float-left mr-4 text-capitalize"
					>
						Save
					</button>
				</div>
			</form>
		</div>
	)
}

export default InvoicePay