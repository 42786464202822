import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./component/app";
import "./assets/scss/app.scss";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import Loader from "./layout/loader";
import SignIn from "./auth";
import SignUp from './auth/SignUp'
import ResetPassword from "./auth/resetPassword";
import Invoice from "./component/store/Invoice";
import VendorInvoice from "./component/store/VendorInvoice";
import PrivateRouter from "./PrivateRouter";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./ErrorFallback";
import StudentSignup from "./StudentSignup";
import Home from "./component/Home";
import InvoicePay from "./InvoicePay";

const Root = () => {
  const loginAsUser = JSON.parse(localStorage.getItem("loginAsLocalStorageData"));
  const currentData = JSON.parse(localStorage.getItem("currentData"));

  return (
    <Provider store={store}>
      <Router>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Suspense fallback={<Loader />}> 
            <Switch>
            <Route exact path="/" component={Home} />
              {/* <Route exact path={"/landing"} component={Landing} /> */}
              <Route exact path={"/login"} component={SignIn} />
              <Route exact path={"/signup/:id"} component={SignUp} />
              <Route exact path={"/invoices/pay/:id"} component={InvoicePay} />
              <Route path={"/resetpassword/:id"} component={ResetPassword} />
              <Route path={"/invoice/:id"} component={Invoice} /> 
              <Route path={"/vendor/:id"} component={VendorInvoice} />
              <Route exact path={"/studentSignup/:id"} component={StudentSignup} />
              <App>
                <Route 
                  exact
                  path={"/"}
                  render={() => {
                    return <Redirect to={(loginAsUser?.role === "parent" || currentData?.role === "parent") ? "/member/children" : currentData?.role ? "/dashboard" : '/'} />;
                  }}
                />
                {/* || document.location.pathname === '/studentSignup' */}
                <Switch>
                  <PrivateRouter />
                  {(loginAsUser?.role === "parent" || currentData?.role === "parent") ? (
                    <Redirect to={"/member/children"} />
                  ) : (
                    <Redirect to={"/dashboard"} />
                  )}
                </Switch>
              </App>
            </Switch>
          </Suspense>
        </ErrorBoundary>
      </Router>
    </Provider>
  );
};
ReactDOM.render(<Root />, document.getElementById("root"));
