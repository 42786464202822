import { toast } from "react-toastify";
import { GET_PLAN_BUILDER, FETCH_SINGLE_PLAN, GET_ALL_CARD, GET_ALL_PLAN, GET_ALL_INSTRUMENT, GET_INSTRUMENT_TEACHER, SELECT_PLAN, GET_SUBSCRIBE_PLAN, GET_WAITING_PLAN_BUILDER, GET_PAYMENT_METHODS, GET_PAYMENT_DATA_DETAILS, CANCEL_PAYMENT, BOOK_PLAN, GET_PLAN_SCHOOL_PRIVATE_TEACHER, GET_ENABLE_PLAN_LIST, GET_WIDGET_DATA, GET_ALL_GROUP_STUDENT, SELECT_INVOICE_PAY } from "../../redux/actionTypes";
import { authHeader } from "../../services/authHeader";

export const getAllPlanBuilder = (data) => async (dispatch, getState, api) => {
  const results = await api
    .post(`/studentBilling/getListPlanBuilder`, data, {
      headers: authHeader(),
    })
    .then((res) => {
      console.log("dfhfjhgjfdfd", res.data.data)
      if (res.status === 200) {
        dispatch({
          type: GET_PLAN_BUILDER,
          payload: res.data.data,
        });
      }
      return res;
    });
  return results;
};

export const getEnablePlanList = (data) => async (dispatch, getState, api) => {
  const results = await api
    .post(`/schoolBilling/getSchoolPlanList`, data, {
      headers: authHeader(),
    })
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: GET_ENABLE_PLAN_LIST,
          payload: res.data.data,
        });
      }
      return res;
    });
  return results;
};


export const getAllWaitingPlanBuilder = (data) => async (dispatch, getState, api) => {
  const results = await api
    .post(`/studentBilling/getListPlanBuilder`, data, {
      headers: authHeader(),
    })
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: GET_WAITING_PLAN_BUILDER,
          payload: res.data.data,
        });
      }
      return res;
    });
  return results;
};


export const addPlanBuilder = (payload, currentUser, loginAsUser) => async (dispatch, getState, api) => {
  const results = await api
    .post(`/studentBilling/addPlanBuilder`, payload, {
      headers: authHeader(),
    })
    .then((res) => {
      if (res.status === 200) {
        dispatch(getAllPlanBuilder({
          userId: loginAsUser ? loginAsUser?.userId : currentUser?.userId,
          page: 1,
          limit: 6
        }))
        dispatch(getAllWaitingPlanBuilder({
          userId: loginAsUser ? loginAsUser?.userId : currentUser?.userId,
          isPlanApproveList: false,
          page: 1,
          limit: 6
        }))
      }
      return res;
    })
    .catch((err) => {
      return err.response;
    });
  return results;
};

export const updatePlanBuilder = (payload, currentUser, loginAsUser) => async (dispatch, getState, api) => {
  const results = await api
    .post(`/studentBilling/updatePlanBuilder`, payload, {
      headers: authHeader(),
    })
    .then((res) => {
      if (res.status === 200) {
        dispatch(getAllPlanBuilder({
          userId: loginAsUser ? loginAsUser?.userId : currentUser?.userId,
          page: 1,
          limit: 6
        }))
        dispatch(getAllWaitingPlanBuilder({
          userId: loginAsUser ? loginAsUser?.userId : currentUser?.userId,
          isPlanApproveList: false,
          page: 1,
          limit: 6
        }))
      }
      return res;
    })
    .catch((err) => {
      return err.response;
    });
  return results;
};

export const bookPlanBuilder = (payload) => async (dispatch, getState, api) => {
  const results = await api.post(`/bookPlan/bookplanschool`, payload, { headers: authHeader(), })
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: BOOK_PLAN,
          payload: res.data
        })
        toast.success('Plan Booked Successfully');
      }
      return res;
    })
    .catch((err) => {
      toast.error(`${err?.response?.data?.message}`);
      return err.response;
    });
  return results;
};

export const getPlanOfSchool = (payload) => async (dispatch, getState, api) => {
  const results = await api.post(`/bookPlan/getbookplanschool`, payload, { headers: authHeader(), })
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: GET_PLAN_SCHOOL_PRIVATE_TEACHER,
          payload: res.data
        })
      }
      return res;
    })
    .catch((err) => {
      return err.response;
    });
  return results;
};

export const studentExceedLimit = (payload) => async (dispatch, getState, api) => {
  const results = await api.post(`/bookPlan/planUpgradeEmail`, payload, { headers: authHeader(), })
    .then((res) => {
      // if (res.status === 200) {
      //   dispatch({
      //     type: GET_PLAN_SCHOOL_PRIVATE_TEACHER,
      //     payload: res.data
      //   })
      // }
      return res;
    })
    .catch((err) => {
      return err.response;
    });
  return results;
};

export const updatePlanOfAdmin = (payload) => async (dispatch, getState, api) => {
  const results = await api.post(`/bookPlan/updatebookplanschool`, payload, { headers: authHeader(), })
    .then((res) => {
      // if (res.status === 200) {
      //   dispatch({
      //     type: GET_PLAN_SCHOOL_PRIVATE_TEACHER,
      //     payload: res.data
      //   })
      // }
      return res;
    })
    .catch((err) => {
      return err.response;
    });
  return results;
};

export const canclePlan = (payload) => async (dispatch, getState, api) => {
  const results = await api.post(`/bookPlan/cancelBookPlanSchool`, payload, { headers: authHeader(), })
    .then((res) => {
      // if (res.status === 200) {
      //   dispatch({
      //     type: GET_PLAN_SCHOOL_PRIVATE_TEACHER,
      //     payload: res.data
      //   })
      // }
      return res;
    })
    .catch((err) => {
      return err.response;
    });
  return results;
};


export const studentBillingImage = (payload) => async (dispatch, getState, api) => {
  let formData = new FormData();
  formData.append('fileUpload', payload);
  const results = await api
    .post(`fileUpload/upload`, formData, { headers: authHeader() })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
  return results;
};


export const deletePlanBuilder = (data, currentUser, loginAsUser) => async (dispatch, getState, api) => {
  // const data = { data };
  const results = await api
    .delete(`/studentBilling/deletePlanBuilder`, {
      data,
      headers: authHeader(),
    })
    .then((res) => {
      if (res.status === 200) {
        dispatch(getAllPlanBuilder({
          userId: loginAsUser ? loginAsUser?.userId : currentUser?.userId,
          page: 1,
          limit: 6
        }))
        dispatch(getAllWaitingPlanBuilder({
          userId: loginAsUser ? loginAsUser?.userId : currentUser?.userId,
          isPlanApproveList: false,
          page: 1,
          limit: 6
        }))
      }
      return res
    })
    .catch((err) => {
      return err.response;
    });
  return results;
};

export const fetchSinglePlanData = (planData) => dispatch => {
  dispatch({
    type: FETCH_SINGLE_PLAN,
    payload: planData
  })
}


export const getAllPlans = (data) => async (dispatch, getState, api) => {
  const results = await api
    .post(`/paymentbilling/planList`, data, {
      headers: authHeader(),
    })
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: GET_ALL_PLAN,
          payload: res.data.data.reverse(),
        });
      }
      return res;
    });
  return results;
};

export const getClassGroupStudent = (data) =>async (dispatch, getState, api) => {
  const results = await api
    .post(`/scheduling/getClassGroupStudentList`, data, {
      headers: authHeader(),
    })
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: GET_ALL_GROUP_STUDENT,
          payload: res.data.data,
        });
      }
      return res;
    });
  return results;
}

export const getAllInstrument = (userId) => async (dispatch, getState, api) => {
  const results = await api
    .post(`/genre/getgenre`, userId, {
      headers: authHeader(),
    })
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: GET_ALL_INSTRUMENT,
          payload: res.data.data.reverse(),
        });
      }
      return res;
    });
  return results;
};

export const getInstrumentTeacher = (instrumentId) => async (dispatch, getState, api) => {
  const results = await api
    .post(`/paymentbilling/instrumentTeacher`, { instrumentId }, { headers: authHeader() })
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: GET_INSTRUMENT_TEACHER,
          payload: res.data.data.reverse(),
        });
      }
      return res;
    });
  return results;
};


export const getAllCardDetails = (studentId) => async (dispatch, getState, api) => {
  const results = await api
    .post(`/studentBilling/cardList`, { studentId }, { headers: authHeader() })
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: GET_ALL_CARD,
          payload: res.data.data.reverse(),
        });
      }
      return res;
    });
  return results;
};

export const addCardDetail = (payload) => async (dispatch, getState, api) => {
  const results = await api
    .post(`/studentBilling/addToCard`, payload, {
      headers: authHeader(),
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
  return results;
};

export const getInvoicePay = (payload) => async (dispatch, getState, api) => {
  const results = await api
    .get(`/invoice/${payload}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: SELECT_INVOICE_PAY,
          payload: res.data.data,
        });
      }
      return res;
    })
    .catch((err) => {
      return err.response;
    });
  return results;
};

export const addInvoicePay = (payload) => async (dispatch, getState, api) => {
  const results = await api
    .post(`/invoice/payment`, payload)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
  return results;
};

export const removeCardDetails = (cardId) => async (dispatch, getState, api) => {
  const data = { cardId }
  const results = await api.delete(`/studentBilling/removeCard`, { data, headers: authHeader() }).then(results => {
    return results
  })
  return results
}

export const getPlanById = (planId) => async (dispatch, getState, api) => {
  const results = await api
    .post(`/studentBilling/getPlanById`, { planId }, {
      headers: authHeader(),
    })
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: SELECT_PLAN,
          payload: res.data.data,
        });
      }
      return res;
    })
    .catch((err) => {
      return err.response;
    });
  return results;
};


export const buyPlanByStudent = (paylaod) => async (dispatch, getState, api) => {
  const results = await api
    .post(`/studentBilling/payments`, paylaod, {
      headers: authHeader(),
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
  return results;
};


export const getPlanSubscribe = () => async (dispatch, getState, api) => {
  const results = await api
    .get(`/studentBilling/mySubscription`, {
      headers: authHeader(),
    })
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: GET_SUBSCRIBE_PLAN,
          payload: res.data.data.reverse(),
        });
      }
      return res;
    });
  return results;
};


export const approveRejectPlan = (data) => async (dispatch, getState, api) => {
  const results = await api
    .post(`/studentBilling/approvePlanByAdmin`, data, {
      headers: authHeader(),
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
  return results;
};

export const getPaymentMethods = (id) => async (dispatch, getState, api) => {
  const results = await api
    .get(`/paymentMethod/getPaymentMethodList/${id}`, {
      headers: authHeader(),
    })
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: GET_PAYMENT_METHODS,
          payload: res.data.data,
        });
      }
      return res;
    });
  return results;
};

export const addCardAllDetails = (payload) => async (dispatch, getState, api) => {
  const results = await api
    .post(`/studentBilling/addCustomerPayment`, payload, {
      headers: authHeader(),
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
  return results;
};

export const updateCardAllDetails = (payload) => async (dispatch, getState, api) => {
  const results = await api
    .post(`/studentBilling/updateCustomerPaymentProfile`, payload, {
      headers: authHeader(),
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
  return results;
};

export const getPaymentDataDetails = (id,adminId) => async (dispatch, getState, api) => {
  const results = await api
    .get(`/studentBilling/getCustomerPaymentProfile/${adminId}/${id}`, {
      headers: authHeader(),
    })
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: GET_PAYMENT_DATA_DETAILS,
          payload: res.data.data,
        });
      }
      return res;
    });
  return results;
};

export const getWidgetData = (data) => async (dispatch, getState, api) => {
  const results = await api
    .post('/widget/getActiveWidget', data, {
      headers: authHeader(),
    })
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: GET_WIDGET_DATA,
          payload: res.data.data,
        });
      }
      return res;
    });
  return results; 
}

export const deletePaymentCard = (data,adminId) => async (dispatch, getState, api) => {
  console.log("oieurouoioooii", data)
  // const data = { data };
  const results = await api
    .delete("/studentBilling/delCustomerPaymentProfile", {
      data,
      headers: authHeader(),
    })
    .then((res) => {
      dispatch(getPaymentDataDetails(data?.customerProfileId,adminId))
      return res;
    })
    .catch((err) => {
      return err.response;
    });
  return results;
};

export const cancelPayment = (data) => async (dispatch, getState, api) => {
  const results = await api
    .post(`/studentBilling/cancelBookPlan`, data, {
      headers: authHeader(),
    })
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: CANCEL_PAYMENT,
          payload: res.data.data,
        });
      }
      return res;
    });
  return results;
};

export const registerStudent = (data) => async (dispatch, getState, api) => {
  const results = await api
  .post('/student/studentSignupWidget/add', data).then(res=>{
    return res
  }).catch(err=>{
    return err.response
  })
  return results;
}