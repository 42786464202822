import { GET_ALL_STORE_ITEM, GET_STORE_ID, EDIT_STORE_ITEM, ADD_STORE_ITEM_INTO_CART, REMOVE_STORE_ITEM_FROM_CART, INCREMENT_STORE_QUENTITY, DECREMENT_STORE_QUENTITY, GET_DEFAUKT_QUANTITY, UPDATE_STORE_QUANTITY, VIEW_STORE_ITEM_FROM_CART, GET_ORDER_LIST, VIEW_SINGLE_STORE_PRODUCT, SEARCH_STORE_ITEM, DOWNLOAD_INVOICE, ORDER_DETAILS, VENDOR_ORDER_DETAILS, DELIVERY_ADDRESS_DETAILS, ADD_SELLER_INFORMATION, GET_SELLER_INFORMATION, UPDATE_SELLER_INFORMATION, ADD_STORE_ITEM_IN_LIST, DOWNLOAD_VENDOR_INVOICE, GET_STORE_ITEM_LIST, GROUP_EMAIL_RES } from '../actionTypes';

const initialState = {
    stores: [],
    storeId: null,
    isEdit: false,
    editItem: {},
    currentPage: 1,
    limit: 10,
    item_name: '',
    totalPages: null,
    totalResults: null,
    isCart: false,
    store_cart: [],
    categories:[],
    cart_subTotal: null,
    cart_grandTotal: null,
    cart_taxApplicable: 0,
    order_information: null,
    quantity: {},
    viewSingleStoreItem: null,
    allOrderList: [],
    invoieData: null,
    vendorInvoiceData: null,
    order_details: null,
    vendor_order_details: null,
    delivery_address: null,
    sellerInformationAdd: null,
    sellerInformationGet: null,
    sellerInformationUpdate: null,
    storeItemInList: [],
    stockList: [],
    groupEmail: {}
}


export default function storesReducer(state = initialState, action) {
    switch (action.type) {

        case GET_ALL_STORE_ITEM:
            return {
                ...state,
                categories: action.payload.categories,
                stores: action.payload.results,
                currentPage: action.payload.page,
                item_name: action.payload.item_name,
                totalPages: action.payload.totalPages,
                totalResults: action.payload.totalResults
            }

        case SEARCH_STORE_ITEM:
            return {
                ...state,
                stores: action.payload,
            }

        case GET_ORDER_LIST:
            return {
                ...state,
                allOrderList: action.payload.results,
                currentPage: action.payload.page,
                totalPages: action.payload.totalPages,
                totalResults: action.payload.totalResults
            }

        case VIEW_SINGLE_STORE_PRODUCT:
            return {
                ...state,
                viewSingleStoreItem: action.payload
            }


        case GET_STORE_ID:
            return {
                ...state,
                storeId: action.id
            }

        case EDIT_STORE_ITEM:
            return {
                ...state,
                editItem: action.payload.item,
                isEdit: action.payload.isEdit,
            }

        case ADD_STORE_ITEM_INTO_CART:
            var doesItemExist = false;
            const newState = state.store_cart.map((item) => {
                if (item._id === action.store_item._id) {
                    item.selected_quentity = action.store_item.selected_quentity
                    doesItemExist = true;
                    // return item
                }
                return item;
            });
            if (doesItemExist) {
                return {
                    ...state,
                    store_cart: newState
                };
            }
            return {
                ...state,
                store_cart: [...state.store_cart, action.store_item]
            }

        case VIEW_STORE_ITEM_FROM_CART:
            return {
                ...state,
                store_cart: action.cart_item ? action.cart_item : [],
                order_information: action.cart_item?.userId,
                cart_subTotal: action.cart_item?.subTotal,
                cart_grandTotal: action.cart_item?.grandTotal,
                cart_taxApplicable: action.cart_item?.taxApplicable,
                isCart: action.isCart
            }

        case REMOVE_STORE_ITEM_FROM_CART:
            return {
                ...state
            }

        case GET_DEFAUKT_QUANTITY:
            return {
                ...state,
                quantity: action.store_quantity
            }

        case UPDATE_STORE_QUANTITY:
            return {
                ...state,
                quantity: { ...state.quantity, [action.id]: state.quantity[action.id] + action.quantity_number }
            }

        case INCREMENT_STORE_QUENTITY:
            return {
                ...state,
                quantity: { ...state.quantity, [action.id]: state.quantity[action.id] + 1 }
            }

        case DECREMENT_STORE_QUENTITY:
            return {
                ...state,
                quantity: { ...state.quantity, [action.id]: state.quantity[action.id] - 1 }
            }

        case DOWNLOAD_INVOICE:
            return {
                ...state,
                invoieData: action.payload,
            }

        case DOWNLOAD_VENDOR_INVOICE:
            return {
                ...state,
                vendorInvoiceData: action.payload
            }

        case ORDER_DETAILS:
            return {
                ...state,
                order_details: action.payload,
            }

        case VENDOR_ORDER_DETAILS:
            return {
                ...state,
                vendor_order_details: action.payload,
            }

        case DELIVERY_ADDRESS_DETAILS:
            return {
                ...state,
                delivery_address: action.payload,
            }

        case ADD_SELLER_INFORMATION:
            return {
                ...state,
                sellerInformationAdd: action.payload
            }

        case GET_SELLER_INFORMATION:
            return {
                ...state,
                sellerInformationGet: action.payload?.results
            }

        case UPDATE_SELLER_INFORMATION:
            return {
                ...state,
                sellerInformationUpdate: action.payload?.results
            }

        case ADD_STORE_ITEM_IN_LIST:
            return {
                ...state,
                storeItemInList: action.payload
            }

        case GET_STORE_ITEM_LIST:
            return {
                ...state,
                stockList: action.payload.results,
                currentPage: action.payload.page,
                totalPages: action.payload.totalPages,
                totalResults: action.payload.totalResults,
                limit: action.payload.limit
            }

        case GROUP_EMAIL_RES:
            return {
                ...state,
                groupEmail: action.payload
            }

        default: return state
    }
}