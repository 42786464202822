import { EDIT_ROOM_DATA } from '../actionTypes';

const initialState = {
    editRoomState:null
}

export default function settingReducer(state = initialState, action) {
    switch (action.type) {

        case EDIT_ROOM_DATA:
            return {
                ...state,
                editRoomState: action.payload,
            }

        default: return state
    }
}