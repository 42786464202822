import {GET_COMMUNICATION_LOG} from '../actionTypes'

const initialState = {
    communicationInfoLog: [],
    // pagination
    page: 1,
    limit: 10,
    totalPages: 0,
    totalResults: 0
}

export default function communicationReducer(state = initialState, action) {
    switch (action.type) {
        case GET_COMMUNICATION_LOG:
            return{
                ...state,
                communicationInfoLog: action.payload.results,
                page:action.payload.page,
                limit: action.payload.limit,
                totalPages: action.payload.totalPages,
                totalResults: action.payload.totalResults
            }
        default: return state
    }
}