import { lazy } from "react";

// import etry from '../hooks/Retry';

const Dashboard = lazy(() => import("../component/dashboard"));
const Member = lazy(() => import("../component/member"));
const Learning = lazy(() => import("../component/learning"));
const Reporting = lazy(() => import("../component/reporting"));
const MyBizNew = lazy(() => import("../component/buildMyBiz"));
const Quiz = lazy(() => import("../component/quizzes"));
const QuizCategoryStudent = lazy(() => import("../component/quizzes/QuizCategoryStudent"));
const FavouritePage = lazy(() => import("../component/Favourite"));
const EarTrainer = lazy(() => import("../component/musicalInteractives/earTrainer"))
const StudentEarTrainerQuiz = lazy(() => import("../component/musicalInteractives/studentEarTrainerQuiz"))
const Scheduling = lazy(() => import("../component/scheduling"));
const StudentBilling = lazy(() => import("../component/studentBilling"));
const Support = lazy(() => import("../component/helpSection/support"));
const HelpContent = lazy(() => import("../component/helpSection/HelpContent"));

// members
const SubAdmin = lazy(() => import("../component/member/subAdmin"));
const ViewProfile = lazy(() => import("../component/member/viewProfile"));

const Teachers = lazy(() => import("../component/member/teachers"));
const Student = lazy(() => import("../component/member/student"));
const Parent = lazy(() => import("../component/member/parent"));
const Email = lazy(() => import("../component/GroupEmail"));
const Children = lazy(() => import("../component/member/children"));
const Group = lazy(() => import("../component/member/group"));
const ClassesGroupDetails = lazy(() =>
  import("../component/member/classesGroupDetails")
);
const ViewAllSchool = lazy(() => import("../component/member/school"));
const PrivateTeacher = lazy(() => import("../component/member/privateTeacher"));
const ViewSchoolDetails = lazy(() =>
  import("../component/member/school/ViewSchoolDetails")
);
const ViewPrivateTeacherDetails = lazy(() =>
  import("../component/member/privateTeacher/ViewPrivateTeacherDetails")
);

// learning
const Courses = lazy(() => import("../component/learning/courses"));
const CourseDetail = lazy(() => import("../component/learning/course_detail"));
const Practice = lazy(() => import("../component/learning/practice"));
const ApproveCourses = lazy(() =>
  import("../component/learning/approveCourses")
);
const Lessons = lazy(() => import("../component/learning/lessons"));
const LessonDetails = lazy(() => import("../component/learning/lesson_detail"));
const Library = lazy(() => import("../component/learning/library"));
const AssignPractice = lazy(() =>
  import("../component/learning/practice/assignPractice")
);
const ViewPractice = lazy(() =>
  import("../component/learning/practice/ViewPractice")
);

const LearningSuperAdmin = lazy(() => import("../component/learning/learning"));

// scheduling
const WeeklyScheduling = lazy(() =>
  import("../component/scheduling/weeklyScheduling")
);
// const DailyScheduling = lazy(() =>  import('../component/scheduling/daily'));
const DailyScheduling = lazy(() =>
  import("../component/scheduling/dailyScheduling")
);
const MonthlyScheduling = lazy(() =>
  import("../component/scheduling/monthlyScheduling")
);

// reporting
const AttendanceReporting = lazy(() =>
  import("../component/reporting/attendance")
);
const PaymentReporting = lazy(() => import("../component/reporting/payment"));
const PaymentDetails = lazy(() => import("../component/reporting/PaymentDetails"));
const StudentReporting = lazy(() => import("../component/reporting/student"));
// const SchollReporting = lazy(() =>  import('../component/reporting/schoolReport'));
const PayrollReporting = lazy(() => import("../component/reporting/payroll"));
const AproovedPayroll = lazy(() => import("../component/reporting/payroll/AproovedPayroll.jsx"));
const PayrollReportGenerate = lazy(() =>
  import("../component/reporting/payrollReport")
);
const CommunicationLogReporting = lazy(() =>
  import("../component/reporting/communication-log")
);
const StudentPractice = lazy(() =>
  import("../component/reporting/studentPractice")
);

// StudentBilling
const ApprovePlan = lazy(() =>
  import("../component/studentBilling/approvePlan")
);
const GroupPlans = lazy(() => import("../component/studentBilling/groupPlans"));
const LoginWidget = lazy(() =>
  import("../component/studentBilling/loginWidget")
);
const StudentWidget = lazy(()=> import('../component/studentBilling/StudentWidget'))
const ApproveStudent = lazy(()=> import('../component/reporting/ApproveStudent'))
const newInvoice = lazy(() => import("../component/studentBilling/newInvoice"));
const invoiceList = lazy(() => import("../component/studentBilling/invoiceList"));
const recuringInvoice = lazy(() => import("../component/studentBilling/recuringList"));
const RegisterWidget = lazy(() =>
  import("../component/studentBilling/registerWidgets")
);
const PlanWidget = lazy(() => import("../component/studentBilling/planWidget"));
const PlanBuilder = lazy(() => import("../component/studentBilling/planBuilder"));
const BookPlan = lazy(() => import("../component/studentBilling/bookPlan"));
const StoreWidget = lazy(() =>
  import("../component/studentBilling/storeWidget")
);
const StoreBuilder = lazy(() =>
  import("../component/studentBilling/storeBuilder")
);
const PaymentBilling = lazy(() =>
  import("../component/studentBilling/paymentBilling")
);

const PlanRegistration = lazy(() =>
  import("../component/studentBilling/planBuilder/planRegistration")
);

const BuyPlanDetails = lazy(() =>
  import("../component/studentBilling/paymentBilling/BuyPlanDetails.js")
);

// Build My Biz
const MyBiz = lazy(() => import("../component/buildMyBiz/BuildMyBuiz"));
const StrategiesforSuccess = lazy(() => import("../component/buildMyBiz/StrategiesforSuccess/index"));
const ViewDetailsBuildMYBiz = lazy(() =>
  import("../component/buildMyBiz/ViewDetailsBuildMYBiz")
);
const Store = lazy(() => import("../component/store"));
const ProductListing = lazy(() => import("../component/store/Product"));
const ProductDetail = lazy(() => import("../component/store/ProductDetails"));
const OrderListing = lazy(() => import("../component/store/orderListing"));
const Stock = lazy(() => import("../component/store/Stocks"));
const Seller = lazy(() => import("../component/store/seller"));
const CheckOut = lazy(() => import("../component/store/CheckOut"));
const OrderDetails = lazy(() => import("../component/store/orderDetails"));
const LearnMoreContant = lazy(() => import("../component/dashboard/learnMoreContant"));

// Super Admin : Reporting
const PaymentSchoolReporting = lazy(() =>
  import("../component/reporting/Payment-Scholl-Report")
);
// const SchoolReporting = lazy(() =>  import('../component/reporting/SchoolReport'));

// Profile
const UserProfile = lazy(() => import("../component/profile"));
const NotificationComponent = lazy(() =>
  import("../layout/header/notificationComponent")
);



// view all activities

const ViewAllActivities = lazy(() => import("../component/dashboard/viewAllActivities"));

// full calendar
// const FullCalendarScheduling = lazy(() =>  import('../component/scheduling/weeklyScheduling'));

export const SuperAdminRoute = [
  { path: "/dashboard", Component: Dashboard, exact: true, title: "Dashboard" },
  { path: "/quizzes", Component: Quiz, exact: true, title: "Quiz" },
  { path: "/musicalInteractives/earTrainer", Component: EarTrainer, exact: true, title: "Ear Trainer" },
  { path: "/members", Component: Member, title: "Members" },
  // { path: "/member/:slug/:id", Component: ViewProfile, title:'Profiles' },
  { path: "/member/list-school", Component: ViewAllSchool, title: "Schools" },
  {
    path: "/member/private-teacher",
    Component: PrivateTeacher,
    title: "Private Teachers",
  },
  {
    path: "/superadmin/view-school-details/:id",
    Component: ViewSchoolDetails,
    title: "Schools Details",
  },
  {
    path: "/superadmin/view-teacher-details/:id",
    Component: ViewPrivateTeacherDetails,
    title: "Private Teacher Details",
  },
  {
    path: "/learning/learning",
    Component: LearningSuperAdmin,
    title: "Learning",
  },
  { path: "/learning/library", Component: Library, title: "Library" },
  { path: "/learning/courses", Component: Courses, title: "Courses" },
  {
    path: "/learning/course_detail/:id",
    Component: CourseDetail,
    title: "Course Details",
  },
  { path: "/learning/lessons/:id", Component: Lessons, title: "Lessons" },
  {
    path: "/learning/lesson_details/:lessonID/:id",
    Component: LessonDetails,
    title: "Lesson Details",
  },

  {
    path: "/reporting/payment-details/:id",
    Component: PaymentDetails,
    title: "Payment Details",
  },

  { path: "/MyBiz/category/:id", Component: MyBiz, title: "MyBiz" },
  {
    path: "/build-my-biz/view-details/:id",
    Component: ViewDetailsBuildMYBiz,
    title: "Build My Biz Details",
  },

  { path: "/store", Component: Store, title: "Store" },
  {
    path: "/stores/order-listing/:id",
    Component: OrderListing,
    title: "Order Listing",
  },
  {
    path: "/stores/seller",
    Component: Seller,
    title: "Seller Information",
  },
  {
    path: "/stores/order-details/:id",
    Component: OrderDetails,
    title: "Order Details",
  },
  {
    path: "/stores/product-listing",
    Component: ProductListing,
    title: "Product Listing",
  },

  // { path: "/schoolbillings", Component: StudentBilling, title:'Scholl Billing' },
  {
    path: "/schoolbilling/loginwidget",
    Component: LoginWidget,
    title: "Login Widget",
  },
  {
    path: "/schoolbilling/planbuilder",
    Component: PlanBuilder,
    title: "Plan Builder",
  },
  {
    path: "/schoolbilling/bookplan",
    Component: BookPlan,
    title: "Book Plan",
  },
  {
    path: "/schoolbilling/planwidget",
    Component: PlanWidget,
    title: "Plan Widget",
  },
  {
    path: "/paymentBilling",
    Component: PaymentBilling,
    title: "Payment Billing",
  },

  {
    path: "/plan-registration/:id",
    Component: PlanRegistration,
    title: "Plan Reg",
  },

  { path: "/reportings", Component: Reporting, title: "Reporting" },
  {
    path: "/reports/:slug",
    Component: PaymentSchoolReporting,
    title: "Reports",
  },
  // { path: "/reports/school-reporting", Component: SchoolReporting, title:'School Reporting' },

  { path: "/user/:slug/:id", Component: UserProfile, title: "User Profile" },
  {
    path: "/notification",
    Component: NotificationComponent,
    title: "Notification",
  },
  {
    path: "/support",
    Component: Support,
    title: "Help",
  },
  {
    path: "/help",
    Component: HelpContent,
    title: "Help Content",
  }

];

export const AdminRoute = [
  { path: "/dashboard", Component: Dashboard, exact: true, title: "Dashboard" },
  { path: "/members", Component: Member, title: "Members" },
  { path: "/member/sub-admins", Component: SubAdmin, title: "Sub Admin" },
  { path: "/member/teachers", Component: Teachers, title: "Teachers" },
  { path: "/member/:slug/:id", Component: ViewProfile, title: "Profiles" },
  { path: "/member/students", Component: Student, title: "Students" },
  { path: "/member/parents", Component: Parent, title: "Parents" },
  { path: "/email", Component: Email, title: "email" },
  { path: "/family/:slug", Component: Parent, title: "Parents" },
  { path: "/member/classes-group", Component: Group, title: "Classes/Group" },
  { path: "/recentActivities/viewAllActivities", Component: ViewAllActivities, title: "View All" },
  {
    path: "/classes/group/:id",
    Component: ClassesGroupDetails,
    title: "Classes/Group Details",
  },
  // { path: "/member/school", Component: School, title:'Schools' },
  {
    path: "/member/private-teacher",
    Component: PrivateTeacher,
    title: "Private Teachers",
  },
  {
    path: "/learning/learning",
    Component: LearningSuperAdmin,
    title: "Learning Super Admin",
  },
  { path: "/learnings", Component: Learning, title: "Learnings" },
  {
    path: "/learning/approve-course",
    Component: ApproveCourses,
    title: "Approve Courses",
  },
  { path: "/learning/lessons/:id", Component: Lessons, title: "Lessons" },
  {
    path: "/learning/lesson_details/:lessonID/:id",
    Component: LessonDetails,
    title: "Lesson Details",
  },
  { path: "/learning/library", Component: Library, title: "Library" },
  { path: "/learning/courses", Component: Courses, title: "Courses" },
  {
    path: "/learning/course_detail/:id",
    Component: CourseDetail,
    title: "Course Details",
  },
  { path: "/learning/practice", Component: Practice, title: "Practice" },
  { path: "/reportings", Component: Reporting, title: "Reporting" },
  { path: "/MyBizNew", Component: MyBizNew, title: "MyBizNew" },

  {
    path: "/reporting/attendance",
    Component: AttendanceReporting,
    title: "Attendance Reporting",
  },
  {
    path: "/reporting/payments",
    Component: PaymentReporting,
    title: "Payment Reporting",
  },
  {
    path: "/reporting/payment-details/:id",
    Component: PaymentDetails,
    title: "Payment Details",
  },
  {
    path: "/reporting/student",
    Component: StudentReporting,
    title: "Student Reporting",
  },
  {
    path: "/reporting/payrolls",
    Component: PayrollReporting,
    title: "Payroll Reporting",
  },
  {
    path: "/reporting/aproovedPayrolls",
    Component: AproovedPayroll,
    title: "Approved Payroll",
  },
  {
    path: "/reporting/approvedStudent",
    Component: ApproveStudent,
    title: "Approved Student",
  },
  {
    path: "/reporting/payrollReport",
    Component: PayrollReportGenerate,
    title: "Payroll Report Generate",
  },
  {
    path: "/reporting/communication-log",
    Component: CommunicationLogReporting,
    title: "Communication-Log Reporting",
  },
  {
    path: "/category/:id",
    Component: StrategiesforSuccess,
    title: "Strategies for success",
  },
  { path: "/schedulings", Component: Scheduling, title: "Schedulings" },
  {
    path: "/scheduling/weekly",
    Component: WeeklyScheduling,
    title: "Weekly Scheduling",
  },
  {
    path: "/scheduling/daily",
    Component: DailyScheduling,
    title: "Daily Scheduling",
  },
  // { path: "/scheduling/monthly", Component: MonthlyScheduling, title:'Monthly Scheduling' },

  // { path: "/scheduling/fullCalendar", Component: FullCalendarScheduling, title:'Full Calendar Scheduling' },

  {
    path: "/studentbillings",
    Component: StudentBilling,
    title: "Student Billing",
  },
  {
    path: "/studentbilling/approveplan",
    Component: ApprovePlan,
    title: "Approve Plan",
  },
  {
    path: "/studentbilling/groupplans",
    Component: GroupPlans,
    title: "Group Plans",
  },
  {
    path: "/studentbilling/loginwidget",
    Component: LoginWidget,
    title: "Login Widget",
  },
  {
    path: "/studentbilling/studentWidget",
    Component: StudentWidget,
    title: "Student Widget",
  },
  {
    path: "/studentbilling/newInvoice",
    Component: newInvoice,
    title: "New Invoice",
  },
  {
    path: "/studentbilling/invoiceList",
    Component: invoiceList,
    title: "Invoice List",
  },
  {
    path: "/studentbilling/recuringInvoice",
    Component: recuringInvoice,
    title: "Recurring Invoices",
  },
  {
    path: "/studentbilling/registerwidget",
    Component: RegisterWidget,
    title: "Register Widget",
  },
  {
    path: "/studentbilling/planbuilder",
    Component: PlanBuilder,
    title: "Plan Builder",
  },
  {
    path: "/studentbilling/planwidget",
    Component: PlanWidget,
    title: "Plan Widget",
  },
  {
    path: "/studentbilling/storebuilder",
    Component: StoreBuilder,
    title: "Store Builder",
  },
  {
    path: "/paymentBilling",
    Component: PaymentBilling,
    title: "Payment Billing",
  },
  {
    path: "/studentbilling/storewidget",
    Component: StoreWidget,
    title: "Store Widget",
  },
  // { path: "/MyBiz/category/:id", Component: MyBiz, title: "MyBiz" },

  { path: "/store", Component: Store, title: "Store" },
  {
    path: "/stores/order-details/:id",
    Component: CheckOut,
    title: "Order Detail",
  },
  {
    path: "/stores/product-listing",
    Component: ProductListing,
    title: "Product Listing",
  },
  {
    path: "/stores/product-details/:id",
    Component: ProductDetail,
    title: "View Product",
  },
  {
    path: "/stores/stocks",
    Component: Stock,
    title: "Inventory"
  },

  {
    path: "/learning/view-practice/:id",
    Component: ViewPractice,
    title: "View Practice",
  },
  {
    path: "/reports/:slug",
    Component: PaymentSchoolReporting,
    title: "Report",
  },

  { path: "/user/:slug/:id", Component: UserProfile, title: "User Profile" },

  {
    path: "/plan-registration/:id",
    Component: PlanRegistration,
    title: "Plan Registration",
  },
  {
    path: "/notification",
    Component: NotificationComponent,
    title: "Notification",
  },
  {
    path: "/support",
    Component: Support,
    title: "Help",
  },
  {
    path: "/help",
    Component: HelpContent,
    title: "Help Content",
  }
];

export const SubAdminRoute = [
  { path: "/dashboard", Component: Dashboard, exact: true, title: "Dashboard" },
  { path: "/members", Component: Member, title: "Members" },
  { path: "/member/teachers", Component: Teachers, title: "Teachers" },
  { path: "/member/:slug/:id", Component: ViewProfile, title: "Profiles" },
  { path: "/member/students", Component: Student, title: "Students" },
  { path: "/member/parents", Component: Parent, title: "Parent" },
  { path: "/family/:slug", Component: Parent, title: "Parents" },
  { path: "/member/classes-group", Component: Group, title: "Classes/Group" },
  { path: "/recentActivities/viewAllActivities", Component: ViewAllActivities, title: "View All" },
  { path: "/email", Component: Email, title: "email" },
  {
    path: "/classes/group/:id",
    Component: ClassesGroupDetails,
    title: "Classes/Group Details",
  },
  {
    path: "/learning/learning",
    Component: LearningSuperAdmin,
    title: "Learning Super Admin",
  },
  { path: "/learnings", Component: Learning, title: "Learnings" },
  {
    path: "/learning/approve-course",
    Component: ApproveCourses,
    title: "Approve Courses",
  },
  { path: "/learning/lessons/:id", Component: Lessons, title: "Lessons" },
  {
    path: "/learning/lesson_details/:lessonID/:id",
    Component: LessonDetails,
    title: "Lesson Details",
  },
  { path: "/learning/library", Component: Library, title: "Library" },
  { path: "/learning/courses", Component: Courses, title: "Courses" },
  {
    path: "/learning/course_detail/:id",
    Component: CourseDetail,
    title: "Course Details",
  },
  { path: "/learning/practice", Component: Practice, title: "Practice" },
  { path: "/reportings", Component: Reporting, title: "Reporting" },
  { path: "/MyBizNew", Component: MyBizNew, title: "MyBizNew" },
  {
    path: "/reporting/attendance",
    Component: AttendanceReporting,
    title: "Attendance Reporting",
  },
  {
    path: "/reporting/payments",
    Component: PaymentReporting,
    title: "Payment Reporting",
  },
  {
    path: "/reporting/student",
    Component: StudentReporting,
    title: "Student Reporting",
  },
  {
    path: "/reporting/communication-log",
    Component: CommunicationLogReporting,
    title: "Communication-Log Reporting",
  },
  {
    path: "/category/:id",
    Component: StrategiesforSuccess,
    title: "Strategies for success",
  },
  { path: "/schedulings", Component: Scheduling, title: "Schedulings" },
  {
    path: "/scheduling/weekly",
    Component: WeeklyScheduling,
    title: "Weekly Scheduling",
  },
  {
    path: "/scheduling/daily",
    Component: DailyScheduling,
    title: "Daily Scheduling",
  },
  {
    path: "/scheduling/monthly",
    Component: MonthlyScheduling,
    title: "Monthly Scheduling",
  },
  {
    path: "/studentbillings",
    Component: StudentBilling,
    title: "Student Billing",
  },
  {
    path: "/studentbilling/loginwidget",
    Component: LoginWidget,
    title: "Login Widget",
  },
  {
    path: "/studentbilling/studentWidget",
    Component: StudentWidget,
    title: "Student Widget",
  },
  {
    path: "/studentbilling/newInvoice",
    Component: newInvoice,
    title: "New Invoice",
  },
  {
    path: "/studentbilling/invoiceList",
    Component: invoiceList,
    title: "Invoice List",
  },
  {
    path: "/studentbilling/recuringInvoice",
    Component: recuringInvoice,
    title: "Recurring Invoices",
  },
  {
    path: "/studentbilling/registerwidget",
    Component: RegisterWidget,
    title: "Register Widget",
  },
  {
    path: "/studentbilling/planbuilder",
    Component: PlanBuilder,
    title: "Plan Builder",
  },
  {
    path: "/studentbilling/planwidget",
    Component: PlanWidget,
    title: "Plan Widget",
  },
  {
    path: "/reports/:slug",
    Component: PaymentSchoolReporting,
    title: "Report",
  },
  { path: "/store", Component: Store, title: "Store" },
  {
    path: "/stores/order-details/:id",
    Component: CheckOut,
    title: "Order Detail",
  },
  {
    path: "/stores/product-listing",
    Component: ProductListing,
    title: "Product Listing",
  },
  {
    path: "/stores/product-details/:id",
    Component: ProductDetail,
    title: "View Product",
  },

  { path: "/user/:slug/:id", Component: UserProfile, title: "User Profile" },

  // { path: "/MyBiz/category/:id", Component: MyBiz, title: "MyBiz" },

  {
    path: "/plan-registration/:id",
    Component: PlanRegistration,
    title: "Plan Registration",
  },
  {
    path: "/notification",
    Component: NotificationComponent,
    title: "Notification",
  },
  {
    path: "/support",
    Component: Support,
    title: "Help",
  },
  {
    path: "/help",
    Component: HelpContent,
    title: "Help Content",
  },
  {
    path: "/reporting/payrolls",
    Component: PayrollReporting,
    title: "Payroll Reporting",
  },
  {
    path: "/reporting/aproovedPayrolls",
    Component: AproovedPayroll,
    title: "Approved Payroll",
  },
  {
    path: "/reporting/payrollReport",
    Component: PayrollReportGenerate,
    title: "Payroll Report Generate",
  },
  {
    path: "/reporting/approvedStudent",
    Component: ApproveStudent,
    title: "Approved Student",
  },
];

export const TeacherRoute = [
  { path: "/dashboard", Component: Dashboard, exact: true, title: "Dashboard" },
  { path: "/members", Component: Member, title: "Members" },
  { path: "/member/:slug/:id", Component: ViewProfile, title: "Profiles" },
  { path: "/member/students", Component: Student, title: "Students" },
  { path: "/member/parents", Component: Parent, title: "Parent" },
  { path: "/member/classes-group", Component: Group, title: "Classes/Group" },
  {
    path: "/classes/group/:id",
    Component: ClassesGroupDetails,
    title: "Classes/Group Details",
  },
  { path: "/learnings", Component: Learning, title: "Learnings" },
  { path: "/learning/lessons/:id", Component: Lessons, title: "Lessons" },
  {
    path: "/learning/lesson_details/:lessonID/:id",
    Component: LessonDetails,
    title: "Lesson Details",
  },
  { path: "/learning/library", Component: Library, title: "Librarys" },
  { path: "/learning/courses", Component: Courses, title: "Courses" },
  {
    path: "/learning/course_detail/:id",
    Component: CourseDetail,
    title: "Course Details",
  },
  { path: "/learning/practice", Component: Practice, title: "Practice" },
  { path: "/reportings", Component: Reporting, title: "Reporting" },
  { path: "/MyBizNew", Component: MyBizNew, title: "MyBizNew" },
  {
    path: "/reporting/attendance",
    Component: AttendanceReporting,
    title: "Attendance Reporting",
  },
  {
    path: "/reporting/payments",
    Component: PaymentReporting,
    title: "Payment Reporting",
  },
  {
    path: "/reporting/student",
    Component: StudentReporting,
    title: "Student Reporting",
  },
  {
    path: "/reports/:slug",
    Component: PaymentSchoolReporting,
    title: "Report",
  },

  {
    path: "/reporting/communication-log",
    Component: CommunicationLogReporting,
    title: "Communication-Log Reporting",
  },
  {
    path: "/category/:id",
    Component: StrategiesforSuccess,
    title: "Strategies for success",
  },
  { path: "/schedulings", Component: Scheduling, title: "Schedulings" },
  {
    path: "/scheduling/weekly",
    Component: WeeklyScheduling,
    title: "Weekly Scheduling",
  },
  {
    path: "/scheduling/daily",
    Component: DailyScheduling,
    title: "Daily Scheduling",
  },
  {
    path: "/scheduling/monthly",
    Component: MonthlyScheduling,
    title: "Monthly Scheduling",
  },
  {
    path: "/studentbillings",
    Component: StudentBilling,
    title: "Student Billing",
  },
  // {
  //   path: "/studentbilling/loginwidget",
  //   Component: LoginWidget,
  //   title: "Login Widget",
  // },
  // {
  //   path: "/studentbilling/registerwidget",
  //   Component: RegisterWidget,
  //   title: "Register Widget",
  // },
  // {
  //   path: "/studentbilling/planbuilder",
  //   Component: PlanBuilder,
  //   title: "Plan Builder",
  // },
  // {
  //   path: "/studentbilling/planwidget",
  //   Component: PlanWidget,
  //   title: "Plan Widget",
  // },

  { path: "/store", Component: Store, title: "Store" },
  {
    path: "/stores/order-details/:id",
    Component: CheckOut,
    title: "Order Detail",
  },
  {
    path: "/stores/product-listing",
    Component: ProductListing,
    title: "Product Listing",
  },
  {
    path: "/stores/product-details/:id",
    Component: ProductDetail,
    title: "View Product",
  },

  {
    path: "/learning/assign-practice",
    Component: AssignPractice,
    title: "Assign Practice",
  },
  {
    path: "/learning/assign-practice-update/:practiceAssign_Id",
    Component: AssignPractice,
    title: "Update Assign Practice",
  },
  {
    path: "/learning/view-practice/:id",
    Component: ViewPractice,
    title: "View Practice",
  },
  // { path: "/user/profile/:id", Component: UserProfile,title:'User Profile' },

  // { path: "/MyBiz/category/:id", Component: MyBiz, title: "MyBiz" },

  { path: "/user/:slug/:id", Component: UserProfile, title: "User Profile" },

  {
    path: "/plan-registration/:id",
    Component: PlanRegistration,
    title: "Plan Registration",
  },
  {
    path: "/notification",
    Component: NotificationComponent,
    title: "Notification",
  },
  {
    path: "/support",
    Component: Support,
    title: "Help",
  },
  {
    path: "/help",
    Component: HelpContent,
    title: "Help Content",
  }
];

export const StudentRoute = [
  { path: "/dashboard", Component: Dashboard, exact: true, title: "Dashboard" },
  { path: "/learnings", Component: Learning, title: "Learnings" },
  { path: "/quizCategories", Component: QuizCategoryStudent, title: "Quiz categories" },
  { path: '/favourite', Component: FavouritePage, title: "Favourite" },
  // { path: "/categories/categoryWiseQuiz", Component: CategoryWiseQuiz, title: "category wise quiz" },
  // { path: "/ategories/categoryWiseQuiz/:id", Component: QuizCategoryStudent, title: "category wise quiz" },
  { path: "/earTrainerQuiz", Component: StudentEarTrainerQuiz, title: "Ear Trainer" },
  { path: "/learning/lessons/:id", Component: Lessons, title: "Lessons" },
  {
    path: "/learning/lesson_details/:lessonID/:id",
    Component: LessonDetails,
    title: "Lesson Details",
  },
  { path: "/learning/library", Component: Library, title: "Librarys" },
  { path: "/learning/courses", Component: Courses, title: "Courses" },
  { path: "/learning/practice", Component: Practice, title: "Practice" },
  { path: "/member/:slug/:id", Component: ViewProfile, title: "Profiles" },
  { path: "/recentActivities/viewAllActivities", Component: ViewAllActivities, title: "View All" },
  {
    path: "/learning/course_detail/:id",
    Component: CourseDetail,
    title: "Course Details",
  },
  { path: '/learnMore', Component: LearnMoreContant, title: 'Learn More' },
  {
    path: "/learning/view-practice/:id",
    Component: ViewPractice,
    title: "View Practice",
  },
  { path: "/reportings", Component: Reporting, title: "Reporting" },
  {
    path: "/reporting/attendance",
    Component: AttendanceReporting,
    title: "Attendance Reporting",
  },
  {
    path: "/reporting/payments",
    Component: PaymentReporting,
    title: "Payment Reporting",
  },
  {
    path: "/reporting/student",
    Component: StudentReporting,
    title: "Student Reporting",
  },
  {
    path: "/reporting/communication-log",
    Component: CommunicationLogReporting,
    title: "Communication-Log Reporting",
  },
  {
    path: "/reporting/student-practice",
    Component: StudentPractice,
    title: "Student Practice",
  },
  { path: "/schedulings", Component: Scheduling, title: "Schedulings" },
  {
    path: "/scheduling/weekly",
    Component: WeeklyScheduling,
    title: "Weekly Scheduling",
  },
  {
    path: "/scheduling/monthly",
    Component: MonthlyScheduling,
    title: "Monthly Scheduling",
  },

  {
    path: "/paymentBilling",
    Component: PaymentBilling,
    title: "Payment Billing",
  },

  { path: "/buyPlanDetails", Component: BuyPlanDetails, title: "Buy Plan Details" },

  { path: "/store", Component: Store, title: "Store" },
  {
    path: "/stores/order-listing",
    Component: OrderListing,
    title: "Order Listing",
  },
  { path: "/stores/order-details", Component: CheckOut, title: "Order Detail" },
  // { path: "/user/profile/:id", Component: UserProfile,title:'User Profile' },
  { path: "/user/:slug/:id", Component: UserProfile, title: "User Profile" },

  {
    path: "/plan-registration/:id",
    Component: PlanRegistration,
    title: "Plan Registration",
  },
  {
    path: "/notification",
    Component: NotificationComponent,
    title: "Notification",
  },
  {
    path: "/support",
    Component: Support,
    title: "Help",
  },

];

export const ParentRoute = [
  {
    path: "/member/children",
    Component: Student,
    exact: true,
    title: "Members",
  },
  { path: "/reportings", Component: Reporting, title: "Reporting" },
  { path: "/member/:slug/:id", Component: ViewProfile, title: "Profiles" },
  {
    path: "/reporting/attendance",
    Component: AttendanceReporting,
    title: "Attendance Reporting",
  },
  {
    path: "/support",
    Component: Support,
    title: "Help",
  },
  {
    path: "/reporting/payments",
    Component: PaymentReporting,
    title: "Payment Reporting",
  },
  {
    path: "/reporting/student-practice",
    Component: StudentPractice,
    title: "Student Practice",
  },
  {
    path: "/reporting/student",
    Component: StudentReporting,
    title: "Student Reporting",
  },
  {
    path: "/reporting/payment-details/:id",
    Component: PaymentDetails,
    title: "Payment Details",
  },
  {
    path: "/reporting/communication-log",
    Component: CommunicationLogReporting,
    title: "Communication-Log Reporting",
  },
  {
    path: "/paymentBilling",
    Component: PaymentBilling,
    title: "Payment Billing",
  },
  // { path: "/user/profile/:id", Component: UserProfile,title:'User Profile' },
  { path: "/user/:slug/:id", Component: UserProfile, title: "User Profile" },

  {
    path: "/plan-registration/:id",
    Component: PlanRegistration,
    title: "Plan Registration",
  },
  {
    path: "/notification",
    Component: NotificationComponent,
    title: "Notification",
  },
];
