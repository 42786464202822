import { GET_RECENT_ACTIVITIES, ADD_RECENT_ACTIVITIES_FOR_STUDENTS } from '../actionTypes';

const initialState = {
    recentActivitiesData :[],
    page: null,
    limit: null,
    totalPages: null,
    totalResults: null,
    // dataDetails: null
}

export default function recentActivitiesReducer(state = initialState, action) {
    switch (action.type) {

        case GET_RECENT_ACTIVITIES:
            return {
                ...state,
                recentActivitiesData: action.payload.results,
                page: action.payload.page,
                limit: action.payload.limit,
                totalPages: action.payload.totalPages,
                totalResults: action.payload.totalResults
            }

        case ADD_RECENT_ACTIVITIES_FOR_STUDENTS: 
            return{
                ...state,
                // dataDetails: action.payload
            }

        default: return state
    }
}