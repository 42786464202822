// lib
import html2canvas from 'html2canvas';
import JSPDF from 'jspdf';


export const printToPDF = async (reportNamePrefix, printArea = 'printArea') => {
  const area = document.getElementById(printArea);
  return html2canvas(area, {
    scale: 1.5,
    logging: false,
    backgroundColor: '#FFFFFF',
  }).then(canvas => {
    const imgData = canvas.toDataURL('image/png');
    const orientation = canvas.width > canvas.height ? 'l' : 'p';
    const pdf = new JSPDF(orientation, 'px', [
      canvas.width + 20,
      canvas.height + 20,
    ]);
    const width = pdf.internal.pageSize.getWidth();
    const height = pdf.internal.pageSize.getHeight();
    pdf.setFillColor('#FFFFFF');
    pdf.rect(0, 0, width, height, 'F');
    pdf.addImage(imgData, 'JPEG', 10, 10, width - 20, height - 20, '', 'FAST');
    pdf.save(`${reportNamePrefix}_${new Date().getTime()}.pdf`);
  });
};


export const printToPDFMultiple = async (reportOwner, areas) => {
  let doc;
  let i = 0;
  await areas.reduce(async prevPromise => {
    return prevPromise.then((canvas = '') => {
      if (canvas !== '') {
        const orientation = canvas.width > canvas.height ? 'l' : 'p';
        if (!doc) {
          doc = new JSPDF(orientation, 'px', [
            canvas.height + 20,
            canvas.width + 20,
          ]);
        }
        if (i > 0) {
          doc.addPage([canvas.width + 20, canvas.height + 20], orientation)
        }
        const width = doc.internal.pageSize.getWidth();
        const height = doc.internal.pageSize.getHeight();


        doc.setFillColor(235, 235, 235);
        doc.rect(0, 0, width, height, 'F');


        doc.addImage(
          canvas.toDataURL('image/png'),
          'JPEG',
          10,
          10,
          width - 20,
          height - 20,
          '',
          'FAST',
        );
        if (i === areas.length - 1) {
          doc.save(`${reportOwner}_Assessment_Report`);
          return Promise.resolve();
        }


        i += 1;


        return html2canvas(document.getElementById(areas[i]), {
          scale: 1,
          logging: false,
          useCORS: true
        });
      }
      return Promise.resolve();
    });
  }, html2canvas(document.getElementById(areas[0]), {
    scale: 1, logging: false, useCORS: true
  }));
};