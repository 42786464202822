import Cookies from 'universal-cookie';

export const authHeader = () => {
    const cookies = new Cookies();
    const userToken = cookies.get('loginAsUser') ? cookies.get('loginAsUser') : cookies.get('amplitech')
    if (userToken) {
        return { 'Authorization': 'Bearer ' + userToken };
    } else {
        return {};
    }
}