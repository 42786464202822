import {SAVE_CONTRACTS_DETAILS, GET_CONTRACTS_DETAILS} from '../actionTypes'

const initialState = {
    cotracts:{},
    getContracts: {}
}



export default function contractsReducer(state = initialState, action) {
    console.log('reducer',action)
    console.log("stateCon---", state?.cotracts)
    switch (action.type) {

        case SAVE_CONTRACTS_DETAILS:
            console.log("state", state)
            return {
                ...state,
                cotracts: action.payload,
            }

            case GET_CONTRACTS_DETAILS:
                return {
                    ...state,
                    getContracts: action.payload
                }
    
        default: return state
    }
}