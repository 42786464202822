import ampliTeachWhite from "../assets/images/AmpliTeach_white.png";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import { useForm } from "react-hook-form";
import { resetUserPassword } from "../redux/auth/action";
import Validation from "../component/formValidation";
import SweetAlert from "sweetalert2";

const Reset = ({ history, match, resetUserPassword }) => {

  const token = match.params.id;
  const { register, handleSubmit, getValues, errors, watch } = useForm();
  const [modal, setModal] = useState(true);

  const onResetPassWordClick = (pwdValue) => {
    resetUserPassword(pwdValue, token).then((res) => {
      if (res.status === 200) {
        SweetAlert.fire({
          text: "Password Reset Successfully.",
          icon: "success",
        });
        history.push("/login");
      }
    });
  };

  return (
    <div className="fullWidth">
      <style>
        {
          "\
                // .modal.show{pointer-events:none;background-color:transparent;}\
                .modal-backdrop, .modal-content{background-color:transparent;}\
                .login_details{background: #fff; border-radius: 1rem;}\
            "
        }
      </style>
      <div className="login_page"></div>
      <Modal
        className="login_modal fade show d-block"
        isOpen={modal}
        // toggle={() => setModal(false)}
        centered
      >
        <div className="fullWidth text-center mb-5">
          <img
            src={ampliTeachWhite}
            alt="AmpliTech"
            title="AmpliTech"
            height="60"
          />
        </div>
        {/* <h1 className="text-center text-white mb-5">Welcome to Ampliteach log in</h1> */}
        {/* <p className="text-center text-white mb-5">Lorem Ipsum is simply dummy text of the printing and <br />typesetting industry. </p> */}
        <ModalBody className="p-0 mx-auto mb-0 mt-4 login_details">
          <div className="login_part">
            {/* Reset Password */}
            <div className="login_header mb-5">
              <h2>Reset Password!</h2>
              <p className="mt-0 text-left">Please enter the new password.</p>
              {/* <button type="button" className="close" data-dismiss="modal" onClick={() => setModal(false)}>
                                <img src={require("../assets/images/modal_cross_icon.svg").default} alt="Close" title="Close" height="15" width="15" />
                            </button> */}
            </div>
            <form
              className="mb-4"
              onSubmit={handleSubmit(onResetPassWordClick)}
            >
              <div className="form-group">
                <input
                  type="password"
                  className={`form-control ${!errors.newPassword && watch("newPassword")
                    ? "valid"
                    : errors.newPassword
                      ? "invalid"
                      : ""
                    }`}
                  placeholder="Password"
                  name="newPassword"
                  ref={register({
                    required: "Password is Required",
                    minLength: {
                      value: 8,
                      message: "Password should be at-least 8 characters.",
                    },
                    maxLength: {
                      value: 50,
                      message: "Password should allow only 50 characters.",
                    },
                    pattern: {
                      value:
                        /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s)(?=.*[&-_!@#$*])/,
                      message:
                        "Your password isn’t strong enough. Please include uppercase and numbers.",
                    },
                  })}
                />
                <Validation
                  errors={errors.newPassword}
                  message={errors?.newPassword?.message}
                  watch={watch("newPassword")}
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  className={`form-control ${!errors.confirm_password && watch("confirm_password")
                    ? "valid"
                    : errors.confirm_password
                      ? "invalid"
                      : ""
                    }`}
                  placeholder="Confirm Password"
                  name="confirm_password"
                  ref={register({
                    required: "Confirm password is Required",
                    validate: {
                      matchesPreviousPassword: (value) => {
                        const { newPassword } = getValues();
                        return (
                          newPassword === value || "Passwords should match!"
                        );
                      },
                    },
                  })}
                />
                <Validation
                  errors={errors.confirm_password}
                  message={errors?.confirm_password?.message}
                  watch={watch("confirm_password")}
                />
              </div>
              <button type="submit" className="btn_primary fullWidth mt-5">
                Reset Your Password
              </button>
            </form>
            {/* End: Reset Password */}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default connect(null, { resetUserPassword })(Reset);
